import React, { useState, useEffect } from 'react';
import FormatFilter from '../../../uicomponent/filter/FormatFilter'
import ImageRenderer from '../../auth/views/imageRenderer/ImageRenderer'
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { loadImages } from '../../../redux/apiSlice'
import { useLocation } from 'react-router-dom';
import SelectImage from "../academics/selectImage";
import { SearchImages } from "../../../redux/fetchSearchSlice";
import { Modal } from '@mui/material';
import { Login } from '../../auth/login/Login';
import { SignUp } from '../../auth/signUp/SignUp';
import StructureFilter from '../../../uicomponent/structureFiltering'

const Sections = () => {

    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])
    const [filters, setFilters] = useState([])
    const [noSelectImage, setNoSelectImage] = useState([]);
    const [tab, setTab] = useState(0)

    const { searchedImages } = useSelector(state => state.searchedImage)
    const { list, continuationToken, loading } = useSelector(state => state.api)

    // State for File format filters 
    const [formats, setFormat] = useState(null)        // formats of files like png , jpg, gif, pdf etc
    const [docType, setDocType] = useState(null)      // state for type of file document like Images, Videos, Animations, etc

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => handleFetchImages(), [location?.search.includes('?text=') ? location?.search.replace('?text=', "").toLowerCase() : null])

    // Function to handle loading of images
    const handleFetchImages = (folderPath) => {
        if (location?.search.includes('?text=') && location?.search.replace('?text=', "").toLowerCase() !== "") {
            let header = {
                "Content-Type": "application/json",
            }

            dispatch(SearchImages({
                headers: header,
                method: "GET",
                body: {
                    query: location?.search.replace('?text=', "").toLowerCase()
                }
            })).then((res) => {
                setData(res.payload)
                return
            })
            return
        }
        let path = location.state?.path ? location.state.path : "ACADEMIC/"
        let header = {
            "Content-Type": "application/json"
        }
        dispatch(loadImages({
            url: "/rest/images/fetch",
            header: header,
            method: "post",
            body: { folderPath: folderPath ? folderPath : path, imagesPerPage: 1000 }
        }));
    }

    const selectPicture = (data, remove) => {
        if (remove) {
            setNoSelectImage((current) => {
                const copy = [...current];
                let index = copy.indexOf(data);
                // console.log(index);
                if (index > -1) {
                    copy.splice(index, 1);
                }
                return copy;
            });
        } else {
            setNoSelectImage((current) => {
                const copy = [...current];
                copy.push(data);
                return copy;
            });
        }
    };
    const handleAddToFavourite = (data) => {
        setNoSelectImage((current) => {
            const copy = [...current];
            let index = copy.indexOf(data);

            if (index > -1) {
                copy[index].markFav = !copy[index]?.markFav;
            }
            return copy;
        });
    };

    const handleRemoveAll = () => {
        setNoSelectImage([]);
    };

    const handleClose = () => {
        setOpen(false);
        setTab(0);
    };

    return (
        <>
            <div className="checkboxContainer">
                <SelectImage
                    image={noSelectImage}
                    handleRemove={selectPicture}
                    handleRemoveAll={handleRemoveAll}
                    handleAddToFavourite={handleAddToFavourite}
                />
            </div>
            <div>
                <StructureFilter
                    loadImages={handleFetchImages}
                />
                <div className='academicsContainer'>
                    <div className={isMobile ? 'mobGutter' : 'homeGutter'} />
                    <div className='academicsWrapper'>
                        <FormatFilter
                            formats={formats}
                            setFormat={setFormat}
                            docType={docType}
                            setDocType={setDocType}
                        />
                        <ImageRenderer
                            openLogin={setOpen}
                            loading={loading}
                            data={location?.search.includes('?text=') ? searchedImages : list}
                            token={continuationToken}
                            addedImages={noSelectImage}
                            addToNoSelectImage={selectPicture}
                            selectedFilters={formats}
                        />
                    </div>
                    <div className={isMobile ? 'mobGutter' : 'homeGutter'} />
                </div>
            </div>
            <Modal className='openModal' onClose={handleClose} open={open}>
                {tab === 0 ? (
                    <Login changeTab={() => setTab(tab === 0 ? 1 : 0)} handleCloseModal={handleClose}/>
                ) : (
                    <SignUp changeTab={() => setTab(tab === 0 ? 1 : 0)} handleCloseModal={handleClose}/>
                )}
            </Modal>
        </>

    )
}

export default Sections;