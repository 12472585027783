import * as React from "react";
import TeachersIcon from "../../../../assests/auth/teachersIcon.svg";
import { ReactComponent as AddIcon } from "../../../../assests/AddIcon.svg";
import { ReactComponent as ImportExcelIcon } from "../../../../assests/ImportExcelIcon.svg";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import AuthSearchField from "../../../../customTheme/authSearchField/AuthSearchField";
import { DataGrid } from "@mui/x-data-grid";

import { columns, teacherMobileViewColumns } from './constant'
import { useNavigate } from "react-router-dom";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { ListUsers } from "../../../../redux/fetchUsersSlice";
import { RefreshToken } from "../../../../redux/authSlice";
import AddNewTeacher from "./addNewTeacher/AddNewTeacher"

export default function Teacher() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = React.useState();

  const [addNewTeacher, setAddNewTeacher] = React.useState(false);

  const { hasMore, usersList, loading } = useSelector((state) => state.usersList)
  const { accessToken, refreshToken, tokenExpiry } = useSelector((state) => state.login)

  const handleAddNewTeacher = () => {
    setAddNewTeacher(true);
  }

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    // console.log(e.target.value)    
  }

  const handleSearch = () => {
    // console.log(searchText)

    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let data = {
      role: "TEACHER",
      limit: 100,
      teacherCode: searchText
    }

    let timestamp = Math.ceil(Date.now() / 1000)

    if (tokenExpiry < timestamp) {
      dispatch(RefreshToken({
        headers: header,
        body: {
          "refreshToken": refreshToken
        }
      }))
    }

    dispatch(ListUsers({
      url: "/rest/users/listUsersByRole",
      headers: header,
      method: "GET",
      body: data
    }))
  }

  React.useEffect(() => handleFetchData(), [])

  const handleFetchData = () => {
    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let data = {
      role: "TEACHER",
      limit: 100
    }

    let timestamp = Math.ceil(Date.now() / 1000)

    if (tokenExpiry < timestamp) {
      dispatch(RefreshToken({
        headers: header,
        body: {
          "refreshToken": refreshToken
        }
      })).then(() => {
        dispatch(ListUsers({
          url: "/rest/users/listUsersByRole",
          headers: header,
          method: "GET",
          body: data
        }))
      })
    } else {
      dispatch(ListUsers({
        url: "/rest/users/listUsersByRole",
        headers: header,
        method: "GET",
        body: data
      }))
    }
  }

  if (addNewTeacher) {
    return (
      <AddNewTeacher
        handleCancel={() => {
          setAddNewTeacher(false);
        }}
      />
    );
  }

  return (
    <div className="schoolContainer">
      <div className="typoContainer">
        <img alt="" src={TeachersIcon} />
        <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"}>
          Teachers
        </Typography>
      </div>

      <div className="schoolBtnContainer">
        <div className="imgBtnContainer">
          <AuthSearchField id="signUpEmail" type="text" placeholder="Search by School ID, School Name, School City here"
            onChange={handleSearchText} />
          <Button variant="outlined" className="authSearchbtn" onClick={handleSearch}>Search</Button>
        </div>
        <MobileView>
          <div className='schoolRadioButtonCaontainer' style={{ display: 'flex', flexDirection: 'column', flex: '1', gap: '1vw', paddingTop: 10 }}>
            <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} marginTop='3px'>
              Search By
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControl >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="teachercode"
                  name="radio-buttons-group"
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}

                >
                  <FormControlLabel value="teachercode" control={<Radio />} label="Teacher Code" />
                  <FormControlLabel value="teachername" control={<Radio />} label="Teacher Name" />

                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </MobileView>
        <div className="btnCombo">
          <Button variant="outline" className="blueBtnBg" startIcon={<AddIcon />} onClick={handleAddNewTeacher}>
            Add New Teacher
          </Button>
          <Button variant="outline" className="blueBtnBg" startIcon={<ImportExcelIcon />} >Import Excel File</Button>
        </div>
      </div>
      <BrowserView>
        <div className='schoolRadioButtonCaontainer' style={{ display: 'flex', flexDirection: 'row', flex: '1', gap: '1vw' }}>
          <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} marginTop='3px'>
            Search By
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="teachercode"
                name="radio-buttons-group"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}

              >
                <FormControlLabel value="teachercode" control={<Radio />} label="Teacher Code" />
                <FormControlLabel value="teachername" control={<Radio />} label="Teacher Name" />

              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </BrowserView>
      
      <DataGrid
        className="teachersRecord"
        getRowId={(row) => row.userId}
        columns={isMobile ? teacherMobileViewColumns : columns}
        rowHeight={isMobile ? 170 : 40}
        rows={usersList.map((item) => {return {...item, "handleFetchUserData": handleFetchData}})}
        headerHeight={isMobile ? 70 : 56}
        headerWidth={50}
        scrollbarSize={isMobile ? 1 : 5}
        style={{ backgroundColor: 'white', overflow: 'auto', position: 'relative', minHeight: '80vh' }}
        sx={{
          '& .MuiDataGrid-columnHeadersInner': {
            background: "#B3DAFF",
            fontFamily: "Proxima Nova",
            fontSize: 15,
            fontWeight: 600,
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        }}
        getRowClassName={() => 'textFontProxima'}
      // rowsPerPageOptions={[20]}
      />
    </div>
  );
}
