import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect';


var Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ margin: isMobile ? "35px 10px" : 80 }}>
      <span className="document-previewer-logo-d16635" />
      <div className="document-previewer-wrapper-a717db">
        <style dangerouslySetInnerHTML={{ __html: "[data-custom-class='body'],[data-custom-class='body'] *{background: transparent !important;\n         }[data-custom-class='title'],[data-custom-class='title'] *{font-family: Arial !important;\n         font-size: 26px !important;\n         color: #000000 !important;\n         }[data-custom-class='subtitle'],[data-custom-class='subtitle'] *{font-family: Arial !important;\n         color: #595959 !important;\n         font-size: 14px !important;\n         }[data-custom-class='heading_1'],[data-custom-class='heading_1'] *{font-family: Arial !important;\n         font-size: 19px !important;\n         color: #000000 !important;\n         }[data-custom-class='heading_2'],[data-custom-class='heading_2'] *{font-family: Arial !important;\n         font-size: 17px !important;\n         color: #000000 !important;\n         }[data-custom-class='body_text'],[data-custom-class='body_text'] *{color: #595959 !important;\n         font-size: 14px !important;\n         font-family: Arial !important;\n         }[data-custom-class='link'],[data-custom-class='link'] *{color: #3030F1 !important;\n         font-size: 14px !important;\n         font-family: Arial !important;\n         word-break: break-word !important;\n         }\n      " }} />
        <div data-custom-class="body">
          <div><strong><span style={{ fontSize: '26px' }}><span data-custom-class="title">PRIVACY NOTICE</span></span></strong></div>
          <div><br /></div>
          <div>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <strong>
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="subtitle">
                    Last updated
                    <bdt className="question">November 20, 2022</bdt>
                  </span>
                </span>
              </strong>
            </span>
          </div>
          <div><br /></div>
          <div><br /></div>
          <div><br /></div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ color: 'rgb(127, 127, 127)' }}>
              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  This privacy notice for
                  <bdt className="question">Edwiser Innovation hub private limited</bdt>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                  (
                  <bdt className="block-component" />
                  '
                  <bdt className="block-component" />
                  <strong>Company</strong>
                  <bdt className="statement-end-if-in-editor" />
                  ', '<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>',
                  <bdt className="else-block" />
                </span>
                <span data-custom-class="body_text">
                  ), describes how and why we might collect, store, use, and/or share (
                  <bdt className="block-component" />
                  '<strong>process</strong>'
                  <bdt className="else-block" />
                  ) your information when you use our services (
                  <bdt className="block-component" />
                  '<strong>Services</strong>'
                  <bdt className="else-block" />
                  ), such as when you:
                </span>
              </span>
            </span>
            <span style={{ fontSize: '15px' }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: '15px' }}>
                  <span style={{ fontSize: '15px' }}>
                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      Download and use
                      <bdt className="block-component" />
                      our mobile application
                      <bdt className="block-component" />
                      (
                      <bdt className="question">
                        Myschool)
                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: '15px' }}>
                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: '15px' }}>
                                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                                        <bdt className="statement-end-if-in-editor">,</bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: '15px' }}>
                              <span style={{ color: 'rgb(89, 89, 89)' }}>
                                <bdt className="statement-end-if-in-editor">
                                  <bdt className="block-component"> or any other application of ours that links to this privacy notice</bdt>
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      Engage with us in other related ways, including any sales, marketing, or events
                      <span style={{ fontSize: '15px' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: '15px' }}>
                              <span style={{ color: 'rgb(89, 89, 89)' }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span data-custom-class="body_text">
                    <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at
                    <bdt className="question">info@myschool.in</bdt>
                    .
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><strong><span style={{ fontSize: '15px' }}><span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click&nbsp;</em></strong></span></span><a data-custom-class="link" href="#toc"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong><em>here</em></strong></span></span></a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong><em>&nbsp;to go directly to our table of contents.</em></strong></span></span></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with
                  <bdt className="block-component" />
                  <bdt className="question">Edwiser Innovation hub private limited</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  and the Services, the choices you make, and the products and features you use. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#personalinfo"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">&nbsp;to learn more.</span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>Do we process any sensitive personal information?</strong>
                  <bdt className="block-component" />
                  We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#sensitiveinfo"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  &nbsp;to learn more.
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>Do we receive any information from third parties?</strong>
                  <bdt className="block-component" />
                  We do not receive any information from third parties.
                  <bdt className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click&nbsp;</span></span><a data-custom-class="link" href="#infouse"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">&nbsp;to learn more.</span></span></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>
                    In what situations and with which
                    <bdt className="block-component" />
                    parties do we share personal information?
                  </strong>
                  We may share information in specific situations and with specific
                  <bdt className="block-component" />
                  third parties. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#whoshare"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  &nbsp;to learn more.
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>How do we keep your information safe?</strong> We have
                  <bdt className="block-component" />
                  organisational
                  <bdt className="else-block" />
                  and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                  <bdt className="block-component" />
                  unauthorised
                  <bdt className="else-block" />
                  third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#infosafe"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  &nbsp;to learn more.
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click&nbsp;</span></span><a data-custom-class="link" href="#privacyrights"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">&nbsp;to learn more.</span></span></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by filling out our data subject request form available
                  <bdt className="block-component" />
                  here:
                  <bdt className="question"><a href="http://www.myschool.in" target="_blank" data-custom-class="link">http://www.myschool.in</a></bdt>
                  <bdt className="else-block" />
                  , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  Want to learn more about what
                  <bdt className="block-component" />
                  <bdt className="question">Edwiser Innovation hub private limited</bdt>
                  <bdt className="statement-end-if-in-editor" />
                  does with any information we collect? Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#toc"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">here</span></span></a><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">&nbsp;to review the notice in full.</span></span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="toc" style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#infocollect"><span style={{ color: 'rgb(89, 89, 89)' }}>1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    <bdt className="block-component" />
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span style={{ color: 'rgb(89, 89, 89)' }}><a data-custom-class="link" href="#whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <span style={{ color: 'rgb(89, 89, 89)' }}>
                            <bdt className="block-component" />
                          </span>
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <a data-custom-class="link" href="#inforetain"><span style={{ color: 'rgb(89, 89, 89)' }}>4. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <a data-custom-class="link" href="#infosafe"><span style={{ color: 'rgb(89, 89, 89)' }}>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a>
                <span style={{ color: 'rgb(127, 127, 127)' }}>
                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><a data-custom-class="link" href="#privacyrights">6. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#DNT"><span style={{ color: 'rgb(89, 89, 89)' }}>7. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#caresidents"><span style={{ color: 'rgb(89, 89, 89)' }}>8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span></div>
            <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="#policyupdates"><span style={{ color: 'rgb(89, 89, 89)' }}>9. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
            <div style={{ lineHeight: '1.5' }}><a data-custom-class="link" href="#contact"><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
            <div style={{ lineHeight: '1.5' }}><a data-custom-class="link" href="#request"><span style={{ color: 'rgb(89, 89, 89)' }}>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="infocollect" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span></div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div id="personalinfo" style={{ lineHeight: '1.5' }}><span data-custom-class="heading_2" style={{ color: 'rgb(0, 0, 0)' }}><span style={{ fontSize: '15px' }}><strong>Personal information you disclose to us</strong></span></span></div>
            <div>
              <div><br /></div>
              <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></span></span></span></span></span></span></div>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    We collect personal information that you voluntarily provide to us when you
                    <span style={{ fontSize: '15px' }}>
                      <bdt className="block-component" />
                    </span>
                    register on the Services,&nbsp;
                  </span>
                  <span style={{ fontSize: '15px' }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">names</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">phone numbers</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">email addresses</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: '15px' }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">mailing addresses</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div id="sensitiveinfo" style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <strong>Sensitive Information.</strong>
                  <bdt className="block-component" />
                  When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:
                  <bdt className="forloop-component" />
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: '15px' }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">student data</bdt>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: '15px' }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by
                    <bdt className="forloop-component" />
                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: '15px' }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt className="question">razorpay</bdt>
                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: '15px' }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: '15px' }}>
                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: '15px' }}>
                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                              <span style={{ fontSize: '15px' }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="forloop-component" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          . You may find their privacy notice link(s) here:
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <bdt className="forloop-component" />
                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: '15px' }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <bdt className="question"><a href="https://razorpay.com/privacy/" target="_blank" data-custom-class="link">https://razorpay.com/privacy/</a></bdt>
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: '15px' }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <bdt className="forloop-component" />
                                <span style={{ fontSize: '15px' }}>
                                  <span data-custom-class="body_text">
                                    .
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}><br /></div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor">
                          <bdt className="block-component" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: '15px' }}>
                  <strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                  <bdt className="block-component" />
                </span>
              </span>
              <div style={{ lineHeight: '1.5' }}>
                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                <div style={{ lineHeight: '1.5' }}>
                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                  <div style={{ lineHeight: '1.5' }}>
                    <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                  </div>
                  <ul>
                    <li style={{ lineHeight: '1.5' }}>
                      <span style={{ fontSize: '15px' }}>
                        <span data-custom-class="body_text">
                          <em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span></span></div>
                  <div style={{ lineHeight: '1.5' }}><br /></div>
                  <div style={{ lineHeight: '1.5' }}>
                    <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                  </div>
                  <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></span></span></div>
                  <div style={{ lineHeight: '1.5' }}><br /></div>
                  <div style={{ lineHeight: '1.5' }}>
                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                        <span data-custom-class="body_text">
                          <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component" />
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span style={{ fontSize: '15px' }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </div>
                  <div id="infouse" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
                  <div>
                    <div style={{ lineHeight: '1.5' }}><br /></div>
                    <div style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></span></span></span></span></span></div>
                  </div>
                  <div style={{ lineHeight: '1.5' }}><br /></div>
                  <div style={{ lineHeight: '1.5' }}>
                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{ lineHeight: '1.5' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.
                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: '15px' }}>
                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: '15px' }}>
                                          <span style={{ color: 'rgb(89, 89, 89)' }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: '1.5' }}>
                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: '1.5' }}>
                      <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      <div style={{ lineHeight: '1.5' }}>
                        <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                        <div style={{ lineHeight: '1.5' }}>
                          <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          <div style={{ lineHeight: '1.5' }}>
                            <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                            <div style={{ lineHeight: '1.5' }}>
                              <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                              <div style={{ lineHeight: '1.5' }}>
                                <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                <div style={{ lineHeight: '1.5' }}>
                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                  <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                    <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                  </p>
                                  <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                    <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                  </p>
                                  <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                    <bdt className="block-component" />
                                  </p>
                                  <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
                                    <bdt className="block-component" />
                                  </p>
                                  <div style={{ lineHeight: '1.5' }}>
                                    <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                    <div style={{ lineHeight: '1.5' }}>
                                      <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                      <div style={{ lineHeight: '1.5' }}>
                                        <bdt className="block-component"><span style={{ fontSize: '15px' }} /></bdt>
                                        <div style={{ lineHeight: '1.5' }}>
                                          <span style={{ fontSize: '15px' }}>
                                            <bdt className="block-component"><span data-custom-class="body_text" /></bdt>
                                          </span>
                                          <div style={{ lineHeight: '1.5' }}>
                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                            <div style={{ lineHeight: '1.5' }}>
                                              <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                              <div style={{ lineHeight: '1.5' }}>
                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                <div style={{ lineHeight: '1.5' }}>
                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                  <div style={{ lineHeight: '1.5' }}>
                                                    <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                    <div style={{ lineHeight: '1.5' }}>
                                                      <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                      <div style={{ lineHeight: '1.5' }}>
                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                        <div style={{ lineHeight: '1.5' }}>
                                                          <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                          <div style={{ lineHeight: '1.5' }}>
                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                            <div style={{ lineHeight: '1.5' }}>
                                                              <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                              <div style={{ lineHeight: '1.5' }}>
                                                                <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                  <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                <div id="whoshare" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></span></span></span></span></div>
                                                                <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                      <span data-custom-class="body_text">
                                                                        <strong><em>In Short:</em></strong>
                                                                        <em>
                                                                          &nbsp;We may share information in specific situations described in this section and/or with the following
                                                                          <bdt className="block-component" />
                                                                          third parties.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                    <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                  <span style={{ fontSize: '15px' }}>
                                                                    <span data-custom-class="body_text">
                                                                      We
                                                                      <bdt className="block-component" />
                                                                      may need to share your personal information in the following situations:
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <ul>
                                                                  <li style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span></li>
                                                                </ul>
                                                                <div style={{ lineHeight: '1.5' }}>
                                                                  <span style={{ fontSize: '15px' }}>
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                  <div style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ fontSize: '15px' }}>
                                                                      <bdt className="block-component"><span data-custom-class="body_text" /></bdt>
                                                                    </span>
                                                                    <div style={{ lineHeight: '1.5' }}>
                                                                      <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                      <div style={{ lineHeight: '1.5' }}>
                                                                        <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                        <div style={{ lineHeight: '1.5' }}>
                                                                          <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                          <span data-custom-class="body_text">
                                                                            <span style={{ fontSize: '15px' }}>
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                          <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                            <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                              <span style={{ fontSize: '15px' }}>
                                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <bdt className="block-component"><span data-custom-class="heading_1" /></bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                  <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                    <span style={{ fontSize: '15px' }}>
                                                                                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                        <span style={{ fontSize: '15px' }}>
                                                                                          <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                            <bdt className="block-component">
                                                                                              <span data-custom-class="body_text">
                                                                                                <bdt className="block-component" />
                                                                                              </span>
                                                                                            </bdt>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="inforetain" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">4. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <strong><em>In Short:&nbsp;</em></strong>
                                                                                  <em>
                                                                                    We keep your information for as long as necessary to
                                                                                    <bdt className="block-component" />
                                                                                    fulfil
                                                                                    <bdt className="else-block" />
                                                                                    the purposes outlined in this privacy notice unless otherwise required by law.
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                                                                                  <bdt className="block-component" />
                                                                                  No purpose in this notice will require us keeping your personal information for longer than
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                  <bdt className="block-component" />
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="else-block" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                  .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  When we have no ongoing legitimate business need to process your personal information, we will either delete or
                                                                                  <bdt className="block-component" />
                                                                                  anonymise
                                                                                  <bdt className="else-block" />
                                                                                  such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                                                                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="infosafe" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <strong><em>In Short:&nbsp;</em></strong>
                                                                                  <em>
                                                                                    We aim to protect your personal information through a system of
                                                                                    <bdt className="block-component" />
                                                                                    organisational
                                                                                    <bdt className="else-block" />
                                                                                    and technical security measures.
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  We have implemented appropriate and reasonable technical and
                                                                                  <bdt className="block-component" />
                                                                                  organisational
                                                                                  <bdt className="else-block" />
                                                                                  security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                                                                                  <bdt className="block-component" />
                                                                                  unauthorised
                                                                                  <bdt className="else-block" />
                                                                                  third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                                                                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                  <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="privacyrights" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">6. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <strong><em>In Short:</em></strong>
                                                                                  <em>
                                                                                    &nbsp;
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <span style={{ fontSize: '15px' }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          <em>
                                                                                            <bdt className="block-component" />
                                                                                          </em>
                                                                                        </span>
                                                                                      </span>
                                                                                      &nbsp;
                                                                                    </span>
                                                                                    You may review, change, or terminate your account at any time.
                                                                                  </em>
                                                                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <span style={{ fontSize: '15px' }}>
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>&nbsp;</span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(48, 48, 241)' }}><span data-custom-class="body_text"><a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank"><span style={{ fontSize: '15px' }}>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">If you are located in Switzerland, the contact details for the data protection authorities are available here: <span style={{ fontSize: '15px' }}><span style={{ color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><span style={{ color: 'rgb(48, 48, 241)' }}><span data-custom-class="body_text"><span style={{ fontSize: '15px' }}><a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.</span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="withdrawconsent" style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information,
                                                                                  <bdt className="block-component" />
                                                                                  which may be express and/or implied consent depending on the applicable law,
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                  you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section
                                                                                  <bdt className="block-component" />
                                                                                  '
                                                                                  <bdt className="else-block" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <a data-custom-class="link" href="#contact"><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                  '
                                                                                  <bdt className="else-block" />
                                                                                  below
                                                                                  <bdt className="block-component" />
                                                                                  .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}>
                                                                              <span data-custom-class="body_text">
                                                                                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
                                                                                <bdt className="block-component" />
                                                                                when applicable law allows,
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px' }}><span data-custom-class="heading_2"><strong>Account Information</strong></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ fontSize: '15px' }}>
                                                                                If you would at any time like to review or change the information in your account or terminate your account, you can:
                                                                                <bdt className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <ul>
                                                                            <li style={{ lineHeight: '1.5' }}>
                                                                              <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <bdt className="question">Log in to your account settings and update your user account.</bdt>
                                                                                </span>
                                                                              </span>
                                                                            </li>
                                                                          </ul>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ fontSize: '15px' }}>
                                                                                <bdt className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <ul>
                                                                            <li style={{ lineHeight: '1.5' }}>
                                                                              <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <bdt className="question">Contact us using the contact information provided.</bdt>
                                                                                </span>
                                                                              </span>
                                                                            </li>
                                                                          </ul>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ fontSize: '15px' }}>
                                                                                <bdt className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}><span data-custom-class="body_text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></span>
                                                                            <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <span style={{ fontSize: '15px' }}>
                                                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span style={{ fontSize: '15px' }}>
                                                                                              <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                <bdt className="block-component" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="block-component"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span data-custom-class="body_text">
                                                                              <span style={{ fontSize: '15px' }}>
                                                                                If you have questions or comments about your privacy rights, you may email us at
                                                                                <bdt className="question">info@myschool.in</bdt>
                                                                                .
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="statement-end-if-in-editor"><span style={{ fontSize: '15px' }}><span data-custom-class="body_text" /></span></bdt>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="DNT" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">7. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (
                                                                                  <bdt className="block-component" />
                                                                                  'DNT'
                                                                                  <bdt className="else-block" />
                                                                                  ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for
                                                                                  <bdt className="block-component" />
                                                                                  recognising
                                                                                  <bdt className="else-block" />
                                                                                  and implementing DNT signals has been
                                                                                  <bdt className="block-component" />
                                                                                  finalised
                                                                                  <bdt className="else-block" />
                                                                                  . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="caresidents" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  California Civil Code Section 1798.83, also known as the
                                                                                  <bdt className="block-component" />
                                                                                  'Shine The Light'
                                                                                  <bdt className="else-block" />
                                                                                  law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.
                                                                                  <bdt className="block-component" />
                                                                                  backups, etc.).
                                                                                  <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="policyupdates" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">9. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  We may update this privacy notice from time to time. The updated version will be indicated by an updated
                                                                                  <bdt className="block-component" />
                                                                                  'Revised'
                                                                                  <bdt className="else-block" />
                                                                                  date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="contact" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  If you have questions or comments about this notice, you may
                                                                                  <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                      email us at
                                                                                      <bdt className="question">info@myschool.in</bdt>
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                  <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span data-custom-class="body_text">&nbsp;or by post to:</span></span></span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="question">Edwiser Innovation hub private limited</bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="question">Musheerabad</bdt>
                                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span data-custom-class="body_text" style={{ fontSize: '15px' }}>
                                                                              <bdt className="question">
                                                                                Secunderabad
                                                                                <span data-custom-class="body_text">
                                                                                  <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                    <span style={{ fontSize: '15px' }}>
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </bdt>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}>
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="question">Secunderabad</bdt>
                                                                                <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                  <span style={{ fontSize: '15px' }}>
                                                                                    <bdt className="block-component" />
                                                                                    ,
                                                                                    <bdt className="question">Telangana</bdt>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                    <bdt className="block-component" />
                                                                                    <bdt className="question">500020</bdt>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                    <bdt className="block-component" />
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px' }}>
                                                                              <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <bdt className="question">India</bdt>
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                        <span style={{ fontSize: '15px' }}>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                <span style={{ fontSize: '15px' }}>
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </bdt>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span data-custom-class="body_text">
                                                                                          <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                            <span style={{ fontSize: '15px' }}>
                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                              <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                        <span style={{ color: 'rgb(89, 89, 89)' }}>
                                                                                          <span style={{ fontSize: '15px' }}>
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component">
                                                                                                <bdt className="block-component" />
                                                                                              </bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                                <span style={{ fontSize: '15px' }}>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}>
                                                                                      <span style={{ fontSize: '15px' }}>
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="statement-end-if-in-editor">
                                                                                            <bdt className="block-component" />
                                                                                          </bdt>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div id="request" style={{ lineHeight: '1.5' }}><span style={{ color: 'rgb(127, 127, 127)' }}><span style={{ color: 'rgb(89, 89, 89)', fontSize: '15px' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}><span id="control" style={{ color: 'rgb(0, 0, 0)' }}><strong><span data-custom-class="heading_1">11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></span></span></span></span></div>
                                                                          <div style={{ lineHeight: '1.5' }}><br /></div>
                                                                          <div style={{ lineHeight: '1.5' }}>
                                                                            <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                              <span style={{ fontSize: '15px', color: 'rgb(89, 89, 89)' }}>
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                  You have the right to request access to the personal information we collect from you, change that information, or delete it.
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                  To request to review, update, or delete your personal information, please
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  &nbsp;visit:
                                                                                  <bdt className="question"><a href="http://www.myschool.in" target="_blank" data-custom-class="link">https://www.myschool.in</a></bdt>
                                                                                  <bdt className="else-block" />
                                                                                </span>
                                                                              </span>
                                                                              <span data-custom-class="body_text">.</span>
                                                                            </span>
                                                                          </div>
                                                                          <style dangerouslySetInnerHTML={{ __html: "\n                                                                                                            ul {\n                                                                                                            list-style-type: square;\n                                                                                                            }\n                                                                                                            ul > li > ul {\n                                                                                                            list-style-type: circle;\n                                                                                                            }\n                                                                                                            ul > li > ul > li > ul {\n                                                                                                            list-style-type: square;\n                                                                                                            }\n                                                                                                            ol li {\n                                                                                                            font-family: Arial ;\n                                                                                                            }\n                                                                                                         " }} />
                                                                        </div>

                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy