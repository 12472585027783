import React from 'react'


const InfoHub = () => {
    return (
        <>
            Info Hub
        </>
    )
}

export default InfoHub