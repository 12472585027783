import React from 'react'
import TopNavbar from './navbar/TopNavbar'
import "./AuthStyle.css"
import SideNav from './navbar/SideNav'
import { Outlet } from 'react-router-dom'
import { isMobile, MobileView } from 'react-device-detect'
import MobileNavbar from './mobileNavbar/MobileNavbar'

const AuthViews = () => {
    const [mounted, setMounted] = React.useState(isMobile ? false : true);
    return (
        <div className='authViewContainer'>
            <TopNavbar toggleDrawer={() => setMounted(mounted ? false : true)} />
            <MobileView>
                <MobileNavbar toggleDrawer={() => setMounted(!mounted ? true : false)} />
            </MobileView>
            <div className='authViewMain' style={{display:mounted ? 'flex' : 'block'}}>
                <SideNav drawerState={mounted} toggleDrawer={() => setMounted(mounted ? false : true)}/>
                <Outlet />
            </div>
        </div>
    )
}

export default AuthViews