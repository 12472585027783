import "./App.css";
import Routes from "./Routes/routes";
import { CustomTheme } from "./customTheme/Index";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import CustomSnackbar from "./customTheme/customSnackbar/CustomSnackbar";
import { fetchToken, onMessageListener } from './firebaseConfig/firebase'
import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} sx={{minWidth : 300, backgroundColor:'white'}}/>});

function App() {
  let persistor = persistStore(store);
  const [isTokenFound, setTokenFound] = React.useState(false);
  const [notification, setNotification] = React.useState({});
  const [open, setOpen] = React.useState(false);

  //fetchToken(setTokenFound);
  // 

  onMessageListener().then(payload => {
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    let notifications = JSON.parse(localStorage.getItem("mySchoolNotification"))
    // console.log(notifications)
    if (notifications === "" || notifications === undefined || notifications === null) {
      notifications = []
      notifications.push(payload)
      // console.log(notifications)
      localStorage.setItem("mySchoolNotification", JSON.stringify(notifications))
    } else {
      notifications.push(payload)
      // console.log(notifications)
      localStorage.setItem("mySchoolNotification", JSON.stringify(notifications))
    }
    setOpen(true)
    // console.log(payload);
  }).catch(err => console.log('failed: ', err));

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={CustomTheme}>
          <Routes />
          <CustomSnackbar />
        </ThemeProvider>
      </PersistGate>
      <Snackbar open={open} autoHideDuration={6000} 
        anchorOrigin={{vertical : 'bottom', horizontal: 'right'}}
        onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity="info" icon={false} >
          <h3>{notification?.title}</h3>
          <p>{notification?.body}</p>
        </Alert>
      </Snackbar>
    </Provider>
  );

}

export default App;
