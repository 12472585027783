import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ListUsers = createAsyncThunk(
    'auth/listUsers',
    async (data, { rejectWithValue }) => {
        try {
            let headers = data.headers
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: data?.url,
                method: data?.method,
                headers: headers,
                params: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })

export const UserAccess = createAsyncThunk(
    'auth/userAccess',
    async (data, {rejectWithValue}) => {
        try {
            let header = data.headers
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url : '/rest/users/disableAccount',
                method : "POST",
                headers : header,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const UserCredits = createAsyncThunk(
    'auth/userCredits',
    async (data, {rejectWithValue}) => {
        try {
            let header = data.headers
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url : '/rest/users/updateCredits',
                method : "PATCH",
                headers : header,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)

const fetchUsersSlice = createSlice({
    name: "user",
    initialState: {
        usersList: [],
        hasMore: false,
        loading: false
    },

    reducers: {},

    extraReducers: {
        [ListUsers.fulfilled]: (state, { payload }) => {
            state.usersList = payload?.data?.users
            state.hasMore = payload?.data?.hasMore
            state.loading = false;
        },
        [ListUsers.pending]: (state) => {
            state.loading = true
            state.usersList = []
            state.hasMore = false
            state.msg = "Registration Started"
        },
        [ListUsers.rejected]: (state, { payload }) => {
            state.usersList = payload
            state.loading = false;
            // setTimeout(() => state.msg = "", 500)
        },
        [UserAccess.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [UserAccess.pending]: (state) => {
            state.msg = "Performing Action!!"
        },
        [UserAccess.rejected]: (state, { payload }) => {
            state.loading = false;
            // setTimeout(() => state.msg = "", 500)
        },
        [UserCredits.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [UserCredits.pending]: (state) => {
            state.msg = "Performing Action!!"
        },
        [UserCredits.rejected]: (state, { payload }) => {
            state.loading = false;
            // setTimeout(() => state.msg = "", 500)
        },
    }
});

export default fetchUsersSlice.reducer;

