import React, { useRef } from "react";
import MSTextField from "../../../../../customTheme/textField/MSTextField";
import { Button, Typography } from "@mui/material";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch, useSelector } from "react-redux";
import { RefreshToken } from "../../../../../redux/authSlice";
import { adminUploadFile } from "../../../../../redux/adminUploadSlice";
import { useSnackbar } from "../../../../../hook/useSnackbar";

const AdminUploadFile = () => {

    const inputRef = useRef();
    const [preview, setPreview] = React.useState();
    const [previewPdf, setPreviewPdf] = React.useState();
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [isFilePicked, setIsFilePicked] = React.useState(false);
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const { refreshToken, accessToken, userRole } = useSelector((state) => state.login);

    const dispatch = useDispatch()

    const { displaySnackbar } = useSnackbar()

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [adminUploadData, setAdminUploadData] = React.useState({
        file: preview, category: "", adminCode: "admin_code",
        s3Path: `Images/useruploads/${selectedFile}`, type: "IMAGES",
    })

    const handleFieldsChange = (e, fieldName) => {
        setAdminUploadData(current => {
            const copy = { ...current }
            copy[fieldName] = e.target.value
            return copy
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // console.log(selectedFile);

        let header = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${accessToken}`
        };

        adminUploadData.file = selectedFile

        let uploadData = JsonToFormData(adminUploadData);

        dispatch(adminUploadFile({
            headers: header,
            body: uploadData
        })).then((res) => {
            if (res.payload.status = 401) {
                header["Content-Type"] = "application/json"
                dispatch(RefreshToken({
                    headers: header,
                    body: {
                        "refreshToken": refreshToken
                    }
                })).then((res) => {
                    header["Content-Type"] = "multipart/form-data"
                    header["Authorization"] = `Bearer ${res.payload.accessToken}`
                    dispatch(adminUploadFile({
                        headers: header,
                        body: uploadData
                    }))
                })
            }
        })
    }

    // Function to convert JSON to FormData
    // @param json object
    // @return formdata
    const JsonToFormData = (item) => {
        let fd = new FormData()
        for (var key in item) {
            fd.append(key, item[key]);
        }
        return fd
    }

    return (
        <div className="myImageUploadImageContainer">
            <div className="myImageEnterTextFieldContainer">
                <MSTextField id="filename" type="text" placeholder="Enter file name"
                    label="* File Name" fieldName="fileName"
                    // value={isFilePicked ? selectedFile.name : null}
                    value={adminUploadData.fileName}
                    onChange={handleFieldsChange}
                />
                <MSTextField id="category" type="text" placeholder="Enter category"
                    label="* Category" fieldName="category" value={adminUploadData.category}
                    onChange={handleFieldsChange}
                />
                <MSTextField id="metaTags" type="text" placeholder="Enter meta tags seperated by comma"
                    label="* Meta Tags" fieldName="metaTags" value={adminUploadData.metaTags}
                    onChange={handleFieldsChange}
                />

                <div className="myImagefileUploadContainer">
                    <Typography fontSize="14px" fontWeight="700">* Select File</Typography>
                    <div className="myImageChooseFileContainer">
                        <label htmlFor="file" className="myImageChooseFile">
                            <Typography fontSize="14px">Choose File</Typography>
                            <input
                                id="file"
                                ref={inputRef}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    setSelectedFile(e.target.files[0]);
                                    setIsFilePicked(true);

                                    var objectUrl = window.URL.createObjectURL(e.target.files[0])
                                    // console.log(objectUrl);
                                    // console.log(e.target.files[0]);

                                    setPreview(objectUrl);
                                    // console.log(selectedFile);
                                    setPreviewPdf(objectUrl);
                                }}
                                onClick={(e) => { e.target.value = "" }}
                            />
                        </label>
                        {isFilePicked ? selectedFile.name : null}
                    </div>
                </div>
            </div>

            <div className="myImagePreviewContainer">
                <Typography style={{ alignItems: 'center', fontSize: '20px' }}>Preview</Typography>
                {preview ? <div><img src={preview} style={{ maxWidth: '240px' }} /></div> :
                    <div className="myImagePreviewImageContainer">
                        <Typography style={{ alignItems: 'center' }}>No File Selected </Typography>

                    </div>}
                {selectedFile.type == "application/pdf" ?
                    <Document file={previewPdf}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page maxWidth={200} height={200} pageNumber={pageNumber} />
                    </Document> : null}
                <div className="myImageSaveBtn">
                    <Button disableElevation className='paymentProceedBtn' variant='contained' onClick={handleSubmit}>Submit</Button>
                </div>
            </div>
        </div>
    )

}

export default AdminUploadFile;