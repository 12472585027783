import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MSTextField from '../../customTheme/textField/MSTextField'
import { UserCredits } from '../../redux/fetchUsersSlice'
import { useSnackbar } from "../../hook/useSnackbar";
import { isMobile } from 'react-device-detect'
import  Crossicon from "../../assests/homeScreen/crossicon.svg";


export default function CreditDialog(props) {
    const [credits, setCredits] = React.useState(0)
    const [chips, setChips] = React.useState([
        { label: '100', value: 100 }, { label: '500', value: 500 }, { label: '1000', value: 1000 },
        { label: '1500', value: 1500 }, { label: '5000', value: 5000 }, { label: '10000', value: 10000 },
    ])
    const [selectedChip, setSelectedChip] = React.useState(null)

    const dispatch = useDispatch()
    const { displaySnackbar } = useSnackbar();

    const { accessToken, refreshToken, tokenExpiry, userRole } = useSelector((state) => state.login)

    const handleAction = (action) => {
        let data = {
            userId: props.params.row.userId,
            credits: credits
        }
        if (action) {
            data.credits = -credits
        }
        let header = {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "Authorization": `Bearer ${accessToken}`
        }
        dispatch(UserCredits({
            headers : header,
            body : data
        })).then((res) => {
            props.params.row?.handleFetchUserData()
            displaySnackbar({ message: 'Updated Successfully' })
        })
    }

    return (
        <>
        
          {/* <Crossicon   className='loginCloseModal'/>   */}
        
         
        <Dialog open={props.open} onClose={props.handleClose} PaperProps={{
            style: {
                width: '50vw',
            }
        }}>
         <div className='closeCreditModal' style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
             <img src={Crossicon}  onClick={() => props.handleClose()}/>
            </div>
            <DialogTitle >Manage Image Credits 
            {/* <div className='closeCreditModal' style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
             <img src={Crossicon}  onClick={() => props.handleClose()}/>
            </div> */}
             </DialogTitle>
           
           
            <DialogContent style={{cursor:'pointer'}}>
                <MSTextField
                    id="credits" type="number" placeholder="Enter Number of credits to add or remove" value={credits !== 0 ? credits : null}
                    label="* Credits" onChange={(e) => setCredits(e.target.value)} fieldName="credits"
                />
                <div style={{ display: 'flex', gap: 10, marginTop: 20, flexDirection: isMobile ? 'column' : 'row' }}>
                    {chips.map((k, i) => <div onClick={() => {
                        setSelectedChip(k.value)
                        setCredits(k.value)
                    }}
                        style={{
                            padding: 5,
                            border: '1px solid #B3DAFF',
                            borderRadius: 20,
                            minWidth: 50,
                        }}>
                        <Typography textAlign={'center'}>{k.label}</Typography>
                    </div>)}
                </div>
            </DialogContent>

            <DialogActions>
                <Button fullWidth
                    onClick={() => handleAction(false)}
                    sx={{
                        ':hover': {
                            border: '2px solid #B3DAFF !important',
                            backgroundColor: 'white !important'
                        }
                    }}
                    style={{
                        backgroundColor: '#B3DAFF',
                        borderRadius: '8px'
                    }}>Add</Button>
                <Button fullWidth
                    onClick={() => handleAction(true)}
                    sx={{
                        ':hover': {
                            border: '2px solid #B3DAFF !important',
                            backgroundColor: 'white !important'
                        }
                    }}
                    style={{
                        backgroundColor: '#B3DAFF',
                        borderRadius: '8px'
                    }}>Remove</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}