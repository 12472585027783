import React from 'react';

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel, TextSection, LayersSection, ElementsSection, UploadSection, PhotosSection } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';

import { createStore } from 'polotno/model/store';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

import { TemplatesSection as cTemplateSections } from './templatesSection';
import { ImagesSection as cImagesSection } from './imagesSection';
import { ResizeSection } from './resizeSection';
import './style/chartMaker.css'

const store = createStore( {
    showCredit: false,
    key : '0anLqZl0ZBq-oAZ1vlJz'
});
const page = store.addPage();
store.setScale(0.38)

// const sections = [cTemplateSections, TemplatesSection, cImagesSection, ResizeSection]
const sections =[cImagesSection, cTemplateSections, ElementsSection, TextSection, UploadSection, LayersSection, ResizeSection, PhotosSection]

const ChartMaker = React.forwardRef((props, ref) => {
    return (
        <PolotnoContainer style={{ width: '100vw', height: "76.8vh", overflow:'hidden' }}>
            <SidePanelWrap>
                <SidePanel store={store} sections={sections} defaultSection="custom-images" />
            </SidePanelWrap>
            <WorkspaceWrap>
                <Toolbar store={store} downloadButtonEnabled />
                <Workspace store={store} backgroundColor="#494949" />
                <ZoomButtons store={store} />
            </WorkspaceWrap>
        </PolotnoContainer>

    );
})

export default ChartMaker