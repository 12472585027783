import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiCallBegan } from "./api";

export const loadSingleImage = createAsyncThunk(
    'loadSingleImage',
    async (data, { rejectWithValue }) => {
        try {
            let headers = data.headers
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/fetch',
                method: 'POST',
                headers : headers,
                data: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
        
    })

const loadOldImageSlice = createSlice({
    name: "oldImageFetch",
    initialState: {
        list: [],
        continuationToken : "",
        loading: false,
    },
    reducers : {},


    extraReducers: {
        [loadSingleImage.fulfilled]: (state, { payload }) => {
            state.loading = true;
        },

        [loadSingleImage.fulfilled]: (state, { payload }) => {
            state.list = payload.list;
            state.continuationToken = payload.continuationToken
            state.loading = false;
        },

        [loadSingleImage.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
    },
});

export default loadOldImageSlice.reducer;