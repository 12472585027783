export const menuItemsMakers = [
    {
        "children": [
          {
            "children": [],
            "title": "CHART MAKER",
            "type": "directory"
          },
          {
            "children": [],
            "title": "WORKSHEET MAKER",
            "type": "directory"
          },
          {
            "children": [],
            "title": "STORY MAKER",
            "type": "directory"
          },
          {
            "children": [],
            "title": "MICRO SCHEDULER",
            "type": "directory"
          },
          {
            "children": [],
            "title": "20+TYPES OF GRAMMER WORK SHEETMAKERS",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "50+TYPES OF MATHS WORK SHEET MAKERS",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "ART MAKER",
            "type": "directory",
            disable: true
          },
    
          {
            "children": [],
            "title": "CRAFT MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "CROSS WORD MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "FLASH CARD MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "FONT MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "ID CARD MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "LESSON PLAN MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "SCHOOL DAIRY MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "STATIONARY MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "SUDOKU MAKER",
            "type": "directory"
          },
          {
            "children": [],
            "title": "TEXT BOOK MAKER",
            "type": "directory",
            disable: true
          },
          {
            "children": [],
            "title": "WORK BOOK MAKER",
            "type": "directory",
            disable: true
          },
    
        ],
        "title": "MAKERS",
        "type": "directory"
    },
]