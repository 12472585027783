import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const apiSlice = createSlice({
    name: "imageFetch",
    initialState: {
        list: [],
        continuationToken : "",
        loading: false,
    },

    reducers: {
        imagesRequested: (imageFetch, action) => {
            imageFetch.loading = true;
        },

        imagesReceived: (imageFetch, action) => {
            imageFetch.list = action.payload.list;
            imageFetch.continuationToken = action.payload.continuationToken
            imageFetch.loading = false;
        },

        imageRequestFailed: (imageFetch, action) => {
            imageFetch.loading = false;
        },
    },
});

export default apiSlice.reducer;

const { imagesRequested, imagesReceived, imagesRequestFailed } = apiSlice.actions;

export const loadImages = (data) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url : data?.url,
            method : data?.method,
            body : data?.body,
            headers : data.header,
            onStart: imagesRequested.type,
            onSuccess: imagesReceived.type,
            onError: imagesRequestFailed,
        })
    );
};