import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import axios from "axios";
import jwt_decode from "jwt-decode";


export const authRegister = createAsyncThunk(
    'auth/register',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: data?.url,
                method: data?.method,
                config: {
                    headers: {
                        "Content-type": "Application/json",
                        "Access-Control-Allow-Origin": "*",
                    }
                },
                data: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })

export const RefreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/auth/refreshToken',
                method: "POST",
                headers: data?.headers,
                data: data?.body
            })
            return {...response.data, appType : data?.appType}
        } catch (err) {
            return rejectWithValue({...err.response.data, appType : data?.appType});
        }
    }
)

export const UpdatePassword = createAsyncThunk(
    'auth/updatePassword',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/auth/newPasswordChallenge',
                method: "POST",
                headers: data?.headers,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const userLogin = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/auth/login',
                method: "POST",
                headers: data?.headers,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const sendOtpThunk = createAsyncThunk(
    'auth/sendOtp',
    async (data, { rejectWithValue }) => {
        try {

            let response = await axios.get(`https://api.myschool.in/rest/auth/sendOtp?phoneNumber=${data.phoneNumber}`)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const forgotPwd = createAsyncThunk(
    'auth/forgotPassword',
    async (data, { rejectWithValue }) => {
        try {

            let response = await axios.get(`https://api.myschool.in/rest/auth/forgotPassword?email=${data.user}`)
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const newPwdLogin = createAsyncThunk(
    'auth/confirmPassword',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/auth/confirmPassword',
                method: "POST",
                headers: data?.headers,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    } 
)

export const userOtpLogin = createAsyncThunk(
    'auth/otpLogin',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/auth/loginViaOtp',
                method: "POST",
                headers: data?.headers,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const loginSlice = createSlice({
    name: "user",
    initialState: {
        refreshToken: null,
        accessToken: null,
        tokenExpiry : null,
        userRole : null,
        isLoggedin: false,
        loading: false,
        appType: 'none'
    },

    reducers: {
        logoutUser: (state) => {
            state.refreshToken = null
            state.accessToken = null
            state.userRole = null
            state.tokenExpiry = null
            state.isLoggedin = false;
            state.loading = false
        },
    },

    extraReducers: {
        [authRegister.fulfilled]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },
        [authRegister.pending]: (state) => {
            state.loading = true;
            state.msg = "Registration Started"
        },
        [authRegister.rejected]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
            setTimeout(() => state.msg = "", 500)
        },

        [RefreshToken.fulfilled]: (state, { payload }) => {
            state.refreshToken = payload.refreshToken
            state.accessToken = payload.accessToken
            state.userRole = (jwt_decode(payload.accessToken))['cognito:groups'][0]
            state.tokenExpiry =  (jwt_decode(payload.accessToken))['exp']
            if(payload.appType) state.appType = payload.appType
            state.isLoggedin = true;
            state.loading = false;
        },
        [RefreshToken.pending]: (state) => {
            state.loading = true;
        },
        [RefreshToken.rejected]: (state, { payload }) => {
            state.loading = false;
            if(payload.appType) state.appType = payload.appType
            state.msg = "Something Wrong !!"
        },

        [UpdatePassword.fulfilled]: (state, { payload }) => {
            state.refreshToken = payload.refreshToken
            state.accessToken = payload.accessToken
            state.userRole = (jwt_decode(payload.accessToken))['cognito:groups'][0]
            state.tokenExpiry =  (jwt_decode(payload.accessToken))['exp']
            state.isLoggedin = true;
            state.loading = false;
        },
        [UpdatePassword.pending]: (state) => {
            state.loading = true
        },
        [UpdatePassword.rejected]: (state, { payload }) => {
            state.msg = "Something Wrong !!"
            state.loading = false;
        },

        [userLogin.pending]: (state) => {
            state.loading = true;
        },

        [userLogin.fulfilled]: (state, { payload }) => {
            state.refreshToken = payload.refreshToken
            state.accessToken = payload.accessToken
            state.userRole = (jwt_decode(payload.accessToken))['cognito:groups'][0]
            state.tokenExpiry =  (jwt_decode(payload.accessToken))['exp']
            state.isLoggedin = true;
            state.loading = false;
        },

        [userLogin.rejected]: (state, { payload }) => {
            state.loading = false;
        },

        // Login Via OTP
        [userOtpLogin.pending]: (state) => {
            state.loading = true;
        },

        [userOtpLogin.fulfilled]: (state, { payload }) => {
            state.refreshToken = payload.refreshToken
            state.accessToken = payload.accessToken
            state.userRole = (jwt_decode(payload.accessToken))['cognito:groups'][0]
            state.tokenExpiry =  (jwt_decode(payload.accessToken))['exp']
            state.isLoggedin = true;
            state.loading = false;
        },

        [userOtpLogin.rejected]: (state, { payload }) => {
            state.loading = false;
        },
    }
});

export default loginSlice.reducer;

// const { userRequest, userReceived, userFailed } = loginSlice.actions;

export const { logoutUser } = loginSlice.actions

// export const userLogin = (data) => (dispatch) => {
//     return dispatch(
//         apiCallBegan({
//             url: data?.url,
//             method: data?.method,
//             body: data?.body,
//             headers: data.header,
//             onStart: userRequest.type,
//             onSuccess: userReceived.type,
//             onError: userFailed,
//         })
//     );
// };
