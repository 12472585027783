import { Button, IconButton, Skeleton, Stack, Typography, Box, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import "./imageRenderer.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { ReactComponent as PrintIcon } from "../../../../assests/actionIcons/printIcon.svg";
import { ReactComponent as SaveToCloudIcon } from "../../../../assests/actionIcons/cloudSave.svg";
import { ReactComponent as CloseIcon } from "../../../../assests/closeIcon.svg";
import { ReactComponent as AudioIcon } from "../../../../assests/actionIcons/music-icon.svg";
import { ReactComponent as VideoIcon } from "../../../../assests/actionIcons/video-play-icon.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../assests/signupScreen/LeftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../../../assests/signupScreen/RightArrow.svg";
import { ReactComponent as ZoomInIcon } from "../../../../assests/signupScreen/ZoomIn.svg";
import { ReactComponent as ZoomOutIcon } from "../../../../assests/signupScreen/ZoomOut.svg";

import ReactToPrint from "react-to-print";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { DeleteMyImages, SaveMyImages } from "../../../../redux/addImagesSlice";
import { useDispatch } from "react-redux";
import { RefreshToken } from "../../../../redux/authSlice";
import { loadSingleImage } from "../../../../redux/loadOldImage";

import { fileType } from "../../../../uicomponent/filter/constant";
import { SearchImages } from "../../../../redux/fetchSearchSlice";
import { useSnackbar } from "../../../../hook/useSnackbar";


// Options for Rect-pdf viewer
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const ImageRenderer = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1); //Number of pages in a Pdf File
  const [pageCount, setPageCount] = React.useState(1); // Total number of pages

  const [itemsPerPage, setItemsPerPage] = React.useState(21)

  const [open, setOpen] = React.useState(false); // For side Resize image renderer open and close
  const [load, setLoad] = React.useState(false); // For image loading for skeleton
  const [oldData, setOldData] = React.useState(props.data); // For saving data coming in props
  const [selectedItem, setSelectedItem] = React.useState(null); // For side Resize image open
  const printableDiv = React.useRef(); // Ref for printable div
  const { isLoggedin, accessToken, refreshToken } = useSelector(
    (state) => state.login
  ); // Check if user logged in or not from store
  const [addedImage, setAddedImage] = React.useState(false); // Check if resize image in selected array or not
  // const [addedMultipleImage, setAddedMultipleImage] = React.useState(false);
  const [nextImgDisabled, setNextImgDisabled] = React.useState(false);
  const [preImgDisabled, setPreImgDisabled] = React.useState(false);

  // const { images } = useSelector((state) => state.imagesData)

  const { pagination } = useSelector((state) => state.searchedImage);

  const { displaySnackbar } = useSnackbar();


  // -------------Counter for image Load-----------------
  const counter = React.useRef(0);
  const zoomRef = React.useRef(null);
  // ----------------------------------------------------

  // --------------For pdf renderer-----------------
  const [noPages, setNoPages] = useState(0);
  const [pdfPage, setPdfPage] = useState(1);
  // -----------------------------------------------

  // Function handle on change of props.selectedFilter
  useEffect(() => {
    const filterType = props.selectedFilters?.endCode
    const imageArray = Object.keys(props.data)
    if (filterType) {
      let newImageList = {}
      imageArray.map((k, i) => {
        let imageEndCode = returnImageName(k).slice(-3)
        if (filterType.includes(imageEndCode)) newImageList[k] = props.data[k]
      })
      setOldData(newImageList)
      setPage(1);
      // console.log(page)
    } else {
      setOldData(props.data)
      setPage(1);
      // console.log(page)
    }
    setOpen(false);
    setLoad(false);
    counter.current = 0;
    setPage(1);
    // if (props.place === 'MyImages') {
    //   var myImageData = oldData.map((k, i) => {
    //     return k = k.objectKey;
    //   })
    //   let newImageData = [...new Set(myImageData)]
    //   newImageData = newImageData.filter((ele) => ele.includes('.webp'));
    //   setPageCount(Math.ceil(newImageData.length / 21))
    // } else
    
     if (props.pageType === 'result') {
      setPageCount(pagination)
    } else {
      let data = Object.keys(props.data).filter((ele) => ele.includes('.webp'))
      setPageCount(Math.ceil(Object.keys(data).length / 21))
    }
  }, [props?.selectedFilters, props.data])




  // Function handle page change actions
  // on Pagination change
  // @params (value of that page)
  const handlePageChange = (event, value) => {
    setLoad(false);
    counter.current = 0;
    setPage(value);
    if (props.pageType === 'result') {
      // console.log(Object.keys(oldData).length / 21, value)
      let lastPath = Object.keys(oldData).reverse()[0]
      if (Object.keys(oldData).length / 21 < value) {
        let dataSize = Math.ceil(value - Object.keys(oldData).length / 21) * 21
        dispatch(
          SearchImages({
            method: "GET",
            body: {
              query: location?.search.replace("?text=", "").toLowerCase(),
              size: dataSize,
              lastPath: lastPath
            },
          })
        ).then((res) => {
          setOldData((current) => {
            let copy = { ...current };
            copy = { ...copy, ...res.payload.data }
            return copy;
          });
          // console.log(res.payload.data)
        })
      }
    }
  };


  // Function for opening Resize window image if thumbnail is there
  // @params (item details of that file)
  const handleSingleImage = (item) => {
    // console.log(item);
    var itemFileName = item.fileName.split('/').filter((ele) => ele !== "thumbnails")
    // console.log(itemFileName);
    // console.log(item.tittle);

    let realFileName = item.tittle.slice(-3)
    let extension = fileType[`${realFileName}`]
    // console.log(extension);
    // console.log(realFileName);
    let newFileNamePath = ""
    let result = item.tittle
    // console.log(result);
    let newTitle = itemFileName.pop()
    let newArray = itemFileName.push(`${result}.${extension}`)
    newFileNamePath = itemFileName.join('/')

    let header = {
      "Content-Type": "application/json"
    }

    dispatch(loadSingleImage({
      url: "/rest/images/fetch",
      header: header,
      method: "post",
      body: { folderPath: newFileNamePath, imagesPerPage: 100 }
    })).then((res) => {
      // console.log(res.payload.list);
      // item.fileName = res.payload.list[newFileNamePath];
      item.url = res.payload.list[newFileNamePath];
      item.fileType = returnImageType(newFileNamePath)
      setSelectedItem(item);
      // console.log(selectedItem);
    })

  }

  // Function for opening Resize window
  // @params (item details of that file)
  const handleImageResize = (item) => {
    // console.log(item)
    if (item?.fileIndex === 0) {
      setPreImgDisabled(true)
    } else {
      setPreImgDisabled(false)
    }
    if (item?.fileIndex === itemsPerPage-1) {
      setNextImgDisabled(true)
    } else {
      setNextImgDisabled(false)
    }
    item?.fileType === "webp" ? handleSingleImage(item) : setSelectedItem(item)

    // console.log(item);
    // console.log(selectedItem);
    item !== null ? setOpen(true) : setOpen(false);

    if (
      !open &&
      props.addedImages.findIndex((el) => el.fileName === item?.fileName) >= 0
    ) {
      setAddedImage(true);
    } else {
      setAddedImage(false);
    }
    // console.log(addedImage);
  };

  // Function return name of the image
  // excluding extension
  // @params (name of the file)
  const returnImageName = (name) => {
    return name?.split("/")
    [name?.split("/")?.length - 1]?.split(".")[0]
      .replaceAll("_", " ");
  };

  // Function return type of the image
  // excluding extension
  // @params (Type of the file)
  const returnImageType = (name) => {
    return name
      .split("/")
    [name.split("/").length - 1].split(".")[1]
      .replaceAll("_", " ");
  };


  // Function will slice a big array in chunks
  // of supplied size
  // @params [array, chunksize]
  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  // Function handle image loading
  // @param [data] --> images data of current page
  const handleImageOnLoad = (data) => {
    counter.current += 1;
    if (counter.current >= data?.length) {
      setLoad(true);
    }
  };

  // Function for navigating previous & next image in image resize window
  // @params (direction +1 or -1)
  const handleImageNavigation = (dir) => {
    // console.log(oldData);

    /* Some files are empty because there is no file extension. So it will remove empty file if there is no
     .webp extension after filtering. */
    let imageData = Object.keys(oldData).toString().includes('.webp');
    // console.log(imageData)
    let newImageData = []

    if (imageData) {
      newImageData = Object.keys(oldData).filter((ele) => ele.includes('.webp'));
    } else {
      newImageData = Object.keys(oldData);
    }

    // console.log(newImageData)

    if (props.place === 'MyImages') {
      var myImageData = oldData.map((k, i) => {
        return k = k.objectKey;
      })
      newImageData = [...new Set(myImageData)]
    }
    newImageData = newImageData.filter((ele) => ele.includes('.webp'));
    // console.log(filterData)
    // console.log(newImageData)
    // setMyImagePageCount(newImageData)

    let chunkData = sliceIntoChunks(newImageData, 21);
    // console.log(chunkData)

    let chunkDataIndex = chunkData[page - 1].findIndex(
      (ele) => ele === selectedItem?.fileName
    );
    // console.log(chunkDataIndex);

    const newIndex = chunkDataIndex + dir;
    // console.log('newIndex',newIndex);
    // console.log('Next Img Disabled',nextImgDisabled);
    let nextImageKey = chunkData[page - 1][newIndex];
    // console.log('nextImageKey', nextImageKey)
    let fileType = nextImageKey?.split(".");
    // console.log(fileType[fileType.length - 1]);
    let item = {
      url: oldData[nextImageKey],
      fileName: nextImageKey,
      tittle: returnImageName(nextImageKey),
      fileType: fileType?.length ? fileType[fileType?.length - 1] : "none",
      fileIndex: newIndex,
    }

    // console.log(item.fileType);

    if (item?.fileIndex === 0) {
      setPreImgDisabled(true)
    } else {
      setPreImgDisabled(false)
    }
    if (item?.fileIndex === itemsPerPage-1) {
      setNextImgDisabled(true)
    } else {
      setNextImgDisabled(false)
    }

    item.fileType === "webp" ? handleSingleImage(item) : setSelectedItem(item);
    // console.log(item);

    // console.log(props.addedImages)
    // var imageAdded = props.addedImages.findIndex((el) => el.fileName === item?.fileName) >= 0
    // console.log(imageAdded);
    if (
      !open &&
      props.addedImages.findIndex((el) => el.fileName === item?.fileName) >= 0
    ) {
      setAddedImage(true);
      // setAddedMultipleImage(true);

    } else {
      setAddedImage(false);
      // setAddedMultipleImage(false);
    }
  };

  // Function handle rendering of all images
  const renderImages = () => {
    let data = !Array.isArray(oldData) ? oldData : {};
    let flag = Array.isArray(oldData);
    // console.log('data', data);
    // console.log('flag', flag);
    if (flag) {
      oldData.map((k, i) => {
        data[k.objectKey] = k.url;
      });
    }
    data = Object.keys(data).filter((ele) => ele.includes('.webp'))

    data = sliceIntoChunks(data, 21);
    if (data[page-1] && data[page-1]?.length !== itemsPerPage) setItemsPerPage(data[page-1].length)

    if (oldData.length === 0 && props.loading) {
      return <Typography variant="caption">loading</Typography>;
    } else if (oldData.length === 0 && !props.loading) {
      return <Typography variant="caption">No Data Found.</Typography>;
    } else {
      return (
        <div className={["imagesContainer", open ? "grid-col4" : ""].join(" ")}>
          {data[page - 1]?.map((key, index) => {
            // console.log(key, index)
            let fileType = key.split(".");
            fileType = fileType[fileType.length - 1];
            if (returnImageName(key) !== "") {
              // console.log(key)
              return (
                <div
                  className="imagesSubContainer"
                  key={`images-${index}`}
                  onClick={() =>
                    handleImageResize({
                      url:
                        props.place === "MyImages"
                          ? oldData[
                            oldData.findIndex(
                              (ele) => ele.objectKey === key
                            )
                          ].url
                          : oldData[key],
                      tittle: returnImageName(key),
                      fileName: key,
                      fileType: fileType,
                      fileIndex: index,
                    })

                  }
                >
                  {/* {renderSkeleton()} */}
                  {fileType === "pdf" ?
                    <Document
                      file={
                        props.place === "MyImages"
                          ? oldData[
                            oldData.findIndex(
                              (ele) => ele.objectKey === key
                            )
                          ].url
                          : oldData[key]
                      }
                      onLoadSuccess={() => handleImageOnLoad(data[page - 1])}
                    >
                      {" "}
                      <Page width={144} height={200} className="pdfRender" pageNumber={1} scale={0.5} />
                    </Document>
                    :
                    <img
                      alt=""
                      className="imageRendererWidth"
                      src={
                        props.place === "MyImages"
                          ? oldData[
                            oldData.findIndex(
                              (ele) => ele.objectKey === key
                            )
                          ].url
                          : oldData[key]
                      }
                      onContextMenu={() => false}
                      // style={{ display: !load ? "none" : "block" }}
                      onLoad={() => handleImageOnLoad(data[page - 1])}
                    />
                  }
                  <Typography
                    fontSize="13px"
                    fontWeight="bold"
                    paddingTop="15px"
                    color="black"
                  >
                    {returnImageName(key)}
                  </Typography>
                  <Typography
                    variant="caption"
                    fontSize="12px"
                    color="black"
                    textTransform="lowercase"
                  >
                    www.myschool.in
                  </Typography>
                </div>
              );
            }
          })}
        </div>
      );
    }
  };

  // Function to render skeleton
  const renderSkeleton = () => {
    return (
      <Skeleton
        width={144}
        height={185}
        style={{ display: load ? "none" : "block", transform: "none" }}
      />
    );
  };

  // Function to download img or pdf or gif or any file type
  const downloadImage = async () => {
    // Check if user logged in
    if (!isLoggedin) {
      props.openLogin(true);
      return;
    }

    // 1 Credit will decrease per download file. So API is called.
    if (isLoggedin) {
      var myImagesData = []
      if (isLoggedin) {
        var myImagesData = []
        myImagesData.push(selectedItem?.fileName)


        let header = {
          "Content-Type": "application/json",
          // 'Accept': 'application/json',
          "Authorization": `Bearer ${accessToken}`
        }

        let data = {
          images: myImagesData,
          markFavourite: false
        }
        dispatch(SaveMyImages({
          headers: header,
          body: data
        })).then((res) => {
          if (res.payload.message === "Expired JWT") {
            dispatch(RefreshToken({
              headers: header,
              body: {
                "refreshToken": refreshToken
              }
            })).then((res) => {
              header["Authorization"] = `Bearer ${res.payload.accessToken}`
              dispatch(SaveMyImages({
                headers: header,
                body: data
              })).then((res) => {
                if (res.payload.message === "Success") {
                  props.handleRemoveAll()
                }
              })
            })
          }
        })
      }

      // Dynamic path for downloading file
      var itemFileName = selectedItem.fileName.split('/').filter((ele) => ele !== "thumbnails")
      let realFileName = selectedItem.tittle.slice(-3)
      let extension = fileType[`${realFileName}`]

      let newFileNamePath = ""
      let result = selectedItem.tittle
      let newTitle = itemFileName.pop()
      let newArray = itemFileName.push(`${result}.${extension}`)
      newFileNamePath = itemFileName.join('/')
      selectedItem.fileName = newFileNamePath

      // Function for Download File
      await fetch(selectedItem?.url, {
        //make get request to S3 link
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // necessary to add this header
        },
      }).then((response) => {
        return response.blob(); // extract blob
      }).then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", selectedItem.fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    };
  }

    const handleClose = () => {
      setOpen(false);
      setPdfPage(1);
    };

    const deleteImage = () => {
      // console.log(selectedItem.fileName);
      // console.log(oldData)

      let selectedFileName = oldData.filter((ele) =>
        ele.objectKey === selectedItem.fileName
      )
      // console.log(selectedFileName)
      let selectedFileID = selectedFileName.map((k) => {
        return k = k.id;
      })
      // console.log(selectedFileID)

      if (location.pathname === "/auth/images") {
        let header = {
          "Content-Type": "application/json",
          // 'Accept': 'application/json',
          "Authorization": `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        };

        let data = selectedFileID;
        // alert(data);
        dispatch(
          DeleteMyImages({
            headers: header,
            body: data,
          })
        ).then((res) => {
          // console.log(res)
          if (res.payload === 'Success') {
            displaySnackbar({ message: "Image Deleted" })
            setOpen(false);
            props.getMyImages();
            // props.getFavImages();
          }
          if (res.payload.status === 401) {
            dispatch(
              RefreshToken({
                headers: header,
                body: {
                  refreshToken: refreshToken,
                },
              })
            ).then((res) => {
              header["Authorization"] = `Bearer ${res.payload.accessToken}`;
              dispatch(
                DeleteMyImages({
                  headers: header,
                  body: data,
                })
              );
            });
          }
        });
      }
    };

    var path = location.pathname
      .split("/")
      .filter((el) => el)
      .filter((el) => el !== "views");

    return (
      <div className="mainContainer">
        <div className="imageWrapper">
          <div
            className="imageResize"
            style={open ? { display: "flex" } : { display: "none" }}
          />

          <div
            className={["imageResize", open ? "imageResizePosition" : ""].join(
              " "
            )}
            style={open ? { display: "flex", flex: 1 } : { display: "none" }}
          ></div>

          {/* -------------Pagination starts here----------------- */}
          <div style={{ flex: 1 }}>
            {renderImages()}
            <div className="imageSearchbarPagignationContainer">
              <Stack spacing={1}>
                <Pagination
                  count={pageCount}
                  variant="outlined"
                  size="large"
                  shape="rounded"
                  onChange={handlePageChange}
                  defaultPage={1}
                  page={page}
                  sx={{
                    "& ul > li > button": {
                      margin: 0,
                      backgroundColor: "white",
                    },
                    "& ul > li:not(:first-child):not(:last-child) > button": {
                      borderRadius: 0,
                    },
                    "& ul > li:first-child > button": {
                      borderRadius: "8px 0px 0px 8px",
                    },
                    "& ul > li:last-child > button": {
                      borderRadius: "0px 8px 8px 0px",
                    },
                  }}
                  hidePrevButton={true}
                />
              </Stack>
            </div>
          </div>
        </div>
        <canvas
          id="canvas"
          style={{ display: "none" }}
          width="595"
          height="842"
        ></canvas>

        <Drawer
          anchor="left"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        // variant="persistent"
        >
          <Box
            sx={{
              width: isMobile ? "100vw" : "40vw",
              height: "100vh",
              display: "flex",
              flex: 1,
              backgroundColor:
                path[0] === "academic"
                  ? "#7da8ff"
                  : path[0] === "earlycarrier"
                    ? "#71af47"
                    : path[0] === "edutainment"
                      ? "#e68900"
                      : path[0] === "printrich"
                        ? "#d351a8"
                        : path[0] === "infohub"
                          ? "#a46ee0"
                          : "#7da8ff",
            }}
          >
            <div className="homeGutter" />
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                gap: 40,
              }}
            >
              <div className={isMobile ? "mobileActionContainer" : "actionContainer"}>
                {isMobile ?
                  <div className="mobileActionButtons">
                    {isLoggedin ? (
                      <ReactToPrint
                        trigger={() => (
                          <IconButton disableRipple>
                            <PrintIcon />
                          </IconButton>
                        )}
                        pageStyle="@page { scale : custom 50 }"
                        content={() => printableDiv.current}
                      />
                    ) : (
                      <IconButton
                        disableRipple
                        onClick={() => {
                          props.openLogin(true);
                        }}
                      >
                        <PrintIcon />
                      </IconButton>
                    )}

                    <IconButton onClick={downloadImage} disableRipple>
                      <SaveToCloudIcon />{" "}
                    </IconButton>

                    <IconButton disabled disableRipple>
                      <AudioIcon color="C0C0C0" />
                    </IconButton>

                    <IconButton disabled={true} disableRipple>
                      <VideoIcon color="C0C0C0" />
                    </IconButton>

                    {selectedItem?.fileType === "pdf" ? (
                      <>
                        <IconButton>
                          <ZoomInIcon
                            onClick={() => zoomRef.current.zoomIn(0.2)}
                            color="black"
                          />
                        </IconButton>

                        <IconButton>
                          <ZoomOutIcon
                            onClick={() => zoomRef.current.zoomOut(0.2)}
                            color="black"
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton>
                          <ZoomInIcon
                            onClick={() => zoomRef.current.zoomIn(0.2)}
                            color="black"
                          />
                        </IconButton>

                        <IconButton>
                          <ZoomOutIcon
                            onClick={() => zoomRef.current.zoomOut(0.2)}
                            color="black"
                          />
                        </IconButton>
                      </>
                    )}

                    <IconButton onClick={() => handleImageNavigation(-1)}
                      disabled={preImgDisabled ? true : false}
                    >
                      <LeftArrowIcon
                        color="black"
                      // color={preImgDisabled ? "c0c0c0" : "black"}
                      />
                    </IconButton>
                    <IconButton onClick={() => handleImageNavigation(1)}
                      disabled={nextImgDisabled ? true : false}
                    >
                      <RightArrowIcon
                        color="black"
                      // color={nextImgDisabled ? "c0c0c0" : "black"}
                      />
                    </IconButton>

                    <IconButton onClick={() => {
                      handleImageResize(null)
                      setPdfPage(1)
                      setPreImgDisabled(false)
                      setNextImgDisabled(false)
                    }}>
                      <CloseIcon color="black" />
                    </IconButton>
                  </div> :
                  <>
                    <div className="actionButtons">
                      {isLoggedin ? (
                        <ReactToPrint
                          trigger={() => (
                            <IconButton disableRipple>
                              <PrintIcon />
                            </IconButton>
                          )}
                          pageStyle="@page { scale : custom 50 }"
                          content={() => printableDiv.current}
                        />
                      ) : (
                        <IconButton
                          disableRipple
                          onClick={() => {
                            props.openLogin(true);
                          }}
                        >
                          <PrintIcon />
                        </IconButton>
                      )}

                      <IconButton onClick={downloadImage} disableRipple>
                        <SaveToCloudIcon />{" "}
                      </IconButton>

                      <IconButton disabled disableRipple>
                        <AudioIcon color="C0C0C0" />
                      </IconButton>

                      <IconButton disabled={true} disableRipple>
                        <VideoIcon color="C0C0C0" />
                      </IconButton>
                    </div>

                    <div className="navigationButtons">
                      {selectedItem?.fileType === "pdf" ? (
                        <>
                          <IconButton>
                            <ZoomInIcon
                              onClick={() => zoomRef.current.zoomIn(0.2)}
                              color="black"
                            />
                          </IconButton>

                          <IconButton>
                            <ZoomOutIcon
                              onClick={() => zoomRef.current.zoomOut(0.2)}
                              color="black"
                            />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton>
                            <ZoomInIcon
                              onClick={() => zoomRef.current.zoomIn(0.2)}
                              color="black"
                            />
                          </IconButton>

                          <IconButton>
                            <ZoomOutIcon
                              onClick={() => zoomRef.current.zoomOut(0.2)}
                              color="black"
                            />
                          </IconButton>
                        </>
                      )}

                      <IconButton onClick={() => handleImageNavigation(-1)}
                        disabled={preImgDisabled ? true : false}
                      >
                        <LeftArrowIcon
                          color="black"
                        // color={preImgDisabled ? "c0c0c0" : "black"}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleImageNavigation(1)}
                        disabled={nextImgDisabled ? true : false}
                      >
                        <RightArrowIcon
                          color="black"
                        // color={nextImgDisabled ? "c0c0c0" : "black"}
                        />
                      </IconButton>

                      <IconButton onClick={() => {
                        handleImageResize(null)
                        setPdfPage(1)
                        setPreImgDisabled(false)
                        setNextImgDisabled(false)
                      }}>
                        <CloseIcon color="black" />
                      </IconButton>
                    </div>
                  </>}

              </div>
              {selectedItem?.fileType === "pdf" ? (
                <Document
                  file={selectedItem.url}
                  onLoadSuccess={(e) => setNoPages(e._pdfInfo.numPages)}
                  options={options}
                >
                  <TransformWrapper ref={zoomRef}>
                    <TransformComponent>
                      <Page className="transformComponent" width={500} pageNumber={pdfPage} />
                    </TransformComponent>
                  </TransformWrapper>

                  <div className="page-controls">
                    <button
                      disabled={pdfPage <= 1}
                      onClick={() => setPdfPage(pdfPage - 1)}
                      type="button"
                      aria-label="Previous page"
                    >
                      ‹
                    </button>

                    <span>
                      {pdfPage} of {noPages}
                    </span>

                    <button
                      disabled={pdfPage >= noPages}
                      onClick={() => setPdfPage(pdfPage + 1)}
                      type="button"
                      aria-label="Next page"
                    >
                      ›
                    </button>
                  </div>
                </Document>

              ) : (
                <TransformWrapper ref={zoomRef}>
                  <TransformComponent>
                    <div className="drawerImage">
                      <img
                        ref={printableDiv}
                        alt=""
                        style={{ width: "80%", flex: 0.4 }}
                        src={selectedItem?.url}
                        className="academicImage"
                      />
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              )}

              <div className="bottomContainer">
                <Typography style={{ color: "black" }}>
                  {selectedItem?.tittle}
                </Typography>
                {location.pathname === "/auth/images" ? (
                  <Button className="bottomContainerButton" onClick={deleteImage}>
                    Delete Image
                  </Button>
                ) : (
                  <Button
                    className="bottomContainerButton"
                    onClick={() => {
                      props.addToNoSelectImage(selectedItem, addedImage)
                      setAddedImage(addedImage ? false : true)
                      // console.log(item)
                      // deleteImage()
                    }}
                  >
                    {(path[0] === "academic" ||
                      path[0] === "result" ||
                      path[0] === "sections") &&
                      addedImage
                      // props.addedImages.findIndex((el) => el.fileName === item?.fileName) >= 0
                      ? "Remove Image"
                      : "Select Image"}
                  </Button>
                )}
              </div>
            </div>
            <div className="homeGutter" />
          </Box>
        </Drawer>
      </div>
    );
  };
  export default ImageRenderer;
