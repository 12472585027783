import React from 'react'
import { Breadcrumbs, Link } from '@mui/material'
import { useLocation } from 'react-router-dom'

const DynamicBreadcrumb = (props) => {
    let location = useLocation()

    let breads = location.pathname.split("/").filter(element => element)
    let filteredBread = []

    if (breads[0] === 'views') {
        filteredBread = location.pathname.split("/").filter(element => element).filter(el => el !== "views")
        // alert(breads);
    } else if (breads[0] === 'auth'){
        filteredBread = location.pathname.split("/").filter(element => element).filter(el => el !== "auth")
    }

    const linkGenerator = (index) => {
        let link = ""
        for (let i = 0; i <= index; i++) {
            link = link + "/" + filteredBread[i]
        }
        if (breads[0] === 'views') {
            return '/views' + link
        } else if (breads[0] === 'auth') {
            return '/auth' + link
        }
    }
    // console.log(filteredBread)
    return (
        <Breadcrumbs aria-label="breadcrumb" className="headerBreadcrumbs">

            {/* <Link underline="hover" color="secondary" href="views" fontSize="16px !important" >
                 Home 
            </Link> */}
            {filteredBread.map((k,i) => 
                <Link key={`breadcrumbs-${i}`} underline="hover" color={props.color ? props.color : "secondary"} 
                    href={(i === filteredBread.length - 1) ? null : linkGenerator(i)} aria-current="page" fontSize="16px !important"
                    fontWeight={(i === filteredBread.length - 1) ? 'bold !important' : "400 !important"}>
                    {k.toUpperCase().replace('-', ' ')}
                </Link>
            )}
           
        </Breadcrumbs>
    )
}

export default DynamicBreadcrumb