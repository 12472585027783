import { menuItemsMakers } from "./menuItemsMakers";

export const menuItemsPrintRich = [
    {
        "children": [
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "2b.....Indian features.com.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "PDF",
                                "type": "directory"
                            }
                        ],
                        "title": "CATALOG",
                        "type": "directory"
                    }
                ],
                "title": "INDIAN FEATURES",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "title": "ALL IN ONE CATALOGUE.pdf",
                                "type": "file"
                            }
                        ],
                        "title": "CATALOG",
                        "type": "directory"
                    }
                ],
                "title": "PRINTING RIGHTS",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "TAL_FINAL CATALOGUE (1).pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "PDF",
                                "type": "directory"
                            }
                        ],
                        "title": "CATALOG",
                        "type": "directory"
                    }
                ],
                "title": "TAL_FINAL",
                "type": "directory"
            }
        ],
        "title": "PUBLISHING",
        "type": "directory"
    }
].concat(menuItemsMakers)