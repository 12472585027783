import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const DeleteMyImages = createAsyncThunk(
    'images/myImages/delete',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/myImages/delete',
                method: 'DELETE',
                // config: {
                //     headers: {
                //         "Content-type": "Application/json",
                //         "Access-Control-Allow-Origin": "*",
                //     }
                // },
                headers: data?.headers,
                data: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })

export const SaveMyImages = createAsyncThunk(
    'images/myImages/save',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/myImages/save',
                method: "PUT",
                headers: data?.headers,
                data: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const GetMyImages = createAsyncThunk(
    'images/myImages/get',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/myImages/get',
                method: "GET",
                headers: data?.headers,
                params : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export const GetFavImages = createAsyncThunk(
    'images/myImages/getFavourite',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/myImages/getFavourite',
                method: "GET",
                headers: data?.headers,
                params : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const AddImagesSlice = createSlice({
    name: "imagesData",
    initialState: {
        images: [],
        hasMore: false,
        msg: null,
        loading: false
    },

    reducers: {},

    extraReducers: {
        [GetMyImages.fulfilled]: (state, { payload }) => {
            state.images = payload.images
            state.hasMore = payload.hasMore
            state.loading = false;
        },
        [GetMyImages.pending]: (state) => {
            state.loading = true;
        },
        [GetMyImages.rejected]: (state, { payload }) => {
            // state.msg = payload
            state.loading = false;
        },

        [GetFavImages.fulfilled]: (state, { payload }) => {
            state.images = payload.images
            state.hasMore = payload.hasMore
            state.loading = false;
        },
        [GetFavImages.pending]: (state) => {
            state.loading = true;
        },
        [GetFavImages.rejected]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },

        [SaveMyImages.fulfilled]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },
        [SaveMyImages.pending]: (state) => {
            state.loading = true;
        },
        [SaveMyImages.rejected]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },

        [DeleteMyImages.fulfilled]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },
        [DeleteMyImages.pending]: (state) => {
            state.loading = true;
        },
        [DeleteMyImages.rejected]: (state, { payload }) => {
            state.msg = payload
            state.loading = false;
        },
       
    }
});

export default AddImagesSlice.reducer;
