import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import TeachersIcon from "../../../../../assests/auth/teachersIcon.svg";
import Btn from "../../../../../customTheme/button/Button";
import MSTextField from "../../../../../customTheme/textField/MSTextField";
import "../../student/addNewStudent/AddNewStudent.css";
import { addUser } from "../../../../../redux/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../../../../hook/useSnackbar";
import { RefreshToken } from "../../../../../redux/authSlice";
import formValidation from "../../../../../formValidation/validation"





const Teachers = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { displaySnackbar } = useSnackbar();

  const { accessToken, refreshToken, tokenExpiry, userRole } = useSelector((state) => state.login)
  const [emailErr, setEmailErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [addErr, setAddErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [schoolErr, setSchoolErr] = useState(false);

  const [userData, setUserData] = React.useState({
    userRole: "TEACHER", city: "", name: "", emailId: "", mobileNumber: "", state: "", address: ""

  })



  const handleFieldsChange = (e, fieldName) => {
    e.preventDefault()




    setUserData(current => {
      const copy = { ...current }
      copy[fieldName] = e.target.value
      return copy
    })
  }

  // Function handle generation of new Teacher
  const handleSave = () => {
    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    // var newMessage=formValidation(userData)
    //      if(newMessage==='true') {


    dispatch(addUser({
      headers: header,
      body: userData
    })).then((res) => {
      if (res.payload.addedBy) {
        displaySnackbar({ message: 'Teacher added successfully' })
        setUserData({
          userRole: userRole, city: "", name: "", emailId: "", mobileNumber: "", state: "", address: "",
          schoolCode: "",
        })
      }
      if (res.payload.message) {
        const msg = res.payload.message.split('.')[0]
        displaySnackbar({ message: msg })
      }
      if (res.payload.error) {
        displaySnackbar({ message: res.payload.error })
      }
      if (res.payload.message === "Expired JWT") {
        dispatch(RefreshToken({
          headers: header,
          body: {
            "refreshToken": refreshToken
          }
        })).then((res) => {
          header["Authorization"] = `Bearer ${res.payload.accessToken}`
          dispatch(addUser({
            headers: header,
            body: userData
          })).then((res) => {
            if (res.payload.addedBy) {
              displaySnackbar({ message: 'Teacher added successfully' })
              setUserData({
                userRole: userRole, city: "", name: "", emailId: "", mobileNumber: "", state: "", address: "",
                schoolCode: "",
              })
            }
            if (res.payload.message) {
              const msg = res.payload.message.split('.')[0]
              displaySnackbar({ message: msg })
            }
            if (res.payload.error) {
              displaySnackbar({ message: res.payload.error })
            }
          })
        })
      }
    })

    // } else {
    //   displaySnackbar({ message:newMessage});
    // }






    //     if (!validEmail.test(userData.emailId)) {
    //       setEmailErr(true);
    //    }

    //    if (!validmobileNumber.test(userData.mobileNumber)) {
    //     setPwdErr(true);
    //  }

    //  if (!validUserName.test(userData.name)) {
    //   setNameErr(true);
    // }

    // if(!validUserAddress.test(userData.address)){
    //   setAddErr(true);

    // }
    // if(!validCityName.test(userData.city)){
    //   setCityErr(true);
    // }

    // if(!validStateName.test(userData.state)){
    //   setStateErr(true);
    // }

    // if(!validSchoolCode.test(userData.schoolCode)){
    //   setSchoolErr(true);
    // }


  }


  return (
    <div className="addStudentManinContainer">

      <div className="addStudentContainer">
        <div className="addStudentHeaderTxtContainer">
          <img src={TeachersIcon} alt="teachers icon" />
          <Typography fontSize="15px" fontWeight="bold">
            Add New Teacher
          </Typography>
        </div>
        <div className="addStudentHeaderBtnContainer">
          <BrowserView>
            <Button variant="outlined" className="cancelBtn" onClick={props.handleCancel}>Cancel</Button>
          </BrowserView>
          <MobileView>
            <Btn onClick={() => navigate(-1)} />
          </MobileView>
          <Button style={{ backgroundColor: "#B3DAFF" }} className="saveDataBtn" onClick={handleSave}>Save Data</Button>
          {/* {emailErr && <p>Your email is invalid</p>} 
       {pwdErr && <p>Your Mobile Number is invalid</p>} 
       {nameErr && <p>UserName is invalid</p>} 
       {addErr && <p>Address is invalid</p>} 
       {cityErr && <p>City Name Is Invalid</p>}
       {stateErr && <p>State Name Is Invalid</p>}
       {schoolErr && <p>School Code Is Invalid</p>} */}
        </div>
      </div>
      <form className="addNewTeacherContainer">
        <Paper
          className="AddFormContainer"
          style={{ backgroundColor: "white" }}
        >
          <div className="addTextfieldContainer">
            <div className="AddStudentTextFieldContainer">
              <MSTextField id="teacherUsername" type="text" placeholder="Enter your user name"
                value={userData.name}
                label="* User Name" onChange={handleFieldsChange} fieldName="name"
              />
              <MSTextField id="teacherEmail" type="email" placeholder="teacher@email.com"
                value={userData.emailId}
                label="* Email ID " onChange={handleFieldsChange} fieldName="emailId"
              />
              <MSTextField id="teacherMobileNumber" type="text" placeholder="Mobile Number"
                value={userData.mobileNumber}

                label="* Mobile Number" onChange={handleFieldsChange} fieldName="mobileNumber"
              />
            </div>

            <div className="AddStudentTextFieldContainer">
              {/* <MSTextField id="teacherUsername" type="text" placeholder="School Name"
                label="* School Name" onChange={handleFieldsChange}
                fieldName="schoolName"
              /> */}
              <MSTextField id="teacherAddress" type="text" placeholder="Enter your Address"
                value={userData.address}
                label="Address" onChange={handleFieldsChange} fieldName="address"
              />
              <MSTextField id="teacherCity" type="text" placeholder="City"
                value={userData.city}
                label="City" onChange={handleFieldsChange} fieldName="city"
              />
              <MSTextField id="teacherState" type="text" placeholder="State"
                value={userData.state}
                label="State" onChange={handleFieldsChange} fieldName="state"
              />
            </div>
            <div className="AddStudentTextFieldContainer">
              {userRole == "ADMIN" ?
                <MSTextField id="teacherUsername" type="text" placeholder="School Code ex. 123456789"
                  value={userData.schoolCode}
                  label="* School Code" onChange={handleFieldsChange} fieldName="schoolCode"
                /> : null}
            </div>
          </div>
        </Paper>
      </form>
    </div>
  );
};
export default Teachers;
