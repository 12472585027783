import React from 'react'


const Publishing = () => {
    return (
        <>
            Print Rich
        </>
    )
}

export default Publishing