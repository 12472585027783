import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';

import { SectionTab } from 'polotno/side-panel';
import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';

import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MakersTemplate } from '../../../redux/makersTemplateSlice';
import {ReactComponent as TemplateIcon} from './assests/template.svg'
import axios from 'axios';

export const TemplatesPanel = observer(({ store }) => {
  // load data
  let location = useLocation()
  const dispatch = useDispatch()

  let makerType = location.pathname.split("/")[4]

  const [templates, setTemplates] = useState([])
  const [thumb, setThumb] = useState([])
  const [continuationToken, setContinuationToken] = useState(null)

  const header = {
    "Content-Type": "application/json",
    "Cache-Control": 'no-cache'
  }

  useEffect(() => {
    
    dispatch(MakersTemplate({
      header: header,
      path: makerType,
      type: 'template',
      continuationToken: continuationToken
    })).then((res) => {
      let data = []
      setContinuationToken(res.payload.thumb.continuationToken)
      Object.keys(res.payload.thumb?.list).map((k, i) => {
        let name = (k.split('/'))
        name = name[name.length - 1].split('.')[0]
        if (name !== "") {
          data.push({
            url: res.payload.thumb.list[k],
            preview: res.payload.thumb.list[k],
            name: name
          })
        }
      })
      setThumb(data)
    })
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <ImagesGrid
        shadowEnabled={false}
        images={thumb.map((data) => data).flat()}
        getPreview={(item) => item.preview}
        isLoading={!thumb.length}
        onSelect={async (item) => {
          // download selected json
          let folderPath = `PRINT RICH/makersTemplate/Maker Templates Json/${item.name}.json`
          const req = await axios.request({
            baseURL: "https://api.myschool.in",
            url: '/rest/images/fetch',
            method: 'POST',
            headers: header,
            data: {
              "folderPath": folderPath,
              "continuationToken": null,
              "imagesPerPage": 2
            }
          });
          const resq = await fetch(req.data.list[folderPath])
          // console.log(await resq.json())
          const json = await resq.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        rowsNumber={2}
      />
    </div>
  );
});

// define the new custom section
export const TemplatesSection = {
  name: 'custom-templates',
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <TemplateIcon />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: TemplatesPanel,
};
