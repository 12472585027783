import { Button, Typography, Tabs, Tab, Box } from "@mui/material";
import React from "react";
import AuthSearchField from "../../../../customTheme/authSearchField/AuthSearchField";
import "./ImageScreen.css";
import imageicon from "../../../../assests/dummyImages/imges.svg";
import Pagination from "@mui/material/Pagination";
import { isMobile } from "react-device-detect";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import ImageRenderer from "../imageRenderer/ImageRenderer";
import { GetFavImages, GetMyImages } from "../../../../redux/addImagesSlice";
import { RefreshToken } from "../../../../redux/authSlice";
import pendingApprovalSlice, { pendingApproval } from "../../../../redux/pendingApprovalSlice";
import UploadFile from "./Tabs/UploadFile";
import FavImages from "./Tabs/FavImages";
import PendingApproval from "./Tabs/PendingApproval";
import MyImages from "./Tabs/MyImages";
import AdminUploadFile from "./Tabs/AdminUploadFile";
import { tabsRender } from "./constant";



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(value, index);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  // console.log(index);
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ImageScreenInitializer = () => {

  const [pendingImages, setPendingImages] = React.useState([]);
  const [value, setValue] = React.useState(0); // value denotes to tabs number here
  const { images, hasMore, loading } = useSelector((state) => state.imagesData)
  const { refreshToken, accessToken, userRole } = useSelector((state) => state.login);
  // const { url } = useSelector((state) => state.pendingImageApproval);

  const dispatch = useDispatch()

  // Function handle change of tabs 
  // [0 - My Images, 1 - Favorite Images]
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log('value', value);
   // console.log(pendingImages)
  };

  return (
    <div className="myImageMainContainer">
      <div className="homeGutter" />
      <div className="myImageTextContainer">
        <img alt="" src={imageicon} />
        {userRole === "ADMIN" ? <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} className='imageHeading'>
          {value === 0 ? "Images For Approval" : "Upload File"}
        </Typography> : <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} className='imageHeading'>
          {value === 0 ? "My Images" : value === 1 ? "Favourite Images" : "Upload File"}
        </Typography>}

      </div>
      {/* <div className="homeGutter" /> */}

      <div>
        <div className='heading'>
          <div className="myImageSearchbarContainer">
            {/* Below div is set to display none. */}
            <div classname='myImageSearchAuthSearchFieldContainer' style={{ display: 'none', flex: 1, gap: 20 }}>
              <AuthSearchField
                id="signUpEmail"
                type="email"
                placeholder="Search by Image Name Or Image Category"
              />
              <Button variant="outlined" className="authSearchbtn">
                Search
              </Button>
            </div>

            <Tabs value={value} onChange={handleChange} disableRipple
              sx={{
                ".MuiTabs-flexContainer": {
                  justifyContent: isMobile ? 'center' : 'flex-end'
                },
                ".MuiTabs-indicator": {
                  display: 'none'
                }
              }}
              className="myImageTabMenuContainer" >

              {userRole == "ADMIN" ? null : <Tab label={<Button variant="outlined" className="authSearchbtn">
                My Images
              </Button>} {...a11yProps(userRole !== "ADMIN" ? 0 : 4)} />}

              {userRole == "ADMIN" ? null : <Tab label={
                <Button variant="outlined" className="authSearchbtn">
                  Favourite Images
                </Button>
              } {...a11yProps(userRole !== "ADMIN" ? 1 : 5)} />}

              {userRole == "ADMIN" ? <Tab label={<Button variant="outlined" className="authSearchbtn">
                Images for Approval
              </Button>} {...a11yProps(userRole == "ADMIN" ? 0 : 6)} /> : null}

              {isMobile ? null : <Tab label={<Button variant="outlined" className="authSearchbtn">
                Upload File
              </Button>} {...a11yProps(userRole == "ADMIN" ? 1 : 2)} />}

            </Tabs>


            {/* <Tabs value={value} onChange={handleChange} disableRipple
              sx={{
                ".MuiTabs-flexContainer": {
                  justifyContent: isMobile ? 'center' : 'flex-end'
                },
                ".MuiTabs-indicator": {
                  display: 'none'
                }
              }}
              className="myImageTabMenuContainer">
              {tabsRender.map((k, i) => {
                return
                <Tab label={k.label} />
              })}
            </Tabs> */}



          </div>

          <div>
            <TabPanel value={value} index={userRole !== "ADMIN" ? 0 : 4}>
              <MyImages value={value} />

            </TabPanel>

            <TabPanel value={value} index={userRole !== "ADMIN" ? 1 : 5}>
              <FavImages />

            </TabPanel>

            <TabPanel value={value} index={userRole == "ADMIN" ? 0 : 6}>
              <PendingApproval pendingImages={pendingImages} />

            </TabPanel>

            <TabPanel value={value} index={userRole == "ADMIN" ? 1 : 2} className="myImageUploadTabContainer">
              {userRole === "ADMIN" ? <AdminUploadFile /> : <UploadFile />}
            </TabPanel>

          </div>
        </div>
        <div className="homeGutter" />
      </div>
    </div>
  );
};

export default ImageScreenInitializer;