import { menuItemsMakers } from "./menuItemsMakers";

export const menuItemsEarlyCarrer = [
    {
        "title": "BUDDING CAREER",
        "type": "directory",
        "children": [

            {
                "children": [],
                "title": "CAREER PATH MAPS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "CIVILS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "COMPUTER LESSONS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "EARLY CAREER PROGRAMME",
                "type": "directory"
            },
            {
                "children": [],
                "title": "EDUCATION MAGAZINES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "FOUNDATION COURSES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "IIT",
                "type": "directory"
            },
            {
                "children": [],
                "title": "ITI RESOURCES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "JEE RESOURCES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "LEARN A SKILL",
                "type": "directory"
            },
            {
                "children": [],
                "title": "MIND MAPPING INFOGRAPHICS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "NEET RESOURCES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "PARENT TEACHER ACTIVITIES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "PERSONALITY DEVELOPMENT",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SELF PUBLISHING SERVICES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SKILL RESOURCES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "TEACHER MANUALS",
                "type": "directory"
            }
        ]
    },
].concat(menuItemsMakers)