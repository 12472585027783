import React from "react";
import "./Navbar.css";
import {
  Button,
  Menu,
  MenuItem,
  Fade,
  Link,
  Typography,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { isMobile } from "react-device-detect";

import home from "../../../assests/homeScreen/home.svg";
import hamburgerIcon from "../../../assests/header/hamburger.svg";

import DynamicBreadcrumb from "../../../customTheme/breadcrumbs/breadcrumbs";

// Accordians for Mobile Menus
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { languageOptions } from "./Constant";
import { useSelector } from "react-redux";

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(
    new Array(props.data.length).fill(null)
  ); // dynamically add array according to length of data
  const [expanded, setExpanded] = React.useState(false);

  const { appType } = useSelector((state) => state.login);
  // Mobile Menu Main Accordion

  const location = useLocation();
  let pathName = location.pathname.split("/");
  // Function handle on click event for navbar menu clicked
  // handle anchoring of overlay menus with the navbar menu btn
  // @param (event, index of menu clicked)
  const handleClick = (event, index) => {
    anchorEl[index] = event.target;
    setAnchorEl([...anchorEl]); // spread operator to modify anchoring array and forcefully update render
  };

  // Function handle on close events for closing or de anchoring
  // menus overlays
  // @param (index of menu to be close or mounted menu)
  const handleClose = (index) => {
    anchorEl[index] = null;
    setAnchorEl([...anchorEl]); // spread operator to modify anchoring array and forcefully update render
  };

  // Function handle selected items of menus
  // @param (index of parent in navbar, index of parent child selected)
  const handleItemsClick = (navIndex, childIndex, title) => {
    props.handleSelectedNavbar(navIndex); // Like Grade, class, etc
    props.handleSelectedChild(childIndex); // Like Grade 1 and all

    // if (title === "Chart Maker") {
    //   handleClose(navIndex);
    // } else {
    props.handleSetSelectedMenu(0);
    props.handleSetMenu();
    props.handleImageFetch("navbar", navIndex, childIndex);
    handleClose(navIndex);
    // }
  };

  const getCurrentPath = () => {
    let pathName = location.pathname.split("/");
    // console.log(pathName);
    return pathName;
  };

  const removeHTML = (str) => {
    str = renderToString(str.title)
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(" ", "-")
      .toLowerCase();
    return str;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [anchorLanguageEl, setAnchorLanguageEl] = React.useState(null);
  const opened = Boolean(anchorLanguageEl);

  const handleLanguageClick = (event) => {
    setAnchorLanguageEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorLanguageEl(null);
  };

  const navLanguageOptions = () => {
    return (
      <div className="navLanguageOptions">
        {languageOptions.map((key, index) => {
          return (
            <MenuItem className="navLanguageRender">
              <Link
                to={key.url}
                key={`nav-${index}`}
                className="navLanguageOptionsLink"
              >
                <Typography color='White' fontFamily="Roboto" fontWeight={100} fontSize='14px'>{key.name.toUpperCase()}</Typography>
              </Link>
            </MenuItem>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className="navbarMenuDiv"
    // style={
    //   isMobile
    //     ? { maxHeight: "calc(100vh - 230px)", overflowY: "auto" }
    //     : null
    // }
    >
      <div className={isMobile ? "mobGutter" : opened ? "navbarHomeGutterOpened" : "navbarHomeGutter"}>
        {isMobile ? null : (
          <div className="languageMainContainer">
            <IconButton

              className="languageButtonIcon"
              id="avatarBtn"
              aria-controls={opened ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={
                opened ? "true" : undefined}
              onClick={handleLanguageClick}

            >
              <Typography className="languageButton" fontSize={17} padding='5px' 
                style={{display: opened ? 'none' :'flex'}}>
                L
              </Typography>
            </IconButton>
            {opened ? <div className="navLanguageHeading">
              {/* <Typography className="languageButton" fontSize="16px">L</Typography> */}
              <Typography className="navLanguageTypo" color='white' fontFamily="sans-serif" fontSize='15px' padding='5px'>LANGUAGE</Typography>
            </div> : null}
            <Menu style={{ boxShadow: 'none', maxHeight: '70vh', fontSize: '15px' }}
              classes={{ paper: "languageMenu" }}
              anchorEl={anchorLanguageEl}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              open={opened}
              sx={{
                ".MuiMenu-paper": {
                  background: 'linear-gradient(rgb(215 18 117) 0%, rgb(217 27 122 / 42%) 100%) 0% 0% no-repeat padding-box padding-box transparent !important',
                  // backgroundColor:' #ffdaec',
                  boxShadow: "none",
                  //  borderRadius: "0px 0px 8px 8px",
                  opacity: 1,
                  color: "black",
                  marginLeft: "63.45px",
                  borderRadius: "0px 8px 8px",
                  top: '122px !important'


                },
              }}
              onClose={handleLanguageClose}
              MenuListProps={{
                "aria-labelledby": "avatarBtn",
              }}
            >

              {navLanguageOptions()}

            </Menu>
          </div>
        )}
      </div>
      {isMobile && props.overlay === undefined ? (
        <div className="mobNavbarMenu">
          <div className="icon" style={{ display: "flex", flex: 1, gap: '10px' }}>
            <Link
              underline="hover"
              color="text.primary"
              href="/"
              aria-current="page"
              style={{display : appType === "none" ? "none" : "flex"}}
            >
              <img alt="" src={home} style={{ width: '31px', paddingTop: '6px' }} />
            </Link>
            <DynamicBreadcrumb color={"white"} />
          </div>

          {appType !== "none" ? <div
            onClick={() => props.mobileDrawer(true)}
            className="hamburgerIcon"
          >
            <img src={hamburgerIcon} />
          </div> : <div onClick={() => window.flutter_inappwebview.callHandler('closeWebView', '*')}>
            <Typography>Back to App</Typography>
            </div>}
        </div>
      ) : null}
      {isMobile && props.overlay === undefined ? null :
        <>
          <nav className="navbarMenu" style={props?.overlay ? { flex: 1 } : null}>
            {!props?.overlay ? (
              <ul
                className="menus"
                style={
                  props.overlay
                    ? { flexDirection: "column", alignItems: "flex-start" }
                    : {}
                }
              >
                {props.data.map((k, i) => {
                  return (
                    <div key={`mid-header-${i}`} style={{ height: "100%" }}>
                      <Button
                        disabled={k?.title.toLowerCase() === "more"}
                        aria-controls={
                          Boolean(anchorEl[i])
                            ? `fade-${k.title}-${i}`
                            : undefined
                        }
                        aria-haspopup="true"
                        sx={{
                          "&:hover": {
                            backgroundColor: "black",
                          },
                          "&:active": {
                            backgroundColor: "black",
                          },
                          "&": {
                            width: props.overlay
                              ? "unset"
                              : pathName[2] === "early-career"
                                ? "160px"
                                : "125px",
                          },
                        }}
                        className={[
                          Boolean(anchorEl[i]) ? "navbarMenuActive" : null,
                          props.active === i ? "selectedActive" : null,
                        ].join(" ")}
                        style={{
                          color: "white",
                          fontFamily: ("Roboto", "sans-serif"),
                          fontSize: 15,
                          height: "100%",
                        }}
                        aria-expanded={Boolean(anchorEl[i]) ? "true" : undefined}
                        onClick={(e) => handleClick(e, i)}
                      >
                        {k.title}
                      </Button>
                      <Menu
                        id={`fade-${k.title}-${i}`}
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        classes={{ paper: "navbarMenuPaper" }}
                        anchorEl={anchorEl[i]}
                        open={Boolean(anchorEl[i])}
                        onClose={() => handleClose(i)}
                        TransitionComponent={Fade}
                        elevation={0}
                      >
                        {k.children
                          .sort((a, b) => a.title < b.title)
                          .map((ck, ci) => (
                            <NavLink
                              key={`mid-header-${i}-${ci}`}
                              style={{ textDecoration: "none" }}
                              exact="true"
                              className="navbarMenuLink"
                              to={
                                ck.disable
                                  ? null
                                  : // { pathname: `/views/${k.title.toLowerCase()}/${removeHTML(ck)}`, search: `?main=${i}&mu=${ci}` } :
                                  {
                                    pathname: `/views/${getCurrentPath()[2]
                                      }/${k.title.toLowerCase()}/${removeHTML(
                                        ck
                                      )}`,
                                    search: `?main=${i}&mu=${ci}`,
                                  }
                              }
                            >
                              <MenuItem
                                style={{ paddingLeft: 25, paddingRight: 25 }}
                                className="navbarMenuItem"
                                onClick={() => {
                                  if (!ck.disable)
                                    handleItemsClick(i, ci, ck.title);
                                }}
                              >
                                <Typography
                                  fontFamily={"Roboto"}
                                  // fontSize={16}
                                  fontWeight={200}
                                >
                                  {ck.title}
                                </Typography>
                              </MenuItem>
                            </NavLink>
                          ))}
                      </Menu>
                    </div>
                  );
                })}
              </ul>
            ) : (
              <ul
                className="menus"
                style={
                  props.overlay
                    ? { flexDirection: "column", alignItems: "flex-start" }
                    : {}
                }
              >
                {props.data.map((k, i) => {
                  return (
                    <Accordion
                      key={`mid-header-${i}`}
                      sx={{
                        width: "100%",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "white",
                      }}
                      expanded={expanded === `panel${i}`}
                      onChange={handleChange(`panel${i}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                        aria-controls={`panel${i}bh-content`}
                        id={`panel${i}bh-header`}
                      >
                        <Typography sx={{ flex: 1 }}>{k.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {k.children
                          .sort((a, b) => a.title < b.title)
                          .map((ck, ci) => (
                            <NavLink
                              key={`mid-header-${i}-${ci}`}
                              style={{ textDecoration: "none" }}
                              exact="true"
                              to={{
                                pathname: `/views/${getCurrentPath()[2]
                                  }/${k.title.toLowerCase()}/${removeHTML(ck)}`,
                                search: `?main=${i}&mu=${ci}`,
                              }}
                            >
                              <MenuItem
                                style={{ paddingLeft: 25, paddingRight: 25 }}
                                onClick={() => handleItemsClick(i, ci, ck.title)}
                              >
                                {ck.title}
                              </MenuItem>
                            </NavLink>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </ul>
            )}
          </nav>
        </>
      }

      <div className={isMobile ? "mobGutter" : "homeGutter"} />
    </div>
  );
};

export default Navbar;
