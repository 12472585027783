// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_a8A5yxsYg5H99YlqLARFTbcB8ZUrI_w",
    authDomain: "myschool-fcm.firebaseapp.com",
    projectId: "myschool-fcm",
    storageBucket: "myschool-fcm.appspot.com",
    messagingSenderId: "698861875818",
    appId: "1:698861875818:web:fce5e988dd251d230b1013",
    measurementId: "G-QJ00D7BEK5"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BD3JvgnhabS3re-F_6uT13y8URaUv6QZnPsQfLgvfNIz6znle2DmfuX-_O-nNb42gZpuBUAYnln-IQeMV5jQED8' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});