import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SearchImages = createAsyncThunk(
    'search/global',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/search/global',
                method: "GET",
                headers: {"Content-Type": "application/json"},
                params : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const fetchSearchSlice = createSlice({
    name: "searchedImage",
    initialState: {
        searchedImages: [],
        hasMore: false,
        pagination : 0,
        msg: null,
        loading: false,
    },

    reducers: {},

    extraReducers: {
        [SearchImages.fulfilled]: (state, { payload }) => {
            state.images = payload
            state.searchedImages = payload.data
            state.pagination = Math.ceil(payload.total/payload.length)
            state.hasMore = payload.hasMore
            state.loading = false;
        },
        [SearchImages.pending]: (state) => {
            state.loading = true;
        },
        [SearchImages.rejected]: (state, { payload }) => {
            // state.msg = payload
            state.loading = false;
        },      
    }
})

export default fetchSearchSlice.reducer;