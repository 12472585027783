import React from "react";
import Home from "../components/homeScreen/Home";
import {
  BrowserRouter as Router,
  Routes as Nav,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import AuthViews from "../components/auth/views/Index";
import ImageScreen from "../components/auth/views/imageScreen/ImageScreen";
import Student from "../components/auth/views/student/Student";
import School from "../components/auth/views/school/School";
import ScreenInitializer from "../components/screens/Index";
// Pre login screens
import Academics from "../components/screens/academics/Academics";
import EarlyCarrier from "../components/screens/earlycarrier/EarlyCarriers";
import InfoHub from "../components/screens/infohub/InfoHub";
import Publishing from "../components/screens/publishing/Publishing";
import Edutainment from "../components/screens/edutainment/Edutainment";

// Post login components
import Teacher from "../components/auth/views/teacher/Teacher";
import Profile from "../components/auth/views/profile/Profile";
import { useSelector } from "react-redux";
import Results from "../components/screens/results/Results";
import Faqs from "../components/auth/views/faqs/FAQS";
import AddNewSchool from "../components/auth/views/school/addNewSchool/AddNewSchool";
import AddNewStudent from "../components/auth/views/student/addNewStudent/AddNewStudent";
import AddNewTeacher from "../components/auth/views/teacher/addNewTeacher/AddNewTeacher";
import Subscription from "../components/auth/views/subscription/Subscription";
import Help from "../components/auth/views/help/Help";
import ChartMaker from "../components/makers/chartMaker/chartMaker";
import Makers from "../components/makers/makers";
import Unauthorize from "../components/screens/unauthorize/Unauthorize";
import Sections from "../components/screens/sections/Sections";
import Privacy from '../components/footerComponents/privacy/privacy'

// import devTools from '';

import Launch from "../Launch";
import Analytics from "../components/auth/views/analytics/analytics";
import Maker from "../components/screens/maker/Maker";
import CalendarView from "../components/auth/views/calendarView/CalendarView";
import Authorize from "../components/screens/authorize/Authorize";


const Routes = () => {
  const { isLoggedin } = useSelector((state) => state.login);


  return (
    <Router>
      <Nav>
        <Route path="/launch" element={<Launch />} />
        <Route path="/" element={<Home />} />
        {/* </Route> */}
        <Route path="/views" element={<ScreenInitializer />}>
          <Route path="/views/academic" element={<Academics />}>
            <Route path="/views/academic/:navbarMenuItem/:navbarMenuSubItem" element={<Academics />} />
            <Route path="/views/academic/:navbarMenuItem" element={<Academics />} />
          </Route>
          <Route path="/views/privacy" element={<Privacy />} />
          <Route path="/views/print-rich/makers" element={<Makers />}>
            <Route path="/views/print-rich/makers" index element={<Makers />} />
            <Route path="/views/print-rich/makers/:navbarMenuItem" element={<ChartMaker />} />
            <Route path="/views/print-rich/makers/micro-scheduler" element={<CalendarView />} />
          </Route>
          <Route path="/views/academic/makers" element={<Makers />}>
            <Route path="/views/academic/makers" index element={<Makers />} />
            <Route path="/views/academic/makers/:searchQuery" element={<ChartMaker />} />
            <Route path="/views/academic/makers/micro-scheduler" element={<CalendarView />} />
          </Route>
           <Route path="/views/early-career/makers" element={<Makers />}>
            <Route path="/views/early-career/makers" index element={<Makers />} />
            <Route path="/views/early-career/makers/:navbarMenuItem" element={<ChartMaker />} />
            <Route path="/views/early-career/makers/micro-scheduler" element={<CalendarView />} />
          </Route>
           <Route path="/views/edutainment/makers" element={<Makers />}>
            <Route path="/views/edutainment/makers" index element={<Makers />} />
            <Route path="/views/edutainment/makers/:navbarMenuItem" element={<ChartMaker />} />
            <Route path="/views/edutainment/makers/micro-scheduler" element={<CalendarView />} />
          </Route>  
          <Route path="/views/early-career" element={<EarlyCarrier />}>
            <Route path="/views/early-career/:navbarMenuItem/:navbarMenuSubItem" element={<EarlyCarrier />} />
            <Route path="/views/early-career/:navbarMenuItem" element={<EarlyCarrier />} />
          </Route>
          <Route path="/views/edutainment" element={<Edutainment />}>
            <Route path="/views/edutainment/:navbarMenuItem/:navbarMenuSubItem" element={<Edutainment />} />
            <Route path="/views/edutainment/:navbarMenuItem" element={<Edutainment />} />
          </Route>
          <Route path="/views/print-rich" element={<Publishing />}>
            <Route path="/views/print-rich/:navbarMenuItem/:navbarMenuSubItem" element={<Publishing />} />
            <Route path="/views/print-rich/:navbarMenuItem" element={<Publishing />} />
          </Route>
          <Route path="/views/maker" element={<Maker />}></Route>
          <Route path="/views/maker/makers" element={<Makers />}>
            <Route path="/views/maker/makers" index element={<Makers />} />
            <Route path="/views/maker/makers/:navbarMenuItem" element={<ChartMaker />} />
            <Route path="/views/maker/makers/micro-scheduler" element={<CalendarView />} />
          </Route>
          <Route path="/views/info-hub" element={<InfoHub />}>
            <Route path="/views/info-hub/:navbarMenuItem/:navbarMenuSubItem" element={<InfoHub />} />
            <Route path="/views/info-hub/:navbarMenuItem" element={<InfoHub />} />
          </Route>
          <Route path="/views/result" element={<Results />}></Route>
          <Route path="/views/sections" element={<Sections />}>
            <Route path="/views/sections/:navbarMenuItem/:navbarMenuSubItem" element={<Sections />} />
            <Route path="/views/sections/:navbarMenuItem" element={<Sections />} />
          </Route>
        </Route>
        <Route path="/unauthorized" element={<Unauthorize />} />
        <Route path="/authorized" element={<Authorize />} />

        {/* After Login Routes */}


        {isLoggedin ? (
          <Route path="/auth" element={<AuthViews />}>
            <Route path="/auth" index element={<Profile />} />
            <Route path="/auth/image" element={<Navigate to={"/auth"} />} />
            <Route path="/auth/school" element={<School />} />
            <Route path="/auth/student" element={<Student />} />
            <Route path="/auth/teacher" element={<Teacher />} />
            <Route path="/auth/images" element={<ImageScreen />} />
            <Route path="/auth/subscription" element={<Subscription />} />
            <Route path="/auth/faq" element={<Faqs />} />
            <Route path="/auth/school/addnewschool" element={<AddNewSchool />} />
            <Route path="/auth/student/addnewstudent" element={<AddNewStudent />} />
            <Route path="/auth/teacher/addnewteacher" element={<AddNewTeacher />} />
            <Route path="/auth/subscription" element={<Subscription />} />
            <Route path="/auth/help" element={<Help />} />
            <Route path="/auth/analytics" element={<Analytics />} />
            {/* <Route path="/auth/calendar" element={<CalendarView />} /> */}
            {/* <Route path="/auth" element={<MyImages />} />
            <Route path="/auth" element={<MyImages />} />
            <Route path="/auth" element={<MyImages />} /> */}
          </Route>
        ) : (
          <Route path="/auth" element={<Navigate to="/" />} >
            <Route path="/auth/:internalRoutes" element={<Navigate to={"/auth"} />} />
          </Route>
        )}
      </Nav>
    </Router>
  );
};

export default Routes;
