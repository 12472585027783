import React from 'react'


const Edutainment = () => {
    return (
        <>
            Edutainment
        </>
    )
}

export default Edutainment