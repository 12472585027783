import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';

import { SectionTab } from 'polotno/side-panel';
import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';

import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MakersTemplate } from '../../../redux/makersTemplateSlice';
import { getImageSize } from 'polotno/utils/image';

import {ReactComponent as ImagesIcon} from './assests/images.svg'
import {ReactComponent as Search} from "../../../assests/search.svg";
import { InputAdornment, Pagination, Stack, TextField, Typography } from '@mui/material';
import { SearchImages } from '../../../redux/fetchSearchSlice';
import { isMobile } from 'react-device-detect';

export const ImagesPanel = observer(({ store }) => {

  // load data
  let location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const makerType = location.pathname.split("/")[4]

  const [images, setImages] = useState([])
  const [continuationToken, setContinuationToken] = useState(null)
  const [page, setPage] = React.useState(1);  
  const counter = React.useRef(0);
  const [data, setData] = useState([]);
  const [searchedImage, setSearchedImage] = useState([]);
  const [searchText, setSearchText] = useState("")

  const { searchedImages, loading } = useSelector(
    (state) => state.searchedImage
  );

  const handleSearchImage = (e) => {
    if (e.keyCode === 13) {
      console.log('search');
      navigate({ pathname: `/views/academic/makers/chart-maker`, search: `?text=${searchText}` });
      if (
        // location?.search.includes('?text=') && 
      location?.search.replace('?text=', "").toLowerCase() !== "") {
        let header = {
            "Content-Type": "application/json",
        }
  
        dispatch(SearchImages({
            headers: header,
            method: "GET",
            body: {
                query: location?.search.replace('?text=', "").toLowerCase()
            }
        })).then((res) => {
          console.log(res.payload);
          let newData = []
          let searchData = searchedImage
          Object.keys(res.payload).map((k,i) => {
            let name = (k.split('/'))
            name = name[name.length - 1].split('.')[0]
            if (name !== "" ) {
              newData.push({
                url: res.payload[k],
                preview: res.payload[k],
                name: name,
                // images:images
              })
            }

          })
          searchData.push(newData)
          console.log('searchData', searchData)
          console.log(searchData.length);
          setSearchedImage(searchData)
            // return
        })
        // console.log(data);
        return
    }
    }
    
  }

  useEffect(() => { 
    loadImage();
    console.log(location?.search);
  },[location?.search])

  React.useEffect(() => {
    let searchedText = location?.search.replace('?text=', "").toLowerCase()
    // alert(searchedText);
    if(searchedText.includes('?main=')) {
      setSearchText("")
    }
    else if (searchedText !== "") setSearchText(searchedText)
  }, [])

const loadImage = () => {
  let header = {
    "Content-Type": "application/json",
    "Cache-Control": 'no-cache'
  }

    dispatch(MakersTemplate({
      header: header,
      path: makerType,
      type: 'images',
      continuationToken: continuationToken
    })).then((res) => {
      // console.log(res.payload)
      let data = []
      setContinuationToken(res.payload.images.continuationToken)
      let imagesData = [...images]
      Object.keys(res.payload.images?.list).map((k, i) => {
        let name = (k.split('/'))
        name = name[name.length - 1].split('.')[0]
        if (name !== "" ) {
          data.push({
            url: res.payload.images.list[k],
            preview: res.payload.images.list[k],
            name: name,
            // images:images
          })
        }
       
      })
      imagesData.push(data)
      // console.log('imagesData', [imagesData])
      // console.log(imagesData.length);
      setImages(imagesData)
      
       
    })
  
  }

  const handlePageChange = (event, value) => {
    setPage(value)
    // console.log(value);
    loadImage();
  };

  const handleReturnBase64 = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        console.log((reader.result))
        return (reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  

  
  return (
    <>
    <div style={{ height: '92%' }}>
     <TextField style={{border:'1px solid',backgroundColor:'white',width:'322px'}}
        hiddenLabel
        id="filled-hidden-label-small"
  
        variant="filled"
        size="small"
        fullWidth
        backgroundColor="white"
        placeholder='Search...'
        height='10px'
        value={searchText}
            onKeyDown={handleSearchImage}
            onChange={(e) => setSearchText(e.target.value)}
        
        InputProps={{
            startAdornment: <InputAdornment position="start"><Search style={{color:'gray'}}/></InputAdornment>,
            height:'10px'
          }}
      
      />
      <div style={{height: isMobile ? '80%' : '90%',overflow:'auto',paddingTop:'10px'}}>
        {/* {console.log(searchedImage)}
        {console.log(searchedImage[1])} */}
        {(images.length !== 0 && images[page-1]) || (searchedImage.length !==0 && searchedImage[1] !== undefined) ? 
      <ImagesGrid
        shadowEnabled={false}
        // images={location?.search?.includes('?text=') ? 
        // searchedImage[1].map((data) => data).flat() : images[page-1].map((data) => data).flat()}
        images={images[page-1].map((data) => data).flat()}
        getPreview={(item) => item.preview}
        isLoading={!images.length}

        onSelect={async (image, pos, element) => {
          if (element && !element.locked) {
            if (element.type === 'image') {
              element.set({ src: image?.url });
            } else if (element.type === 'svg') {
              // console.log(handleReturnBase64(image?.url))
              // element.set({src : image?.url})
              element.set({
                type: 'image',
                src: image?.url,
                width: element.width,
                height: element.height,
                // if position is available, show image on dropped place
                // or just show it in the center
                x: element.x,
                y: element.y,
                alwaysOnTop : element.alwaysOnTop
              })
            }
            return;
          }
          const { width, height } = await getImageSize(image.url);
          store.activePage.addElement({
            type: 'image',
            src: image.url,
            width,
            height,
            // if position is available, show image on dropped place
            // or just show it in the center
            x: pos ? pos.x : store.width / 2 - width / 2,
            y: pos ? pos.y : store.height / 2 - height / 2,
          });
        }}

        // loadMore={continuationToken !== null}
        rowsNumber={2}
        
      /> : <Typography>Loading...</Typography> }
      </div>
    <div className="imageSearchbarPagignationContainer" style={{padding: '0px'}}>
            <Stack spacing={1}>
              <Pagination
                // count={Math.ceil(Object.keys(images).length /5)}
                // count={[...images].length}
                count={images[page] + 1}
                variant="outlined"
                size="large"
                shape="rounded"
                onChange={handlePageChange}
                defaultPage={1}
                sx={{
                  '& ul > li > button': {
                    margin: 0,
                    backgroundColor: 'white'
                  },
                  '& ul > li:not(:first-child):not(:last-child) > button': {
                    borderRadius: 0
                  },
                  '& ul > li:first-child > button': {
                    borderRadius: "8px 0px 0px 8px"
                  },
                  '& ul > li:last-child > button': {
                    borderRadius: "0px 8px 8px 0px"
                  },
                }}
                // hidePrevButton={true}
                hideNextButton={continuationToken !== null ? false : true}
              />
            </Stack>
          </div>
    </div>
   
          </>
  );
});

// define the new custom section
export const ImagesSection = {
  name: 'custom-images',
  Tab: (props) => (
    <SectionTab name="Images" {...props}>
      <ImagesIcon />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: ImagesPanel,
};
