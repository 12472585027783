import React from 'react';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from '../../uicomponent/filter/Arrow';
import useDrag from '../../customTheme/signUpMenu/useDrag';
import "../../customTheme/signUpMenu/hideScrollBar.css";
import "./filter.css";
import { structureFilterConst } from './constant/constant';
import { useLocation } from "react-router-dom";

const StructureFilter = (props) => {
    let location = useLocation()
    const [wholeStructList, setWholeStructList] = React.useState([])
    const [structureList, setStructureList] = React.useState([])
    const [selectedCards, setSelectedCards] = React.useState([])


    React.useEffect(() => {
        let path = location.pathname.split('/').filter(ele => ele).filter((ele) => ele !== "views").filter((ele) => ele !== "academic" && ele !== "sections")[0]
        if (structureFilterConst[path]) {
            setStructureList(structureFilterConst[path])
            setWholeStructList(structureFilterConst[path])
        }
    }, [])

    const { dragStart, dragStop, dragMove, dragging } = useDrag();

    const handleDrag = ({ scrollContainer }) => (e) => dragMove(e, (posDiff) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
        }
    });

    const handleItemClick = (data, ItemIndex) => {
        if (dragging) {
            return false;
        }
        // let selectedItem = selectedCards
        let path = location.pathname.split('/').filter(ele => ele).filter((ele) => ele !== "views").filter((ele) => ele !== "academic" && ele !== "sections")[0]

        if (selectedCards.length === 0) {
            let temp_selectedCards = [{ index: [ItemIndex], title: data.title, children: data.children }]
            setSelectedCards(temp_selectedCards)
            let newData = [[...structureList][ItemIndex]]
            setStructureList([...newData, ...data.children])
            path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}/${data.title}`
            props.loadImages(path)
        }
        else {
            if (ItemIndex >= selectedCards.length) {
                if (data.children) {
                    let temp_selectedCards = [...selectedCards, { index: [ItemIndex], title: data.title, children: data.children }]
                    setSelectedCards(temp_selectedCards)
                    let newData = []
                    selectedCards.map((k, i) => 
                        newData.push({ title: k.title, children: k.children })
                    )
                    setStructureList([...newData, ...data.children])
                    path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                    temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                    props.loadImages(path)
                } else {
                    let temp_selectedCards = [...selectedCards]
                    if (temp_selectedCards[temp_selectedCards.length - 1].title === data.title) {
                        temp_selectedCards.splice(temp_selectedCards.length - 1, 2)
                        setSelectedCards(temp_selectedCards)
                        path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                        temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                        props.loadImages(path)
                        return
                    }
                    if (temp_selectedCards[selectedCards.length - 1].children) {
                        temp_selectedCards[selectedCards.length] = { index: [ItemIndex], title: data.title, children: data.children }
                        setSelectedCards(temp_selectedCards)
                        path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                        temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                        props.loadImages(path)
                        return
                    }
                    temp_selectedCards[selectedCards.length - 1] = { index: [ItemIndex], title: data.title, children: data.children }
                    setSelectedCards(temp_selectedCards)
                    path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                    temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                    props.loadImages(path)
                }
                return
            } else if (ItemIndex > 0) {
                let temp_selectedCards = [...selectedCards]
                if (temp_selectedCards[ItemIndex].children) {
                    temp_selectedCards.splice(ItemIndex, selectedCards.length)
                    setSelectedCards(temp_selectedCards)
                    let newData = []
                    temp_selectedCards.map((k, i) =>
                        newData.push({ title: k.title, children: k?.children })
                    )
                    setStructureList([...newData, ...newData[temp_selectedCards.length - 1]?.children])
                    path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                    temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                    props.loadImages(path)
                } else {
                    if (temp_selectedCards[temp_selectedCards.length - 1].title === data.title) {
                        temp_selectedCards.splice(temp_selectedCards.length - 1, 2)
                        setSelectedCards(temp_selectedCards)
                        path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                        temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                        props.loadImages(path)
                        return
                    }
                    temp_selectedCards[selectedCards.length - 1] = { index: [ItemIndex], title: data.title, children: data.children }
                    setSelectedCards(temp_selectedCards)
                    path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
                    temp_selectedCards.map((k, i) => path = `${path}/${k.title}`)
                    props.loadImages(path)
                }
                return
            }
            setSelectedCards([])
            setStructureList(wholeStructList)
            path = `SECTIONS DATA/thumbnails/${path.toUpperCase()}`
            props.loadImages(path)
        }

    }

    const handleSelected = (data) => {
        let selected = false
        selectedCards.map((k, i) => k.title === data.title ? selected = true : null)
        return selected
    }



    return (
        <div className="structureFilterContainer">
            {structureList.length !== 0 ? <div className="structureFilterSelector">
                <ScrollMenu
                    onMouseDown={() => dragStart}
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    onMouseUp={() => dragStop}
                    onMouseMove={handleDrag}
                    style={{ width: '0px !important' }}>
                    {structureList.map((k, i) => (
                        <StructureFilterCard
                            itemId={k.title} // NOTE: itemId is required for track items
                            title={k.title}
                            key={k.title}
                            onClick={() => handleItemClick(k, i)}
                            selected={handleSelected(k)}
                        />
                    ))}
                </ScrollMenu>
            </div> : null }
        </div>
    )
}

function StructureFilterCard({ onClick, selected, title }) {
    const visibility = React.useContext(VisibilityContext);
    return (
        <div onClick={() => onClick(visibility)} style={{ width: 'max-content' }} tabIndex={0}>
            <p className={selected ? "structureFilterItemActive" : "structureFilterItem"} unselectable="on">{title}</p>
        </div>
    )
}

export default StructureFilter