import { Avatar, Button, Card, Link, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import blacksetting from "../../../../assests/auth/blacksetting.svg";
import profileone from "../../../../assests/auth/profileone.svg";

import "./Profile.css";
import "../student/addNewStudent/AddNewStudent.css";
import MSTextField from "../../../../customTheme/textField/MSTextField";
import Btn from "../../../../customTheme/button/Button";
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MyProfile, UpdateProfile } from "../../../../redux/myProfileSlice";
import { RefreshToken } from "../../../../redux/authSlice";

import { useSnackbar } from "../../../../hook/useSnackbar";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.myProfile)
  const { refreshToken, accessToken, tokenExpiry, userRole, appType } = useSelector((state) => state.login);
  const [userData, setUserData] = React.useState({})
  const [newPass, setNewPass] = React.useState("")
  const [confirmPass, setConfirmPass] = React.useState("")

  const { displaySnackbar } = useSnackbar();

  useEffect(() => {
    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let data = {}
    dispatch(MyProfile({
      headers: header,
      method: "GET",
      body: data
    })).then((res) => {
      if (res.payload.message === "Expired JWT") {
        dispatch(RefreshToken({
          headers: header,
          body: {
            "refreshToken": refreshToken
          }
        })).then((res) => {
          header["Authorization"] = `Bearer ${res.payload.accessToken}`
          dispatch(MyProfile({
            headers: header,
            method: "GET",
            body: data
          })).then((res) => {
            setUserData(res.payload)
          })
        })
      } else {
        setUserData(res.payload)
      }
    })
  }, [])

  const handleFieldsChange = (e, fieldName) => {
    e.preventDefault()

    setUserData(current => {
      const copy = { ...current }
      copy[fieldName] = e.target.value
      return copy
    })
  }

  const handleUpdateProfile = () => {
    let header = {
      "Content-Type": "application/json",
      // 'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let timestamp = Math.ceil(Date.now() / 1000)

    if (tokenExpiry < timestamp) {
      dispatch(RefreshToken({
        headers: header,
        body: {
          "refreshToken": refreshToken
        }
      }))
    }

    dispatch(UpdateProfile({
      headers: header,
      body: userData
    })).then((res) => {

      displaySnackbar({ message: "User Details Updated" })

      // setUserData(res.payload)
    })
  }

  const handlePasswordUpdate = () => {
    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let timestamp = Math.ceil(Date.now() / 1000)
    let data = {
      username: userData.email,
      newPassword: newPass,
    }

    if (tokenExpiry < timestamp) {
      dispatch(RefreshToken({
        headers: header,
        body: {
          "refreshToken": refreshToken
        }
      }))
    }

    dispatch(UpdateProfile({
      headers: header,
      body: data
    })).then((res) => {
      console.log(res)
      // setUserData(res.payload)
    })
  }

  return (
    <div className="profileManinContainer">
      {/* <MobileView>
        <MobileHeader />
      </MobileView> */}
      <div className="homeGutter" style={{ maxWidth: 10 }} />
      <div className="myProfileContainer">
        <div className="profileWrapperContainer">
          <div className="profileHeaderTxtContainer">
            <img alt="" src={blacksetting} />
            <Typography fontSize="15px" fontWeight="bold" className="profileText">
              My Profile{" "}
            </Typography>
          </div>
          <div className="addStudentHeaderBtnContainer">
            <MobileView>
              <Btn />
            </MobileView>
            <BrowserView>
              <Button variant="outlined" className="cancelBtn" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </BrowserView>
            <Button
              style={{ backgroundColor: "#B3DAFF" }}
              className="saveDataBtn"
              onClick={handleUpdateProfile}
            >
              SaveData
            </Button>
          </div>
        </div>

        {userRole === "SCHOOL" ?
          <div className="schoolCode">School Code - {userDetails?.schoolCode} </div> : null}

        {userRole === "TEACHER" ?
          <>
            <div className="schoolCode">School Code - {userDetails?.schoolCode} </div>
            <div className="teacherCode">Teacher Code - {userDetails?.teacherCode}</div>
          </> : null}


          {userDetails?.credits ? <Card
            elevation={0}
            className="AddFormContainer"
            style={{ minHeight: 85, maxWidth:300, justifyContent:'center', display:'flex', alignItems:"center", gap:20 }}
          >
            <Typography fontWeight="600" fontSize={20}>Available Credits:</Typography>
            <Typography fontSize={28}>{userDetails?.credits}</Typography>
          </Card> : null}
        

        <div className="profileCardContainer">
          <Card
            elevation={0}
            className="profileStackContainer"
            sx={{ borderRadius: "15px" }}
            style={{ minHeight: "85px" }}
          >
            <Stack direction="row" spacing={2} paddingLeft="15px">
              <Avatar alt="Remy Sharp" src={profileone} />
            </Stack>

            <Link
              color="primary"
              href="/"
              style={{ fontSize: "16px !important", color: "#1F66C4" }}
            >
              Change Photo
            </Link>
          </Card>
        </div>
        <form className="myProfileContainer">
          <Paper
            className="AddFormContainer"
            sx={{ backgroundColor: "white", borderRadius: "15px" }}
          >
            <div className="profileTextfieldContainer">
              <div className="profileTextFieldWrapperContainer">
                <MSTextField id="myProfileUsername" type="text" onChange={handleFieldsChange} value={userData.name} fieldName="name"
                  placeholder="Enter school name" label={userRole === "SCHOOL" ? "* School Name" : "* Full Name"} />
                <MSTextField id="myProfileMobileNumber" type="text" onChange={handleFieldsChange} value={userData.mobileNumber} fieldName="mobileNumber"
                  placeholder="Enter mobile number" label="*Mobile Number " disabled={true} />
                <MSTextField id="myProfileEmailId" type="text" value={userData.email} fieldName="email"
                  label="Email ID" disabled={true} />
                {userRole === "STUDENT" ?
                  <MSTextField id="myProfileParent" type="text" value={userData.fatherName}
                    label="Parent Name" disabled={true} />
                  : null}
              </div>
              <div className="profileTextFieldWrapperContainer profileAdmin" style={{
                display: userRole === "ADMIN" ? 'none' : 'flex'
              }}>
                {userRole === "STUDENT" ?
                  <>
                    <MSTextField id="myProfileClass" type="text" value={userData.className}
                      label="Class Name" disabled={true} />
                    <MSTextField id="myProfileSection" type="text" value={userData.sectionName}
                      label="Section" disabled={true} />
                  </>
                  : null}

                {userRole !== "ADMIN" ?
                  <MSTextField id="myProfileSchoolCode" type="text" value={userData.schoolCode}
                    label="School Code" disabled={true} />
                  : null}
                {userRole === "SCHOOL" ?
                  <MSTextField id="myProfilePrincipalName" type="text" value={userData.principalName}
                    label="Principal Name" disabled={true} />
                  : (userRole === "TEACHER" || "STUDENT") && (userRole !== "ADMIN") ?
                    <MSTextField id="myProfileTeacherCode" type="text" value={userData.teacherCode}
                      label="Teacher Code" disabled={true} />
                    : null}
              </div>
              <div className="profileTextFieldWrapperContainer">
                <MSTextField id="myProfileAddress" type="text" onChange={handleFieldsChange} value={userData.address} fieldName="address"
                  placeholder="Enter complete address here" label="*Address" />
                <MSTextField id="myProfileCity" type="text" onChange={handleFieldsChange} value={userData.city} fieldName="city"
                  placeholder="Enter city name" label="City" />
                <MSTextField id="myProfileState" type="text" onChange={handleFieldsChange} value={userData.state} fieldName="state"
                  placeholder="Enter state" label="*State" />
                <MSTextField id="myProfilePincode" type="text" value={userData.postalCode} label="Postal Code"
                  disabled={true} />
              </div>
            </div>
          </Paper>

          <br />
          {/* <Paper elevation={0} sx={{ borderRadius: "15px" }}>
            <div className="profilechangePasswordLinkContainer">
              <div className="profileLinkContainer">
                <MSTextField id="myProfilePassword" type="password" placeholder="Enter new password" label="*New Password" onChange={(e) => setNewPass(e.target.value)} />
                <MSTextField id="myProfileConfirmPassword" type="password" placeholder="Enter new confirm password" label="*Confirm Password" />

                <div className="profilePwd" style={{ display: 'flex', paddingTop: '2%' }}>
                  <Button
                    style={{ backgroundColor: "#B3DAFF", minWidth: 'unset', maxWidth: 'unset' }}
                    className="saveDataBtn"
                    onClick={handlePasswordUpdate}
                  >
                    Change Password
                  </Button>
                </div>


              </div>
            </div>
          </Paper> */}
        </form>
      </div>

      <div className="homeGutter" style={{ maxWidth: 10 }} />
    </div>
  );
};
export default Profile;
