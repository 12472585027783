import * as React from "react";
import StudentsIcon from "../../../../assests/auth/studentsIcon.svg";
import { ReactComponent as AddIcon } from "../../../../assests/AddIcon.svg";
import { ReactComponent as ImportExcelIcon } from "../../../../assests/ImportExcelIcon.svg";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import AuthSearchField from "../../../../customTheme/authSearchField/AuthSearchField";
import { DataGrid } from "@mui/x-data-grid";
import { columns, data, StudentmobileViewColumns } from "./constant";
import { useNavigate } from "react-router-dom";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { ListUsers } from "../../../../redux/fetchUsersSlice";
import { RefreshToken } from "../../../../redux/authSlice";
import AddNewStudent from "./addNewStudent/AddNewStudent";
import { mobileViewColumns } from "../school/constants";

export default function StudentRecordTable() {
  const navigate = useNavigate();

  const [searchText, setSearchText] = React.useState();

  const [addNewStudent, setAddNewStudent] = React.useState(false);

  const dispatch = useDispatch();
  const { hasMore, usersList, loading } = useSelector(
    (state) => state.usersList
  );
  const { accessToken, refreshToken, tokenExpiry } = useSelector(
    (state) => state.login
  );

  const handleAddNewStudent = () => {
    setAddNewStudent(true);
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    // console.log(e.target.value)    
  }

  const handleSearch = () => {
    // console.log(searchText)

    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    let data = {
      role: "STUDENT",
      limit: 100,
      schoolCode: searchText
    }

    let timestamp = Math.ceil(Date.now() / 1000)

    if (tokenExpiry < timestamp) {
      dispatch(RefreshToken({
        headers: header,
        body: {
          "refreshToken": refreshToken
        }
      }))
    }

    dispatch(ListUsers({
      url: "/rest/users/listUsersByRole",
      headers: header,
      method: "GET",
      body: data
    }))
  }

  React.useEffect(() => handleFetchData(), []);

  const handleFetchData = () => {
    let header = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    let data = {
      role: "STUDENT",
      limit: 100,
    };

    let timestamp = Math.ceil(Date.now() / 1000);

    if (tokenExpiry < timestamp) {
      dispatch(
        RefreshToken({
          headers: header,
          body: {
            refreshToken: refreshToken,
          },
        })
      );
    }

    dispatch(
      ListUsers({
        url: "/rest/users/listUsersByRole",
        headers: header,
        method: "GET",
        body: data,
      })
    );
  }

  if (addNewStudent) {
    return (
      <AddNewStudent
        handleCancel={() => {
          setAddNewStudent(false);
        }}
      />
    );
  }

  return (
    <div className="schoolContainer">
      <div className="typoContainer">
        <img alt="" src={StudentsIcon} />
        <Typography
          fontSize="20px"
          fontWeight={400}
          fontFamily={"Proxima Nova"}
        >
          Students
        </Typography>
      </div>

      <div className="schoolBtnContainer">
        <div className="imgBtnContainer">
          <AuthSearchField
            id="signUpEmail"
            type="email"
            placeholder="Search by School ID, School Name, School City here"
            onChange={handleSearchText}
          />
          <Button variant="outlined" className="authSearchbtn" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <MobileView>
          <div className='schoolRadioButtonCaontainer' style={{ display: 'flex', flexDirection: 'column', flex: '1', gap: '1vw', paddingTop: 10 }}>
            <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} marginTop='3px'>
              Search By
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControl >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="studentcode"
                  name="radio-buttons-group"
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}

                >
                  <FormControlLabel value="studentcode" control={<Radio />} label="Student Code" />
                  <FormControlLabel value="studentname" control={<Radio />} label="Student Name" />

                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </MobileView>
        <div className="btnCombo">
          <Button
            variant="outline"
            className="blueBtnBg"
            startIcon={<AddIcon />}
            onClick={handleAddNewStudent}
          >
            Add New Student
          </Button>
          <Button
            variant="outline"
            className="blueBtnBg"
            startIcon={<ImportExcelIcon />}
          >
            Import Excel File
          </Button>
        </div>
      </div>
      <BrowserView>
        <div className='schoolRadioButtonCaontainer' style={{ display: 'flex', flexDirection: 'row', flex: '1', gap: '1vw' }}>
          <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"} marginTop='3px'>
            Search By
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl >
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="studentcode"
                name="radio-buttons-group"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}

              >
                <FormControlLabel value="studentcode" control={<Radio />} label="Student Code" />
                <FormControlLabel value="studentname" control={<Radio />} label="Student Name" />

              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </BrowserView>
      
      <DataGrid
        getRowId={(row) => row.userId}
        columns={isMobile ? StudentmobileViewColumns : columns}
        rows={usersList.map((item) => {return {...item, "handleFetchUserData": handleFetchData}})}
        rowHeight={isMobile ? 170 : 40}
        headerHeight={isMobile ? 70 : 56}
        style={{ backgroundColor: "white", minHeight: "80vh" }}
        scrollbarSize={isMobile ? 1 : 5}
        sx={{
          "& .MuiDataGrid-columnHeadersInner": {
            background: "#B3DAFF",
            fontFamily: "Proxima Nova",
            fontWeight: "bold",
            fontSize: 15,
            fontWeight: 600,
          },

          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
        getRowClassName={() => "textFontProxima"}
      // rowsPerPageOptions={[20]}
      />
    </div>
  );
}
