export const languageOptions = [
    {
        name: "Assamese",
        url:"/"
    },
    {
        name: "Bengali",
        url:"/"
    },
    {
        name: "Dogri",
        url:"/"
    },
    {
        name: "English",
        url:"/"
    },
    // {
    //     name: "Dhongre",
    //     url:"/"
    // },
    {
        name: "Gujarati",
        url:"/"
    },
    {
        name: "Hindi",
        url:"/"
    },
    {
        name: "Kannada",
        url:"/"
    },
    {
        name: "Kashmiri",
        url:"/"
    },
    {
        name: "Konkani",
        url:"/"
    },
    {
        name: "Malayalam",
        url:"/"
    },
    {
        name: "Manipuri",
        url:"/"
    },
    {
        name: "Marathi",
        url:"/"
    },
    {
        name: "Maithili",
        url:"/"
    },
    {
        name: "Nepali",
        url:"/"
    },
    {
        name: "Odia",
        url:"/"
    },
    {
        name: "Punjabi ",
        url:"/"
    },
    {
        name: "Sanskrit ",
        url:"/"
    },
    {
        name: "Santali ",
        url:"/"
    },
    {
        name: "Sindhi",
        url:"/"
    },
    {
        name: "Tamil",
        url:"/"
    },
    {
        name: "Telugu",
        url:"/"
    },
    {
        name: "Urdu",
        url:"/"
    },
]