import * as React from "react";
import { Button, MenuItem, Select, Typography, Card } from "@mui/material";
import home from "../../../../assests/homeScreen/home.png";
import './analytics.css'

import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { userRoles } from './constants'
import Chart from "react-apexcharts";
import { isMobile } from "react-device-detect";

const Analytics = () => {
    const [selectionRange, setSelectionRange] = React.useState({
        startDate: new Date(),
        endDate: new Date(),
    })
    const bigCardBarOptions =
    {
        chart: {
            id: 'imageUploadsC',
            toolbar: {
                show: false
            },
            sparkline: { enabled: true }

        },
        legend: {
            show: false
        },
        grid: {
            show: false
        },
        xaxis: {
            labels: {
                show: false
            }
        },
        yaxis: {
            labels: { show: false }
        },
        tooltip: {
            x: { show: false },
            y: { show: false }
        }
    }

    const bigCardLineOptions =
    {
        chart: {
            id: 'imageUploadsCl',
            sparkline: { enabled: true }

        },
        grid : {
            show : true
        },
        legend: {
            show: true
        },
        xaxis: {
            
            categories: ['21 Dec', '22 Dec', '23 Dec', '24 Dec', '25 Dec', '26 Dec'],
            show : true,
            labels: {
                style: {
                  fontSize: '12px',  // decrease the font size of the labels
                },
              },
        },
        yaxis : {show : true, labels : {
            style : {padding : '20px'}
        }}
    }

    const [role, setRole] = React.useState('ADMIN')

    // React.useEffect(() => handleSetToday(), [])

    const handleSetToday = () => {
        let data = {}
        data.startDate = (new Date()).setHours(0, 0, 0, 0) / 1000
        let endDate = (new Date())
        endDate.setDate(endDate.getDate() + 1)
        data.endDate = endDate.setHours(23, 59, 59, 0) / 1000
        setSelectionRange(data)
    }

    return (
        <div className="analyticsContainer">
            <div className="typoContainer">
                <img alt="" src={home} />
                <Typography fontSize="20px" fontWeight={400} fontFamily={"Proxima Nova"}>
                    Analytics
                </Typography>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexWrap: "wrap", gap: 30, }}>
                    <div style={{ flex: 1, minWidth: 300 }}>
                        <Typography>Select Date Range</Typography>
                        <RangeDatePicker
                            startDate={selectionRange.startDate}
                            endDate={selectionRange.endDate}
                            onChange={(startDate, endDate) => {
                                let data = {
                                    startDate: startDate,
                                    endDate: endDate
                                }
                                setSelectionRange(data)
                            }}
                            minDate={new Date(2000, 0, 1)}
                            maxDate={new Date(2100, 0, 1)}
                            dateFormat="DD MMM YYYY"
                            monthFormat="MMM YYYY"
                            startDatePlaceholder="Start Date"
                            endDatePlaceholder="End Date"
                            disabled={false}
                            startWeekDay="monday"
                            className="my-own-class-name"
                        />
                    </div>
                    <div style={{ display: 'flex', flex: 1, height: 48, flexDirection: 'column' }}>
                        <Typography>Select User Role</Typography>
                        <Select
                            defaultValue={role}
                            style={{ flex: 1 }}
                            value={role}
                            size={'small'}
                            onChange={(e) => setRole(e.target.value)}>
                            {userRoles.map((k, i) => <MenuItem value={k.value}>{k.label}</MenuItem>)}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, flex: 1 }}>
                        <Button variant="contained"
                            color="secondary"
                            style={{ flex: 1, maxHeight: 48, maxWidth: 300 }}
                            endIcon={<ArrowRightAltIcon />}>Submit</Button>
                    </div>
                </div>
            </div> */}
            <Card style={{ flex: 1, padding: 25, display: 'flex', gap: 30, flexDirection: 'column' }}>
                <div style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}>
                    <Card
                        className="analyticsBigCards"
                        style={{ height: 80 }}
                        variant="elevation">
                        <div>
                            <Typography fontSize={"0.875rem"} fontWeight={600}>Image Uploads</Typography>
                            <Typography fontSize={"2rem"}>2</Typography>
                        </div>
                        <div>
                            <Chart
                                options={bigCardBarOptions}
                                type="bar"
                                width={80}
                                series={[
                                    {
                                        name: '',
                                        data: [5, 4, 10, 8, 9, 11, 5]
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                    <Card
                        className="analyticsBigCards"
                        style={{ height: 80 }}
                        variant="elevation">
                        <div>
                            <Typography fontSize={"0.875rem"} fontWeight={600}>Image Downloads</Typography>
                            <Typography fontSize={"2rem"}>5</Typography>
                        </div>
                        <div>
                            <Chart
                                options={bigCardBarOptions}
                                type="bar"
                                width={80}
                                series={[
                                    {
                                        name: '',
                                        data: [5, 4, 10, 8, 9, 11, 5]
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                    <Card
                        className="analyticsBigCards"
                        style={{ height: 80 }}
                        variant="elevation">
                        <div>
                            <Typography fontSize={"0.875rem"} fontWeight={600}>New Users</Typography>
                            <Typography fontSize={"2rem"}>5</Typography>
                        </div>
                        <div>
                            <Chart
                                options={bigCardBarOptions}
                                type="bar"
                                width={80}
                                series={[
                                    {
                                        name: '',
                                        data: [5, 4, 10, 8, 9, 11, 5]
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                    <Card
                        className="analyticsBigCards"
                        style={{ height: 80 }}
                        variant="elevation">
                        <div>
                            <Typography fontSize={"0.875rem"} fontWeight={600}>Revenue</Typography>
                            <Typography fontSize={"2rem"}>₹ 500</Typography>
                        </div>
                        <div>
                            <Chart
                                options={Object.assign({}, bigCardBarOptions, { fill: { colors: 'rgba(0, 171, 85, 0.85)' } })}
                                type="bar"
                                width={80}
                                series={[
                                    {
                                        name: '',
                                        data: [5, 4, 10, 8, 9, 11, 5]
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', gap: 30, flexWrap: "wrap" }}>
                    <Card
                        className="analyticsBigCards"
                        style={{
                            display: 'flex', alignItems:'baseline',
                            flexDirection:'column', gap:30
                        }}
                        variant="elevation">
                        <Typography style={{textAlign:'left'}}>Total Users</Typography>
                        <Chart
                            options={Object.assign({}, bigCardBarOptions, {
                                labels: ["Admin", "School", "Teacher", "Parent", "Student", "Publisher"],
                                dataLabels: { enabled: false }, legend: { show: true, position: 'top' }, plotOptions: { pie: { donut: { size: '80%' } } }
                            })}
                            type="donut"
                            width={isMobile ? '100%' : 350}
                            series={[3, 40, 10, 20, 15, 2]}
                        />
                    </Card>
                    <Card
                        className="analyticsBigCards"
                        style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                            flexDirection:'column', gap:30
                        }}
                        variant="elevation">
                            <Typography>Users</Typography>
                        <Chart
                            type="line"
                            // width={350}
                            // height={250}
                            options={bigCardLineOptions}
                            series={[{
                                name: "New Users",
                                data: [3, 16, 10, 20, 15, 2],
                                curve : "smooth"
                            }]}
                        />
                    </Card>
                    {/* <Card
                        className="analyticsBigCards"
                        style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}
                        variant="elevation">
                        <Chart
                            type="line"
                            width={350}
                            options={bigCardBarOptions}
                            series={[{
                                name: "New Users",
                                data: [3, 16, 10, 20, 15, 2]
                            }]}
                        />
                    </Card> */}

                </div>
            </Card>

        </div>
    )
}

export default Analytics