export const Data = [
    { Question: '1. Is this really workable for students ?', Answer: 'Yes! They can read, photo copy, print, project and listen' },
    { Question: '2. Where teachers can apply this ?', Answer: 'For a trainee, it’s a project material; for teacher,  it’s a tool ' },
    { Question: '3. How this helpful to schools ?', Answer: 'they can make digital curriculum also can publish Books' },
    { Question: '4. How this is different from Google ?', Answer: 'Get from a single source, instead of many sites in  Google' },
    { Question: '5. How original is the content ?', Answer: 'It’s a proprietary content skilfully developed since 2000' },
    { Question: '6. Do they pay every time they use ?', Answer: 'Annually from school, with membership or pay-per- use' },
    { Question: '7. How this platform is unique that no one else has ?', Answer: 'India - centric  print- ready Half a million original  image bank   Hundreds of comics for value education & for language learning  Science projects and art, craft lesson plans in  animation Unlimited printable activity work sheets Schools can make their own digital curriculum on  our free platform  Complete pre- primary school academic content Curriculum creator (A digital curriculum presenter software for classroom) Rs.50,000 Mediclaim insured for every student on   annual membership.' },
    { Question: '8. What are the other providers have that you don’t have ?', Answer: 'Pre-designed lesson plans for white board teaching Teacher training support Teaching gadgets' },
    { Question: '9. What are the key features of this website ?', Answer: 'First K-12 search engine for Indian education Lowest service fee in the world Password protection for every student first company providing Rs.50,000 Mediclaim for every student on membership School can be a education publishing hub with our print ready books One stop  academic solution for making  of self branded  pre-school' },
    { Question: '10. How customer with support provides before and after purchase ?', Answer: 'Providing weekly updations with notifications Periodical e-news letters with new features and services Updating through remote access' },
    { Question: '11. Who is most likely to be shopping for this Education search engine ?', Answer: ''},
    { Question: '12. How are they going to find this search engine ?', Answer: ''},
    { Question: '13. What type of device will they typically use to browse this Search engine ?', Answer: ''},
    { Question: '14. What languages will the search engine speak ?', Answer: ''},
    { Question: '15. What currency do they use to pay for online purchase ?', Answer: ''},
    { Question: '16. What payment methods will the company accept ?', Answer: ''},
    { Question: '17. What are your consumers biggest concerns about buying from you ?', Answer: ''},
    { Question: '18. What does your company offer that no one else has ?', Answer: ''},
    { Question: '19. What do your competitors have that you  don’t have ?', Answer: ''},
    { Question: '20. What should a customer know about your company ?', Answer: ''},
    { Question: '21. How do you provide your customer with support before and after purchase ?', Answer: ''},
    { Question: '22. How many service categories will you have   (and sub-categories) ?', Answer: ''},
    { Question: '23. How many academic images will you have ?', Answer: ''},
    { Question: '24. What keywords can be used to describe your product ?'},
    { Question: '25. If a customer doesn’t buy a product on their visit, what’s the next action you could take ?', Answer: ''},
    { Question: '26. What emotion should a customer leave your site with after purchase ?', Answer: ''},
    { Question: '27. Will the core services come in a phased manner ?', Answer: ''},
    { Question: '28. What are the major segments that the search engine provides ?'},
    { Question: '29. What are your users biggest concerns about the services and the products ?', Answer: ''},
    { Question: '30. How long does it take your typical user to make a buying decision ?', Answer: ''},
    { Question: '31. How many products will you have ?', Answer: ''},
    { Question: '32. How big are your product photos and other information related to the products ?', Answer: ''},
    { Question: '33. On what platform this search engine is designed  and developed with ?', Answer: ''}
];















