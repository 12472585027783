export const Comics = [{
    "children": [
        {
            "title": "ADVENTURE OF SAMEER",
            "type": "directory"
        },
        {
            "title": "ADVENTURES IN KERALA",
            "type": "directory"
        },
        {
            "title": "ANJI APPAJI ADVENTURES (JAI HANUMAN)",
            "type": "directory"
        },
        {
            
            "title": "CHANDRALEKHA",
            "type": "directory"
        },
        {
            
            "title": "DANIEL THE DEVIL",
            "type": "directory"
        },
        {
            
            "title": "GIRL IN DANGER",
            "type": "directory"
        },
        {
            
            "title": "JOURNEY OF LIFE (CHIRU COMIC)",
            "type": "directory"
        },
        {
            
            "title": "MID NIGHT MYSTERY",
            "type": "directory"
        },
        {
            
            "title": "NETRA",
            "type": "directory"
        },
        {
            
            "title": "OPERATION BLOCK STAR FISH",
            "type": "directory"
        }
    ],
    "title": "ADVENTURE COMICS",
    "type": "directory"
},
{
    "children": [
        {
            "title": "INQUIRY",
            "type": "directory"
        }
    ],
    "title": "CARTOON AND BLOOPER",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ALIEN COLOR",
            "type": "directory"
        },
        {
            
            "title": "PICNIC IN THE MOUNTAIN",
            "type": "directory"
        },
        {
            
            "title": "THE WOLFISH COWBOY",
            "type": "directory"
        }
    ],
    "title": "FUN COMIC",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ACTIONS ARE BETTER THAN WORDS",
            "type": "directory"
        },
        {
            
            "title": "ENLIGHTMENT OF MIND",
            "type": "directory"
        },
        {
            
            "title": "HEAVEN AND HELL",
            "type": "directory"
        },
        {
            
            "title": "LONGEVITY OF LIFE",
            "type": "directory"
        },
        {
            
            "title": "MOCKING AT OTHERS",
            "type": "directory"
        },
        {
            
            "title": "MOTHER'S COMPANY",
            "type": "directory"
        },
        {
            
            "title": "OVERCOMING POVERTY",
            "type": "directory"
        },
        {
            
            "title": "PURITY OF MIND",
            "type": "directory"
        },
        {
            
            "title": "ROLE OF A TEACHER",
            "type": "directory"
        }
    ],
    "title": "GURUKUL",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ASHOKA",
            "type": "directory"
        },
        {
            
            "title": "BHAGAMATHI",
            "type": "directory"
        },
        {
            
            "title": "BHAGAT SINGH",
            "type": "directory"
        },
        {
            
            "title": "CHANAKYA",
            "type": "directory"
        },
        {
            
            "title": "DAYANANDA SARASWATHI",
            "type": "directory"
        },
        {
            
            "title": "JHANSI RANI LAKSHMI BAI",
            "type": "directory"
        },
        {
            
            "title": "MOTHER TERESA",
            "type": "directory"
        },
        {
            
            "title": "SHIVAJI",
            "type": "directory"
        }
    ],
    "title": "HISTORICAL COMICS",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ARROGANT LION",
            "type": "directory"
        },
        {
            
            "title": "CRUELTY VS HUMANTY",
            "type": "directory"
        },
        {
            
            "title": "DECEPTIVE APPEARANCE",
            "type": "directory"
        },
        {
            
            "title": "DHARMABUDDHI",
            "type": "directory"
        },
        {
            
            "title": "GREATNESS OF FOOD GIVING",
            "type": "directory"
        },
        {
            
            "title": "IN SEARCH OF GOD",
            "type": "directory"
        },
        {
            
            "title": "RUN FOR LIFE",
            "type": "directory"
        },
        {
            
            "title": "THE CAT LESSON",
            "type": "directory"
        },
        {
            
            "title": "THE WICKED CRANE",
            "type": "directory"
        }
    ],
    "title": "JATAKA TALE COMICS",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "BEAUTY AND STRENGTH",
            "type": "directory"
        },
        {
            
            "title": "CLEVE DEER",
            "type": "directory"
        },
        {
            
            "title": "CLEVER RABBIT",
            "type": "directory"
        },
        {
            
            "title": "DANGEROUS DESIRE",
            "type": "directory"
        },
        {
            
            "title": "FALSE FRIENDS",
            "type": "directory"
        },
        {
            
            "title": "FOOLISH COURAGE",
            "type": "directory"
        },
        {
            
            "title": "GET UNITED AND REAP PROFIT",
            "type": "directory"
        },
        {
            
            "title": "GOD IS GREAT",
            "type": "directory"
        },
        {
            
            "title": "GREAT FRIENDSHIP",
            "type": "directory"
        },
        {
            
            "title": "HIDDEN TREASURE",
            "type": "directory"
        },
        {
            
            "title": "HITAPADESA",
            "type": "directory"
        },
        {
            
            "title": "HORSE REVENGE",
            "type": "directory"
        },
        {
            
            "title": "HUNGRY TIGER",
            "type": "directory"
        },
        {
            
            "title": "MINISTER_S POST",
            "type": "directory"
        },
        {
            
            "title": "MUD POT AND COPPER POT",
            "type": "directory"
        },
        {
            
            "title": "MUSICAL DIET",
            "type": "directory"
        },
        {
            
            "title": "PITCHYS TRICK",
            "type": "directory"
        },
        {
            
            "title": "PRAYER",
            "type": "directory"
        },
        {
            
            "title": "REAL FRIEND",
            "type": "directory"
        },
        {
            
            "title": "STUPID MONKEY",
            "type": "directory"
        },
        {
            
            "title": "SURVIVAL OF HARDSHIP",
            "type": "directory"
        },
        {
            
            "title": "TAKING A DECISION",
            "type": "directory"
        },
        {
            
            "title": "THE CLEVER DOCTOR",
            "type": "directory"
        },
        {
            
            "title": "THE CROW'S GREED",
            "type": "directory"
        },
        {
            
            "title": "THE GARDENER AND THE MONKEY",
            "type": "directory"
        },
        {
            
            "title": "THE JEALOUS MONKEY",
            "type": "directory"
        },
        {
            
            "title": "THE MISCHIEVIOUS PARROT",
            "type": "directory"
        },
        {
            
            "title": "THE MOSQUITO AND THE HONEY BEE",
            "type": "directory"
        },
        {
            
            "title": "THE PLOT UNCOVERED",
            "type": "directory"
        },
        {
            
            "title": "THINK BEFORE YOU ACT",
            "type": "directory"
        }
    ],
    "title": "MORAL COMICS",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "BALAKANDA",
            "type": "directory"
        },
        {
            
            "title": "BHAGATH SING",
            "type": "directory"
        },
        {
            
            "title": "BHAGIRADHA",
            "type": "directory"
        },
        {
            
            "title": "DURGADEVI",
            "type": "directory"
        },
        {
            
            "title": "GOUTAM BUDDHA",
            "type": "directory"
        },
        {
            
            "title": "HANUMAN",
            "type": "directory"
        },
        {
            
            "title": "JAI JAWAN",
            "type": "directory"
        },
        {
            
            "title": "JESUS",
            "type": "directory"
        },
        {
            
            "title": "KRISHNA",
            "type": "directory"
        },
        {
            
            "title": "PRAHALADHA",
            "type": "directory"
        },
        {
            
            "title": "TIRUMALA BALAJI",
            "type": "directory"
        },
        {
            
            "title": "VEERABHIMANYU",
            "type": "directory"
        }
    ],
    "title": "MYTHOLOGY COMICS",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "AADI AND THREE GHOSTS",
            "type": "directory"
        },
        {
            
            "title": "CLONING CAMERA",
            "type": "directory"
        },
        {
            
            "title": "MAGIC FLUTE",
            "type": "directory"
        },
        {
            
            "title": "TRAPPED IN PAKISTAN",
            "type": "directory"
        }
    ],
    "title": "NEW AGE COMIC",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ABDUL HAMEED",
            "type": "directory"
        },
        {
            
            "title": "ARUN KHETRAPAL",
            "type": "directory"
        },
        {
            
            "title": "ARUN SRIDHAR VYDYA",
            "type": "directory"
        },
        {
            
            "title": "BABLI",
            "type": "directory"
        },
        {
            
            "title": "CHITTURU NENU GOPAL",
            "type": "directory"
        },
        {
            
            "title": "GAUTAM PADMANABHA",
            "type": "directory"
        },
        {
            
            "title": "GOOD MAN",
            "type": "directory"
        },
        {
            
            "title": "HENNA BAKSHI",
            "type": "directory"
        },
        {
            
            "title": "HOSHIOR SINGH",
            "type": "directory"
        },
        {
            
            "title": "HOTILAL",
            "type": "directory"
        },
        {
            
            "title": "JASWANTH SINGH RAVAT",
            "type": "directory"
        },
        {
            
            "title": "NANDAGOPAL",
            "type": "directory"
        },
        {
            
            "title": "RINCHEN",
            "type": "directory"
        },
        {
            
            "title": "SAJAN ANTONY",
            "type": "directory"
        },
        {
            
            "title": "VIJAY RATHAN CHOWDARY",
            "type": "directory"
        }
    ],
    "title": "REALLIFE COMICS",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "ILLEGAL ALIENS",
            "type": "directory"
        },
        {
            
            "title": "THE JOURNEY TO THE CENTRE OF THE EARTH",
            "type": "directory"
        }
    ],
    "title": "SCIENCE FICTION",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "DIAMONDY VALLY",
            "type": "directory"
        },
        {
            
            "title": "FOMTAM TOMBS ISLAND",
            "type": "directory"
        },
        {
            
            "title": "RUN WITH THE LADY MAGICIAN",
            "type": "directory"
        },
        {
            
            "title": "WIN THE GAME",
            "type": "directory"
        }
    ],
    "title": "SPY-C ADVENTURE",
    "type": "directory"
},
{
    "children": [
        {
            
            "title": "BETTING",
            "type": "directory"
        },
        {
            
            "title": "DEADLY FRIENDS",
            "type": "directory"
        },
        {
            
            "title": "DREAM BOYZ",
            "type": "directory"
        },
        {
            
            "title": "GLAMOUR FOR SALE",
            "type": "directory"
        },
        {
            
            "title": "LINKA BOOK OF RECORDS",
            "type": "directory"
        },
        {
            
            "title": "RAGGING RAGGING",
            "type": "directory"
        }
    ],
    "title": "TOONAGE",
    "type": "directory"
},
{
    "children": [
        {
            "children": [
                {
                    
                    "title": "NOTHING",
                    "type": "directory"
                },
                {
                    
                    "title": "PRICE OF SULTAN",
                    "type": "directory"
                }
            ],
            "title": "NASEERUDDIIN",
            "type": "directory"
        },
        {
            "children": [
                {
                    
                    "title": "THE BARBER_S WISH",
                    "type": "directory"
                },
                {
                    
                    "title": "THE FOOLISH THIEVES",
                    "type": "directory"
                },
                {
                    
                    "title": "THE GODDESS_S BOON",
                    "type": "directory"
                },
                {
                    
                    "title": "THE GOLDEN MANGOES",
                    "type": "directory"
                },
                {
                    
                    "title": "THE GURU LEARNS A LESSON",
                    "type": "directory"
                },
                {
                    
                    "title": "THE HOURS AND GRASS",
                    "type": "directory"
                },
                {
                    
                    "title": "THE PROUD POET",
                    "type": "directory"
                },
                {
                    
                    "title": "THE RAT MENACE",
                    "type": "directory"
                },
                {
                    
                    "title": "THE SULTHAN_S MAHABHARATH",
                    "type": "directory"
                },
                {
                    
                    "title": "THE WEDDING OF WELLS",
                    "type": "directory"
                }
            ],
            "title": "TENALI RAMAKRISHNA",
            "type": "directory"
        }
    ],
    "title": "WITTY COMICS",
    "type": "directory"
} ]