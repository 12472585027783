import React from 'react'
import subscriptionIcon from "../../../../../assests/auth/subscriptionIcon.svg";
import "./payment.css"
import { Button, Typography, FormControl, Box, Modal, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import MSTextField from '../../../../../customTheme/textField/MSTextField';
import useRazorpay from "react-razorpay";
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';

// Import Payment gateways Icons
import { ReactComponent as RazorpayIcon } from './assests/razorpay.svg';
import { ReactComponent as CashfreeIcon } from './assests/cashfree.svg';

const paymentOptions = [
    {
        label: "Razorpay",
        icon: <RazorpayIcon width={200} height={30} />,
        value: 'razorpay'
    },
    {
        label: "Cashfree",
        icon: <CashfreeIcon width={200} height={30} />,
        value: 'cashfree'
    },
]

const Payment = (props) => {
    const [userData, setUserData] = React.useState(props.userData)
    const [gateway, setGateway] = React.useState('razorpay')

    const [open, setOpen] = React.useState(false);
    const cashFreeRef = React.useRef(null)

    const handleClose = () => setOpen(false);

    const Razorpay = useRazorpay();

    const handleFieldsChange = (e, fieldName) => {
        e.preventDefault()

        setUserData(current => {
            const copy = { ...current }
            copy[fieldName] = e.target.value
            return copy
        })
    }

    const handlePayment = () => {
        switch (gateway) {
            case 'razorpay':
                const options = {
                    key: "rzp_test_dhMA8GK6NBzrFe", // Enter the Key ID generated from the Dashboard
                    amount: Math.ceil(props.subscription.amount + 0.18 * props.subscription.amount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: "INR",
                    description: "Test Transaction",
                    // image: "https://example.com/your_logo",
                    //order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
                    handler: function (response) {
                        alert(response.razorpay_payment_id);
                        alert(response.razorpay_order_id);
                        alert(response.razorpay_signature);
                    },
                    prefill: {
                        name: userData.name,
                        email: userData.email,
                        contact: userData.mobileNumber,
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    }
                };

                const razorpayGateway = new Razorpay(options)
                razorpayGateway.on("payment.failed", function (response) {
                    console.log(response.error)
                });

                razorpayGateway.open()
                break;

            // case 'cashfree':
            //     console.log('cashfree')
        }
    }

    const cbs = (data) => {
        if (data.order && data.order.status === 'PAID') {
            alert('order is paid. Call api to verify');
        }
    };
    const cbf = (data) => {
        alert(data.order.errorText || 'AAAA');
    };

    const handleOpen = () => {
        setOpen(true);
        setTimeout(() => {
            let parent = cashFreeRef.current
            console.log("Data of div", parent)
            // parent.innerHTML = '';
            let cashfree = new cashfreeSandbox.Cashfree();

            console.log('before Initialisation');
            cashfree.initialiseDropin(parent, {
                orderToken: '7RyleyCOzRftapYCmSDb',
                onSuccess: cbs,
                onFailure: cbf,
                // components: ["order-details", "card"]
                components: ["order-details", "card", "upi", "app", "netbanking"],
                style : {minHeight:'600px !important'}
            });
            console.log('after Initialisation');
        }, 200)


    }

    const renderPaymentOptions = () => {
        return (
            <FormControl style={{ justifyContent: 'space-evenly' }} >
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={(e) => setGateway(e.target.value)}
                    value={gateway}
                    name="row-radio-buttons-group">
                    <div>
                        <Typography>{paymentOptions[0].label}</Typography>
                        <FormControlLabel value={paymentOptions[0].value} control={<Radio />} label={paymentOptions[0].icon} />
                    </div>
                    <div>
                        <Typography>{paymentOptions[1].label}</Typography>
                        <FormControlLabel value={paymentOptions[1].value} control={<Radio />} label={paymentOptions[1].icon} />
                    </div>
                </RadioGroup>
                <Button disableElevation className='paymentProceedBtn' variant='contained' onClick={gateway === "razorpay" ? handlePayment : handleOpen}>Proceed to Checkout</Button>
            </FormControl>
        )
    }

    return (
        <>
            <div className="homeGutter" style={{ maxWidth: 20 }} />
            <div className="subscriptionContainer" >
                <div className="paymentSubscriptionTxtContainer" style={{ flex: 'unset' }}>
                    <img src={subscriptionIcon} alt="" style={{ width: '20px', height: '20px' }} />
                    <h2 className='paymentSubscriptionTopHeading'>
                        Confirm Your Subscription
                    </h2>
                    <Button variant="outlined" className="cancelBtn" onClick={props.handleCancel}>
                        Cancel
                    </Button>
                </div>
                <div className='paymentContainer'>
                    <div className='paymentWrapperLeft'>
                        <div className='paymentCard flexDirectionColumn' style={{ paddingBottom: 50 }}>
                            <Typography fontSize={18}>Your Details</Typography>
                            <div className='paymentFieldsContainer'>
                                <MSTextField id="paymentUsername" type="text" onChange={handleFieldsChange} value={userData.name} fieldName="name"
                                    placeholder="Enter Your Full name" label={<p><span style={{ color: 'red' }}>*</span> Full Name</p>} />
                                <MSTextField id="paymentMobNumber" type="phone" onChange={handleFieldsChange} value={userData.mobileNumber} fieldName="mobileNumber"
                                    placeholder="Enter mobile number ex +91 99999 99999" label={<p><span> * </span>MobileNumber </p>} />
                            </div>
                            <div className='paymentFieldsContainer'>
                                <MSTextField id="paymentCity" type="text" onChange={handleFieldsChange} value={userData.city} fieldName="city"
                                    placeholder="Enter city" label="* City" />
                                <MSTextField id="myProfileUsername" type="text" onChange={handleFieldsChange} value={userData.address} fieldName="address"
                                    placeholder="Enter Address" label="* Address" />
                            </div>
                        </div>
                        <div className='paymentCard flexDirectionColumn'>
                            <Typography fontSize={18}>Payment Options</Typography>
                            {renderPaymentOptions()}
                        </div>

                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}>
                            <div
                                className="dropin-parent"
                                ref={cashFreeRef}
                                id="drop_in_container"
                                style={{ height: '100vh', overflow: 'scroll' }}
                            >

                            </div>
                            </Box>
                        </Modal>

                    </div>
                    <div className='paymentWrapperRight'>
                        <div className='paymentCard paymentFlex1 flexDirectionColumn' style={{ minHeight: 'unset' }}>
                            <Typography fontSize={18}>Summary</Typography>
                            <div className='paymentTypographyContainer'>
                                <Typography>{props.subscription.name}</Typography>
                                <Typography fontWeight={'bold'}>{props.subscription.amount} INR</Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Subtotal</Typography>
                                <Typography fontWeight={'bold'}>{props.subscription.amount} INR</Typography>
                            </div>
                            <div className='paymentTypographyContainer'>
                                <Typography>Taxes (estimated)</Typography>
                                <Typography fontWeight={'bold'}>{0.18 * props.subscription.amount} INR</Typography>
                            </div>
                            <div className='paymentTotalContainer'>
                                <Typography>Total</Typography>
                                <Typography fontWeight={'bold'}>{Math.ceil(props.subscription.amount + 0.18 * props.subscription.amount)} INR</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="homeGutter" style={{ maxWidth: 20 }} />
        </>
    )
}

export default Payment