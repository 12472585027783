import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const MyProfile = createAsyncThunk(
    'auth/myProfile',
    async (data, { rejectWithValue }) => {
        try {
            let postData = data?.method === 'GET' ? {
                params: data?.body
            } : {
                data: data?.body
            }
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/users/getUserDetails',
                method: data?.method,
                headers: data?.headers,
                postData
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }

    }
)

export const UpdateProfile = createAsyncThunk(
    'auth/updateProfile',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/users/updateUserDetails',
                method: "PATCH",
                headers: data?.headers,
                data : data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)


const myProfileSlice = createSlice({
    name: "userDetails",
    initialState: {
        userDetails: {},
        loading: false
    },

    reducers: {},

    extraReducers: {
        [MyProfile.fulfilled]: (state, { payload }) => {
            state.userDetails = payload
            state.loading = false
        },
        [MyProfile.pending]: (state) => {
            state.loading = true
            state.userDetails = {}
        },
        [MyProfile.rejected]: (state, { payload }) => {
            state.userDetails = payload
            state.loading = false;
        },
        [UpdateProfile.fulfilled]: (state, { payload }) => {
            state.userDetails = payload
            state.loading = false
        },
        [UpdateProfile.pending]: (state) => {
            state.loading = true
            state.userDetails = {}
        },
        [UpdateProfile.rejected]: (state, { payload }) => {
            state.userDetails = payload
            state.loading = false;
        }
    }
});

export default myProfileSlice.reducer;

