import React from 'react'
import { TextField, InputAdornment } from '@mui/material'

const MSTextField = (props) => {
    return (
        <div style={[{
            display: 'flex',
            // flex : 1,
            // width: "100%",
            flexDirection: 'column'
        }, props.style].reduce((acc, curr) => Object.assign(acc, curr), {})}>
            <label htmlFor={props.id}>{props.label}</label>
            {props.label ? 
            <TextField type={props.type} id={props.id} disabled={props.disabled}
                value={props.value} style={props.style}
                onChange={(e) => props.onChange(e, props.fieldName)}
                InputProps={{
                    startAdornment: props.fieldName === "schoolCode" || props.fieldName === "teacherCode" ? 
                    <InputAdornment position="start">{props.fieldName === "schoolCode" ? "SC" : "TR"} </InputAdornment> : null 
                }}
                fullWidth placeholder={props.placeholder} size='small' /> : null }
        </div>

    )
}

export default MSTextField