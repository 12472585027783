import React from "react";
import MSTextField from "../../../../customTheme/textField/MSTextField";
import { Button, Paper, Typography } from "@mui/material";
import helpIcon from "../../../../assests/auth/helpIcon.svg";
import "../../../../components/auth/views/student/addNewStudent/AddNewStudent.css";
import "./Help.css";
import { MobileView } from "react-device-detect";

const Help = () => {
    return (
        <div className="addStudentManinContainer">
            {/* <MobileView>
                <MobileHeader />
            </MobileView> */}
            <div className="addStudentContainer">
                <div className="addStudentHeaderTxtContainer">
                    <img src={helpIcon} alt="help icon" />
                    <Typography fontSize="15px" fontWeight="bold">
                        Help
                    </Typography>
                </div>

            </div>
            <form className="addNewSchoolContainer">
                <Paper
                    elevation={0}
                    className="AddFormContainer"
                    style={{ backgroundColor: "white" }}
                >
                    <div className="addTextfieldContainer">
                        <div className="AddStudentTextFieldContainer">
                            <MSTextField
                                id="signUpUsername"
                                type="text"
                                placeholder="Enter email id"
                                label="* Email ID"
                            />
                            <MSTextField
                                id="signUpMobNumber"
                                type="text"
                                placeholder="+91 Enter mobile number"
                                label="* Mobile Number"
                            />
                            <MSTextField
                                id="signUpSchoolArea"
                                type="text"
                                placeholder="Enter subject"
                                label="Subject"
                            />
                        </div>

                        <div
                            className="AddStudentTextFieldContainer"

                        >
                            <MSTextField
                                id="signUpUsername"
                                type="text"
                                placeholder="Enter your message here..."
                                label="Your Message"
                            />
                        </div>
                        <div className="submitBtnContainer">
                            <Button style={{ backgroundColor: "#B3DAFF" }} className="saveDataBtn">Submit</Button>
                        </div>
                    </div>

                </Paper>

            </form>
        </div>
    )

}

export default Help;