import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const userUploadFile = createAsyncThunk(
    'auth/userUploadFile',
    async (data, { rejectWithValue }) => {
        try {
            let headers = data.headers
            let response = await axios.request({
                baseURL: "https://api.myschool.in",
                url: '/rest/images/user/upload',
                method: 'POST',
                headers : headers,
                data: data?.body
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
        
    })

const userUploadFileSlice = createSlice({
    name: "userUploadFile",
    initialState: {
        loading: false
    },

    reducers : {},

    extraReducers: {
        [userUploadFile.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [userUploadFile.pending]: (state) => {
            state.loading = true
        },
        [userUploadFile.rejected]: (state, { payload }) => {
            state.loading = false;
        }
    }
});

export default userUploadFileSlice.reducer;

