export  const School = [
    {
        label : <><span style={{color : 'red'}}>* </span>School Name</>,
        type : "text",
        placeholder : "* School name",
        fieldName : "name",
        index : 0
    },
    {
        label : <><span style={{color : 'red'}}>* </span>School Code</>,
        type : "text",
        index : 0,
        placeholder : 'School Code ex. 123456789',
        fieldName : "schoolCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Principle Name</>,
        type : "text",
        index : 0,
        placeholder : "Principle Name",
        fieldName : "principalName"
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Mobile Number</>,
        type : "text",
        index : 0,
        placeholder : "+91 99999 99999",
        fieldName : "mobileNumber"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Email Id</>,
        type : "email",
        index : 0,
        placeholder : "abhishek@gmail.com",
        fieldName : "emailId"
    },
    {
        label : "Address",
        type : "text",
        index : 0,
        placeholder : "Enter your Address",
        fieldName : "address"
    },
    {
        label : "City",
        type : "text",
        index : 0,
        placeholder : "City",
        fieldName : "city"
    },
    {
        label : "State",
        type : "text",
        index : 0,
        placeholder : "State",
        fieldName : "state"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Postal Code</>,
        type : "text",
        index : 0,
        placeholder : "Enter The Postal Code",
        fieldName : "postalCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Password</>,
        type : "password",
        index : 0,
        placeholder : "Enter your password",
        fieldName : "password"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Confirm Password</>,
        type : "password",
        index : 0,
        placeholder: "Renter your password",
        fieldName : "confirmPassword"
    },
]

export const Teacher = [
    {
        label : <><span style={{color : 'red'}}>* </span>User Name</>,
        type : "text",
        placeholder : "User name",
        fieldName : "name",
        index : 0
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>School Code</>,
        type : "text",
        index : 0,
        placeholder : 'School Code ex. 123456789',
        fieldName : "schoolCode"
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Mobile Number</>,
        type : "text",
        index : 0,
        placeholder : "+91 99999 99999",
        fieldName : "mobileNumber"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Email Id</>,
        type : "email",
        index : 0,
        placeholder : "abhishek@gmail.com",
        fieldName : "emailId"
    },
    {
        label : "Address",
        type : "text",
        index : 0,
        placeholder : "Enter your Address",
        fieldName : "address"
    },
    {
        label : "City",
        type : "text",
        index : 0,
        placeholder : "City",
        fieldName : "city"
    },
    {
        label : "State",
        type : "text",
        index : 0,
        placeholder : "State",
        fieldName : "state"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Postal Code</>,
        type : "text",
        index : 0,
        placeholder : "Enter The Postal Code",
        fieldName : "postalCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Password</>,
        type : "password",
        index : 0,
        placeholder : "Enter your password",
        fieldName : "password"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Confirm Password</>,
        type : "password",
        index : 0,
        placeholder: "Renter your password",
        fieldName : "confirmPassword"
    },
]

export const Parent = [
    {
        label : <><span style={{color : 'red'}}>* </span>User Name</>,
        type : "text",
        placeholder : "User name",
        fieldName : "name",
        index : 0
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Mobile Number</>,
        type : "text",
        index : 0,
        placeholder : "+91 99999 99999",
        fieldName : "mobileNumber"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Email Id</>,
        type : "email",
        index : 0,
        placeholder : "abhishek@gmail.com",
        fieldName : "emailId"
    },
    {
        label : "Address",
        type : "text",
        index : 0,
        placeholder : "Enter your Address",
        fieldName : "address"
    },
    {
        label : "City",
        type : "text",
        index : 0,
        placeholder : "City",
        fieldName : "city"
    },
    {
        label : "State",
        type : "text",
        index : 0,
        placeholder : "State",
        fieldName : "state"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Postal Code</>,
        type : "text",
        index : 0,
        placeholder : "Enter The Postal Code",
        fieldName : "postalCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Password</>,
        type : "password",
        index : 0,
        placeholder : "Enter your password",
        fieldName : "password"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Confirm Password</>,
        type : "password",
        index : 0,
        placeholder: "Renter your password",
        fieldName : "confirmPassword"
    },
]

export const Student = [
    {
        label : <><span style={{color : 'red'}}>* </span>User Name</>,
        type : "text",
        placeholder : "User name",
        fieldName : "name",
        index : 0
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Class Name</>,
        type : "text",
        placeholder : "Class name",
        fieldName : "className",
        index : 0
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Roll Number</>,
        type : "text",
        placeholder : "Roll Number",
        fieldName : "rollNumber",
        index : 0
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Section</>,
        type : "text",
        placeholder : "Section",
        fieldName : "sectionName",
        index : 0
    },
    {
        label : <><span style={{color : 'red'}}>* </span>School Code</>,
        type : "text",
        index : 0,
        placeholder : 'School Code ex. 123456789',
        fieldName : "schoolCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Parent Name</>,
        type : "text",
        placeholder : "* Parent Name",
        fieldName : "fatherName",
        index : 0
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Teacher Code</>,
        type : "text",
        index : 0,
        placeholder : 'Teacher Code ex. 123456789',
        fieldName : "teacherCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Parent Mobile Number</>,
        type : "text",
        index : 0,
        placeholder : "+91 99999 99999",
        fieldName : "mobileNumber"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Email Id</>,
        type : "email",
        index : 0,
        placeholder : "abhishek@gmail.com",
        fieldName : "emailId"
    },
    {
        label : "Address",
        type : "text",
        index : 0,
        placeholder : "Enter your Address",
        fieldName : "address"
    },
    {
        label : "City",
        type : "text",
        index : 0,
        placeholder : "City",
        fieldName : "city"
    },
    {
        label : "State",
        type : "text",
        index : 0,
        placeholder : "State",
        fieldName : "state"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Postal Code</>,
        type : "text",
        index : 0,
        placeholder : "Enter The Postal Code",
        fieldName : "postalCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Password</>,
        type : "password",
        index : 0,
        placeholder : "Enter your password",
        fieldName : "password"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Confirm Password</>,
        type : "password",
        index : 0,
        placeholder: "Renter your password",
        fieldName : "confirmPassword"
    },
]

export const Publisher = [
    {
        label : <><span style={{color : 'red'}}>* </span>User Name</>,
        type : "text",
        placeholder : "User name",
        fieldName : "name",
        index : 0
    },
    {
        label : null,
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Mobile Number</>,
        type : "text",
        index : 0,
        placeholder : "+91 99999 99999",
        fieldName : "mobileNumber"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Email Id</>,
        type : "email",
        index : 0,
        placeholder : "abhishek@gmail.com",
        fieldName : "emailId"
    },
    {
        label : "Address",
        type : "text",
        index : 0,
        placeholder : "Enter your Address",
        fieldName : "address"
    },
    {
        label : "City",
        type : "text",
        index : 0,
        placeholder : "City",
        fieldName : "city"
    },
    {
        label : "State",
        type : "text",
        index : 0,
        placeholder : "State",
        fieldName : "state"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Postal Code</>,
        type : "text",
        index : 0,
        placeholder : "Enter The Postal Code",
        fieldName : "postalCode"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Password</>,
        type : "password",
        index : 0,
        placeholder : "Enter your password",
        fieldName : "password"
    },
    {
        label : <><span style={{color : 'red'}}>* </span>Confirm Password</>,
        type : "password",
        index : 0,
        placeholder: "Renter your password",
        fieldName : "confirmPassword"
    },
]

export const formFields = {
    "School" : School,
    "Student" : Student,
    "Teacher" : Teacher,
    "Parent" : Parent,
    "Publisher" : Publisher}