import { Button, Card, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScrollMenuBtn from "../../../customTheme/signUpMenu/index";
import MSTextField from "../../../customTheme/textField/MSTextField";
import "./SignUp.css";
import { authRegister } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../hook/useSnackbar";
import { ReactComponent as Crossicon } from "../../../assests/homeScreen/crossicon.svg";
import { ReactComponent as MySchool } from "../../../assests/homeScreenLogo.svg";
import formValidation from "../../../formValidation/validation"

import { formFields } from './constant'

export const SignUp = React.forwardRef((props, ref) => {
  const [userRole, setUserRole] = useState("Student");
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();
  const { refreshToken, accessToken } = useSelector((state) => state.login);
  const [loading, setLoading] = React.useState(false);
  const signUpContainerRef = React.useRef(null)

  const { displaySnackbar } = useSnackbar();

  const [userData, setUserData] = useState({
    userRole: userRole?.toLocaleUpperCase(),
    city: "",
    confirmPassword: "",
    password: "",
    name: "",
    emailId: "",
    mobileNumber: "",
    schoolCode: "",
    postalCode: "",
    schoolName: "",
  });
  const dispatch = useDispatch();

  const handleLoginNav = (e) => {
    props.changeTab();
  };

  const handleFieldsChange = (e, fieldName) => {
    e.preventDefault();

    setUserData((current) => {
      const copy = { ...current };
      copy[fieldName] = e.target.value;
      return copy;
    });
  };

  const handleUserRegister = () => {
    setLoading(true);
    let header = {
      "Content-Type": "application/json",
    };

    // var newMessage=formValidation(userData)
    // if(newMessage==='true')
    {
      let data = Object.assign({}, userData);
      data.userRole = userRole.toUpperCase();
      if (userRole === "School") {
        data.schoolCode = `SC${data.schoolCode.startsWith('SC') ? data.schoolCode.substring(2) : data.schoolCode
          }${data.postalCode.slice(-3)}${data.name.slice(0, 2).toUpperCase()}`;

      } else if (userRole === "Teacher" || userRole === "Student") {
        data.schoolCode = `SC${data.schoolCode.startsWith('SC') ? data.schoolCode.substring(2) : data.schoolCode}`
        if (userRole === "Student") data.teacherCode = `TR${data.teacherCode.startsWith('TR') ? data.teacherCode.substring(2) : data.teacherCode}`
        // console.log('School Code', data.schoolCode);
      }

      dispatch(
        authRegister({
          url: "/rest/auth/register",
          header: header,
          method: "POST",
          body: data,
        })
      ).then((res) => {
        if (res.payload.addedBy === "Self" && res.payload.userId) {
          let loginData = {
            username: userData.emailId,
            password: userData.password,
          };
          dispatch(
            userLogin({
              url: "/rest/auth/login",
              header: header,
              method: "POST",
              body: loginData,
            })
          );
          displaySnackbar({ message: res.payload.message });
        } else if (res.payload.message) {
          const message = res.payload.message;
          const msg_remove = message.split(".");
          // const msg = message.substring(0, msg_remove);
          const msg = msg_remove[0];
          displaySnackbar({ message: msg });
          // <CustomSnackbar messsge={msg}/>
        } else {
          const err = res.payload.error;
          displaySnackbar({ message: err });
        }
        setLoading(false);
      });
    }
    // else{
    //   displaySnackbar({ message:newMessage});
    // }
  };

  React.useEffect(() => {
    if (accessToken && refreshToken) {
      // console.log("hello");
      navigate("/auth");
    }
    if (userRole.length === 0) {
      setError(true);
    }
  }, [accessToken, refreshToken]);

  return (
    <>
      {/* {isMobile ? (
        <img src={Crossicon}
          onClick={() => props.handleCloseModal()}
           className="signUpCloseModal"
        />
      ) : null} */}

      <div className="loginParentCard registerParentCard">

        <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'center', alignItems: 'center' }}>
          <div style={{
            display: 'flex', backgroundColor: 'white', width: '40px', height: '40px', borderRadius: '30px',
            alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
          }}>
            <Crossicon
              onClick={() => props.handleCloseModal()}
            />
          </div>
        </div>
        <Card style={{ display: 'flex', flex: 1, flexDirection: 'column', maxHeight: "80vh", minWidth: isMobile ? '100vw' : 'unset' }}>
          <div className="signupNav">
            {isMobile ? null :
              <div className="loginNewLogoContainer">
                <MySchool className="loginMySchoolLogo" />
              </div>
            }
            <div className={isMobile ? '' : "loginBtnContainer"} >
              <Button variant="text" onClick={handleLoginNav}>
                {" "}
                Sign In{" "}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="loginTabBtn"
              >
                Register
              </Button>
            </div>

          </div>
          <div className="SignUpHeading">
            <h3>Register To Get Exclusive Benefits</h3>
          </div>
          <div className="roleSelector">
            <div style={{ maxWidth: "-webkit-fill-available" }}>
              <ScrollMenuBtn setUserRole={setUserRole} userRole={userRole} />
            </div>
          </div>
          <form className="registerForm">
            <div className="signUpInputContainer" ref={signUpContainerRef}>
              {formFields[userRole].map((k, i) =>
                <MSTextField
                  key={i}
                  style={{
                    // width : '50%'
                    flex: 1,
                    // width: isMobile ? "100%" : signUpContainerRef?.current?.clientWidth / 2.1,
                    minWidth: 300,
                  }}
                  id={`register-${k.fieldName}`}
                  type={k.type}
                  onChange={handleFieldsChange}
                  fieldName={k.fieldName}
                  placeholder={k.type === "email" ?
                    `${userRole.toLocaleLowerCase()}@email.com` : k.placeholder}
                  label={k.label}
                />
              )}
            </div>
            <div className="loginContBtn registerContBtn">
              {!loading ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUserRegister}
                  fullWidth
                >
                  Continue
                </Button>
              ) : (
                <Button variant="contained" color="secondary" fullWidth>
                  <CircularProgress size={18} color="primary" />
                </Button>
              )}
            </div>
          </form>
        </Card>
      </div>
    </>
  );
});
