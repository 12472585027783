import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StudentsIcon from "../../../../../assests/auth/studentsIcon.svg";
import Btn from "../../../../../customTheme/button/Button";
import MSTextField from "../../../../../customTheme/textField/MSTextField";
import { addUser } from "../../../../../redux/addUserSlice";
import "./AddNewStudent.css";
import { useSnackbar } from "../../../../../hook/useSnackbar";
import { RefreshToken } from "../../../../../redux/authSlice";
import formValidation from "../../../../../formValidation/validation";



const AddNewStudent = (props) => {

  const { accessToken, refreshToken, tokenExpiry, userRole } = useSelector((state) => state.login)
  const { displaySnackbar } = useSnackbar()

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = React.useState({
    userRole: "STUDENT", city: "", name: "", emailId: "", mobileNumber: "", schoolName: "", address: "",
    state: "", fatherName: "", rollNumber: "", className: "", sectionName: "", teacherCode: "",
    schoolCode: ""
  })

  const handleFieldsChange = (e, fieldName) => {
    e.preventDefault()

    setUserData(current => {
      const copy = { ...current }
      copy[fieldName] = e.target.value
      return copy
    })
  }

  // Function handle generation of new Student
  const handleSave = () => {



    let header = {
      "Content-Type": "application/json",
      'Accept': 'application/json',
      "Authorization": `Bearer ${accessToken}`
    }

    // var newMessage=formValidation(userData)
    // if(newMessage==='true') {

    let data = userData

    if (data.userRole === "STUDENT" && userRole === "SCHOOL") {
      data.teacherCode = `TR${data.teacherCode.startsWith('TR') ? data.teacherCode.substring(2) : data.teacherCode}`
      data.schoolCode = `SC${data.schoolCode.startsWith('SC') ? data.schoolCode.substring(2) : data.schoolCode}`
    }

    dispatch(addUser({
      headers: header,
      body: data
    })).then((res) => {
      if (res.payload.addedBy) {
        displaySnackbar({ message: 'Student added successfully' })
        setUserData({
          userRole: userRole, city: "", name: "", emailId: "", mobileNumber: "", schoolName: "", address: "",
          state: "", fatherName: "", rollNumber: "", className: "", sectionName: "", teacherCode: "",
          schoolCode: ""
        })
      }
      if (res.payload.message) {
        const msg = res.payload.message.split('.')[0]
        displaySnackbar({ message: msg })
      }
      if (res.payload.error) {
        displaySnackbar({ message: res.payload.error })
      }
      if (res.payload.message === "Expired JWT") {
        dispatch(RefreshToken({
          headers: header,
          body: {
            "refreshToken": refreshToken
          }
        })).then((res) => {
          header["Authorization"] = `Bearer ${res.payload.accessToken}`
          dispatch(addUser({
            headers: header,
            body: data
          })).then((res) => {
            setUserData(res.payload)
          })
        })
      }
    }).then((res) => {
      displaySnackbar({ message: res.payload.message })
      if (res.payload.message === "Success") {
        setUserData({
          userRole: "STUDENT", city: "", confirmPassword: "",
          password: "", name: "", emailId: "", mobileNumber: ""
        })
      }

    })
    // } else {
    //   displaySnackbar({ message:newMessage});
    // }
  }

  return (
    <div className="addStudentManinContainer">

      <div className="addStudentContainer">
        <div className="addStudentHeaderTxtContainer">
          <img src={StudentsIcon} alt="students icon" />
          <Typography fontSize="15px" fontWeight="bold">
            Add New Student{" "}
          </Typography>
        </div>
        <div className="addStudentHeaderBtnContainer">
          <BrowserView>
            <Button variant="outlined" className="cancelBtn" onClick={props.handleCancel}>Cancel</Button>
          </BrowserView>
          <MobileView>
            <Btn onclick={() => navigate(-1)} />
          </MobileView>
          <Button style={{ backgroundColor: "#B3DAFF" }} className="saveDataBtn" onClick={handleSave}>Save Data</Button>
        </div>
      </div>
      <form className="addNewStudentContainer">
        <Paper
          className="AddFormContainer"
          style={{ backgroundColor: "white" }}
        >
          <div className="addTextfieldContainer">
            <div className="AddStudentTextFieldContainer">
              <MSTextField
                id="studentUserName" type="text" placeholder="Enter your user name"
                value={userData.name}
                label="* User Name" onChange={handleFieldsChange} fieldName="name"
              />
              <MSTextField
                id="studentEmailId" type="email" placeholder="student@email.com"
                value={userData.emailId}
                label="* Email ID " onChange={handleFieldsChange} fieldName="emailId"
              />
              <MSTextField
                id="studentMobileNumber" type="tel" placeholder="Parent Mobile Number"
                value={userData.mobileNumber}
                label="* Parent Mobile Number" onChange={handleFieldsChange} fieldName="mobileNumber"
              />
            </div>
            <div className="AddStudentTextFieldContainer">
              <MSTextField
                id="studentSchoolName" type="text" placeholder="School Name"
                value={userData.schoolName}
                label="* School Name" onChange={handleFieldsChange} fieldName="schoolName"
              />
              <MSTextField
                id="studentAddress" type="text" placeholder="Enter your Address"
                value={userData.address}
                label="Address" onChange={handleFieldsChange} fieldName="address"
              />
              <MSTextField
                id="studentCity" type="text" placeholder="City"
                value={userData.city}
                label="City" onChange={handleFieldsChange} fieldName="city"
              />
            </div>
            <div className="AddStudentTextFieldContainer">
              <MSTextField
                id="studentState" type="text" placeholder="State"
                value={userData.state}
                label="State" onChange={handleFieldsChange} fieldName="state"
              />
              <MSTextField
                id="studentFatherName" type="text" placeholder="Parent Name ex. Aman Kumar"
                value={userData.fatherName}
                label="Parent Name" onChange={handleFieldsChange} fieldName="fatherName"
              />
              <MSTextField
                id="studentRollNumber" type="text" placeholder="Roll Number ex. 123321"
                value={userData.rollNumber}
                label="* Roll Number" onChange={handleFieldsChange} fieldName="rollNumber"
              />
            </div>
            <div
              className="AddStudentTextFieldContainer">
              <MSTextField
                id="studentClassName" type="text" placeholder="Class Name ex. 11th,4th"
                value={userData.className}
                label="* Class Name" onChange={handleFieldsChange} fieldName="className"
              />
              <MSTextField
                id="studentSectionName" type="text" placeholder="Section ex. A, B, C"
                value={userData.sectionName}
                label="* Section" onChange={handleFieldsChange} fieldName="sectionName"
              />
              {/* <MSTextField
                id="studentSchoolCode" type="text" placeholder="School Code ex. 123456789"
                label="* School Code" onChange={handleFieldsChange} fieldName="schoolCode"
              /> */}
            </div>
            <div className="AddStudentTextFieldContainer">
              {userRole === "SCHOOL" ?
                <MSTextField
                  id="studentTeacherCode" type="text" placeholder="Teacher code ex. 1665709456231"

                  value={userData.teacherCode}
                  label="* Teacher Code" onChange={handleFieldsChange} fieldName="teacherCode"
                /> : null}

              {userRole === "ADMIN" ?
                <>
                  <MSTextField
                    id="studentSchoolCode" type="text" placeholder="School Code ex. 123456789"
                    value={userData.schoolCode}
                    label="* School Code" onChange={handleFieldsChange} fieldName="schoolCode"
                  />
                  <MSTextField
                    id="studentTeacherCode" type="text" placeholder="Teacher code ex. 1665709456231"
                    value={userData.teacherCode}
                    label="* Teacher Code" onChange={handleFieldsChange} fieldName="teacherCode"
                  />
                </> : null}

            </div>
          </div>
        </Paper>
      </form>
    </div>
  );
};
export default AddNewStudent;
