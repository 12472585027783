import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FormatFilter from '../../../uicomponent/filter/FormatFilter'
import ImageRenderer from '../../auth/views/imageRenderer/ImageRenderer'
import './Academics.css'
import { loadImages } from '../../../redux/apiSlice'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import SelectImage from './selectImage'
import { Modal } from '@mui/material'
import { Login } from '../../auth/login/Login'
import { SignUp } from '../../auth/signUp/SignUp'
import { SearchImages } from '../../../redux/fetchSearchSlice';
import StructureFilter from '../../../uicomponent/structureFiltering'


const Academics = (props, state) => {
    const { list, continuationToken, loading } = useSelector(state => state.api)
    const [data, setData] = useState([])
    const [tab, setTab] = useState(0)
    const [open, setOpen] = useState(false)
    const [noSelectImage, setNoSelectImage] = useState([]);

    // State for File format filters 
    const [formats, setFormat] = useState(null)        // formats of files like png , jpg, gif, pdf etc
    const [docType, setDocType] = useState(null)      // state for type of file document like Images, Videos, Animations, etc

    const dispatch = useDispatch()
    const location = useLocation()
    // Function to handle loading of images
    const handleFetchImages = (folderPath) => {
        if (location?.search.includes('?text=') && location?.search.replace('?text=', "").toLowerCase() !== "") {
            let header = {
                "Content-Type": "application/json",
            }

            dispatch(SearchImages({
                headers: header,
                method: "GET",
                body: {
                    query: location?.search.replace('?text=', "").toLowerCase(),
                    size : 21
                }
            })).then(async (res) => {
                setData(res.payload.data)
            })
            return
        }
        let path = location.state?.path ? location.state.path : "ACADEMIC/thumbnails/"
        let header = {
            "Content-Type": "application/json"
        }
        dispatch(loadImages({
            url: "/rest/images/fetch",
            header: header,
            method: "post",
            body: { folderPath: folderPath ? folderPath : path, imagesPerPage: 1000 }
        }));
    }

    useEffect(() => handleFetchImages(), [location?.search.includes('?text=') ? location?.search.replace('?text=', "").toLowerCase() : null])

    const selectPicture = (data, remove) => {
        if (remove) {
            setNoSelectImage(current => {
                const copy = [...current]
                let index = copy.indexOf(data)
                if (index >= -1) {
                    copy.splice(index, 1)
                }
                return copy
            })
        } else {
            setNoSelectImage(current => {
                const copy = [...current]
                copy.push(data)
                return copy
            })
        }
    }

    const handleAddToFavourite = (data) => {
        setNoSelectImage(current => {
            const copy = [...current]
            let index = copy.indexOf(data)

            if (index > -1) {
                copy[index].markFav = !copy[index]?.markFav
            }
            return copy
        })
    }

    const handleRemoveAll = () => {
        setNoSelectImage([])
    }

    // Function handle close of Login or Sign Up
    // Modal
    const handleClose = () => {
        setOpen(false);
        setTab(0);
    };

    return (
        <>
            <div className='checkboxContainer'>
                <SelectImage
                    image={noSelectImage}
                    openLogin={setOpen}
                    handleRemove={selectPicture}
                    handleRemoveAll={handleRemoveAll}
                    handleAddToFavourite={handleAddToFavourite} />
            </div>
            <div >
                <StructureFilter
                    loadImages={handleFetchImages}
                />
                <div className='academicsContainer'>
                    <div className={isMobile ? 'mobGutter' : 'homeGutter'} />
                    <div className='academicsWrapper'>
                        <FormatFilter
                            formats={formats}
                            setFormat={setFormat}
                            docType={docType}
                            setDocType={setDocType}
                        />
                        <ImageRenderer
                            openLogin={setOpen}
                            loading={loading}
                            pageType={location?.search.includes('?text=') ? "result" : 'sections'}
                            data={location?.search.includes('?text=') ? data : list}
                            token={continuationToken}
                            addedImages={noSelectImage}
                            selectedFilters={formats}
                            addToNoSelectImage={selectPicture} />
                    </div>
                    <div className={isMobile ? 'mobGutter' : 'homeGutter'} />
                </div>

            </div>
            {/* Render Modal for the Auth Section */}
            <Modal className='openModal' onClose={handleClose} open={open}>
                {tab === 0 ? (
                    <Login changeTab={() => setTab(tab === 0 ? 1 : 0)} handleCloseModal={handleClose} />
                ) : (
                    <SignUp changeTab={() => setTab(tab === 0 ? 1 : 0)} handleCloseModal={handleClose} />
                )}
            </Modal>
        </>
    )
}

export default Academics