import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import { Button } from '@blueprintjs/core';
import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';
import { chipClasses, Switch, TextField, Typography } from '@mui/material';
import './style/resizeStyle.css'
import React from 'react'
import { ReactComponent as ResizeIcon} from './assests/resize.svg'

const AVAILABLE_SIZES = [
  { width: 1080, height: 680, name: "LandScape Size" },
  { width: 1080, height: 1080, name: "Square Size" },
  { width: 1080, height: 1350, name : "Portrait Size" },
  { width: 2480, height: 3508, name: "A4 Size" },
  { width: 3508, height: 4961, name: "A3 Size" },
  { width: 2550, height: 3300, name : "Letter Size" },
  { width: 2550, height: 3900, name: "Legal Size" },
  { width: 1920, height: 1080, name: "HD Size" }
];

// define the new custom section
export const ResizeSection = {
  name: 'Resize',
  Tab: (props) => (
    <SectionTab name="Resize" {...props}>
      <ResizeIcon />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [magicResize, setMagicResize] = React.useState(true)
    const [cWidth, setWidth] = React.useState(1080)
    const [cHeight, setHeight] = React.useState(1080)
    return (
      <>
        <Typography fontSize={20}>Resize</Typography>
        <div style={{display: 'flex', flex:1, justifyContent:'space-between'}}>
            <Typography fontSize={16}>Magic Resize</Typography>
            <Switch checked={magicResize} onChange={(e) => setMagicResize(e.target.checked)}></Switch>
        </div>
        <div style={{display: 'flex', flex:1, justifyContent:'space-between', flexDirection:'column', gap:25}}>
            <div style={{display:'flex', justifyContent:'space-between', flex:1}}>
                <Typography>Width (px)</Typography>
                <TextField style={{maxWidth:200}} 
                    value={cWidth}
                    type="number"
                    onChange={(e) => setWidth(e.target.value)} 
                    size="small"></TextField>
            </div>
            <div style={{display:'flex', justifyContent:'space-between', flex:1}}>
                <Typography>Height (px)</Typography>
                <TextField style={{maxWidth:200}} 
                    type="number"
                    value={cHeight}
                    onChange={(e) => setHeight(e.target.value)} 
                    size="small"></TextField>
            </div>
            <Button
                style={{width: "40%", backgroundColor: '#7BA6FB', height:40, color: 'white'}}
                onClick={() => {
                    store.setSize(parseInt(cWidth), parseInt(cHeight), magicResize);
                }}
                >
                <Typography>Set Page</Typography></Button>
        </div>
        {AVAILABLE_SIZES.map(({ width, height, name }, i) => (
          <Button
            key={i}
            style={{ width: '100%', marginBottom: '20px', marginTop:20, display:'flex' }}
            className="chartMakerResizeBtn"
            onClick={() => {
                setWidth(width)
                setHeight(height)
              store.setSize(width, height, magicResize);
            }}
          >
            <Typography>{name}</Typography>
            <Typography>{width}x{height}px</Typography>
          </Button>
        ))}
      </>
    );
  }),
};