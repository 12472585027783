export const menuItemsInfoHub = [
    {
        "children": [
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000014.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000015.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000016.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000017.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000018.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000019.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000020.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000021.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000022.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000023.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000024.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000025.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000026.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000001.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000002.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000003.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000004.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000005.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000006.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000007.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000008.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000009.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000010.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000011.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000012.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000013.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2011",
                        "type": "directory"
                    },
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "1_My School_Kinder_Special Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "1 Kinder - Prime Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2019",
                        "type": "directory"
                    }
                ],
                "title": "PLAY SCHOOL",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000028.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000029.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000030.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000031.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000032.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000033.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000034.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000035.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000027.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "2000000036.pdf",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000037.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000038.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000039.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000040.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000041.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000042.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000043.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000044.jpg",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2011",
                        "type": "directory"
                    },
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "2_My School_Beginner_Special Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "2 Beginer - Prime Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2019",
                        "type": "directory"
                    }
                ],
                "title": "PRE-PRIMARY",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000045.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000046.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000047.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000048.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000049.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000050.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000051.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000052.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000053.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "2000000054.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000055.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000056.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000057.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000058.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000059.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000060.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000061.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000062.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2011",
                        "type": "directory"
                    },
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "3_My School_Learner_Special Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "3 Learner - Prime Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2019",
                        "type": "directory"
                    }
                ],
                "title": "PRIMARY",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "2000000072.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000073.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000074.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000075.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000076.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000077.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000078.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000079.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000080.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "2000000063.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000064.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000065.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000066.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000067.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000068.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000069.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000070.jpg",
                                        "type": "file"
                                    },
                                    {
                                        "title": "2000000071.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2011",
                        "type": "directory"
                    },
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "4_My School_Explorer_Special Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "4 Explorer - Prime Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2019",
                        "type": "directory"
                    }
                ],
                "title": "MIDDLE SCHOOL",
                "type": "directory"
            },
            {
                "children": [
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000090.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000091.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000092.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000093.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000094.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000095.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000096.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000097.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000098.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "children": [
                                            {
                                                "title": "2000000081.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000082.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000083.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000084.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000085.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000086.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000087.jpg",
                                                "type": "file"
                                            },
                                            {
                                                "title": "2000000089.jpg",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "IMAGES",
                                        "type": "directory"
                                    },
                                    {
                                        "children": [
                                            {
                                                "title": "2000000090.pdf",
                                                "type": "file"
                                            }
                                        ],
                                        "title": "PDF",
                                        "type": "directory"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2011",
                        "type": "directory"
                    },
                    {
                        "children": [
                            {
                                "children": [
                                    {
                                        "title": "5_My School_Winner_Special Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "FEBRUARY",
                                "type": "directory"
                            },
                            {
                                "children": [
                                    {
                                        "title": "5 Winner - Prime Edition.pdf",
                                        "type": "file"
                                    }
                                ],
                                "title": "JANUARY",
                                "type": "directory"
                            }
                        ],
                        "title": "2019",
                        "type": "directory"
                    }
                ],
                "title": "HIGH SCHOOL",
                "type": "directory"
            },
        ],
        "title": "MYSCHOOL",
        "type": "directory"
    }
] 