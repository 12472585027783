import { menuItemsMakers } from "./menuItemsMakers";

export const menuItemsEdutainment = [
    {
        "title": "FUN STATION",
        "type": "directory",
        "children": [

            {
                "children": [],
                "title": "ACADEMIC DECORATION",
                "type": "directory"
            },
            {
                "children": [],
                "title": "ANIMATED LESSONS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "COPY AND SAVE",
                "type": "directory"
            },
            {
                "children": [],
                "title": "CURSIVE WRITING",
                "type": "directory"
            },
            {
                "children": [],
                "title": "CUT-PASTE CHARTS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "HOLIDAY HOME FUN",
                "type": "directory"
            },
            {
                "children": [],
                "title": "INTERACTIVE LESSONS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "LANGUAGE ACTIVITIES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "LIFE SKILLS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "LISTEN & READ ALONG",
                "type": "directory"
            },
            {
                "children": [],
                "title": "LIVE WORKSHEETS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "MATHS ACTIVITIES",
                "type": "directory"
            },
            {
                "children": [],
                "title": "MEET BOOK INDUSTRY",
                "type": "directory"
            },
            {
                "children": [],
                "title": "PICTORIALS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "POWER POINT LESSONS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "PROJECT IN CLASSROOM",
                "type": "directory"
            },
            {
                "children": [],
                "title": "READ ALOUD",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SCHOOLS DIRECTORY",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SCIENCE PROJECTS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SHARE",
                "type": "directory"
            },
            {
                "children": [],
                "title": "STUDENT LAB MANUALS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "SUBJECT POSTERS",
                "type": "directory"
            },
            {
                "children": [],
                "title": "VISUAL WORK SHEETS",
                "type": "directory"
            }
        ]
    },    
].concat(menuItemsMakers)